const ProductComparisonTable = ({
  tableCornerTagline,
  tableCornerTitle,
  tableHeadCells,
  tableRows,
}) => {
  const normalizedData = {
    theme: 'white',
    table: {
      tableCornerTagline,
      tableCornerTitle,
      tableHead: tableHeadCells,
      tableRows,
    },
  }

  return normalizedData
}

export default ProductComparisonTable
