export const getAriaProps = ({
  href,
  label,
  ariaLabel,
  type,
  rel,
  target,
  disabled,
  tabIndex,
  iconStart,
  iconEnd,
}) => {
  let props = {}

  let normalizedRel = rel ? rel : ''

  if (target == '_blank') {
    normalizedRel = `${normalizedRel ? ' ' : ''}noopener noreferrer`
  }

  props = Object.assign(
    {},
    {
      'aria-label': ariaLabel
        ? ariaLabel
        : label
        ? label
        : iconStart || iconEnd,
    },
    normalizedRel ? { rel: normalizedRel } : null,
    disabled ? { 'aria-disabled': true } : null,
    disabled ? { tabIndex: '-1' } : null,
    !disabled ? { tabIndex: tabIndex ? tabIndex : '0' } : null,
    normalizedRel ? { rel: normalizedRel } : null,
    !href ? { type: type ? type : 'button' } : null
  )

  return props
}
