import styled, { css } from 'styled-components'

export const Section = styled.section`
  ${({ theme, colorScheme }) =>
    colorScheme &&
    css`
      background-color: ${theme.colors.surface[colorScheme].background};
      color: ${theme.colors.surface[colorScheme].text};
    `}
`
