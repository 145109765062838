import styled, { css } from 'styled-components'

export const Hero = styled.div`
  padding: ${({ theme }) => theme.rem(32, 0)};

  /* ${({ theme }) =>
    theme.mediaQuery.lg(`
    padding: ${theme.rem(60, 0)};
  `)}; */
`

export const HeroButtons = styled.div`
  margin-top: ${({ theme }) => theme.rem(32)};

  a,
  button {
    width: 100%;

    ${({ theme }) =>
      theme.mediaQuery.lg(`
      width: auto;
    `)};
  }
`
