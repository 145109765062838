import normalizeCta from '@models/CTA'
import normalizeImage from '@models/Image'

const Hero = ({
  paragraphLeft = null,
  paragraphRight = null,
  ctaPrimary = null,
  ctaTertiary = null,
  cards = null,
  images = [],
  paragraph,
  title,
  type,
}) => {
  let normalizedImages = []

  if (images) {
    for (const item of images) {
      normalizedImages.push(normalizeImage(item))
    }
  }

  const normalizedData = {
    paragraphLeft,
    paragraphRight,
    ctaPrimary: normalizeCta(ctaPrimary),
    ctaTertiary: normalizeCta(ctaTertiary),
    cards,
    images: normalizedImages,
    paragraph,
    title,
    type,
    theme: type == 'Hero3' ? 'primaryLight' : 'white',
  }

  return normalizedData
}

export default Hero
