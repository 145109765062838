import styled, { css } from 'styled-components'

const BreadcrumbList = styled.ol`
  display: flex;
  padding: ${({ theme }) => theme.rem(20, 0)};
`

const BreadcrumbListItem = styled.li`
  position: relative;

  ${({ theme, separator }) =>
    separator &&
    css`
      margin: 0 ${theme.rem(16)} 0 ${theme.rem(8)};
    `}

  a, button {
    color: ${({ theme }) => theme.colors.primary.default};
  }

  [aria-current='page'] {
    font-weight: 400;
  }
`

export { BreadcrumbList, BreadcrumbListItem }
