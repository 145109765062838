/**
 * Accessibility specifications
 *
 * @url Accessibility https://www.w3.org/WAI/tutorials/forms/
 * @url Docs https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/forms
 */

import { useCallback, useRef, useState } from 'react'

import createValidationSchema from '@services/formValidation'
import { object } from 'yup'

import { useDataLoader } from '@hooks/useDataLoader'

import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

import { getCurrentLocation, submitForm } from '@api/index'

import { Form, Formik } from 'formik'
import FormDebug from '../FormDebug'
import FormDispatcher from '../FormDispatcher'

import { Button } from '@/atoms'
import { Col, Row } from '@/atoms/Grid'
import { ItemsGrouped } from '@/organisms'

import FormMessage from '../FormMessage'
import * as S from './styles'

const FormBuilder = ({
  endpoint,
  baseUrlEndpoint = 'front',
  method = 'POST',
  contentType = null,
  encType = null,
  fieldGroups = null,
  initialValues,
  privacyField = null,
  errorMessage = 'Errore:',
  successMessage = null,
  submitButton = null,
  cancelButton = null,
  alignButtons = 'flex-start',
  validateOnMount = false,
  onSubmit,
  resetOnSubmit = false,
  reCaptcha = true,
  debug = false,
  dataLayer = null,
  onDataLayerChange = () => {},
  triggerDataLayerEvent = () => {},
}) => {
  const [submitError, setSubmitError] = useState(null)
  const [submitted, setSubmitted] = useState(false)
  const formRef = useRef(null)
  const { startLoading, stopLoading } = useDataLoader()
  const hasErrors = submitError && (submitError.items || submitError.label)

  // Validation schema
  let validationSchema = {}
  const fieldsToValidate = privacyField
    ? [...fieldGroups, { fields: [privacyField] }]
    : fieldGroups
  fieldsToValidate.forEach(({ fields: f }) => {
    const fieldsetValidationSchema = f.reduce(createValidationSchema, {})
    validationSchema = {
      ...validationSchema,
      ...fieldsetValidationSchema,
    }
  })

  // ReCaptcha
  const { executeRecaptcha } = useGoogleReCaptcha()

  // Get ReCaptcha Token
  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.error('Execute recaptcha not yet available')
      return
    }

    const token = await executeRecaptcha()

    return token
  }, [executeRecaptcha])

  // Handle Request
  const handleRequest = async (values) => {
    let headers = {}
    let formData = null

    if (contentType == 'formData') {
      formData = new FormData()
      for (const val in values) {
        formData.append(val, values[val])
      }
    } else {
      headers['Content-Type'] = 'application/json'
      formData = values
    }

    try {
      let params = null
      let options = {
        headers,
        method,
      }

      if (method == 'GET') {
        for (const prop in formData) {
          // Normalize object value
          if (typeof formData[prop] === 'object') {
            formData[prop] = formData[prop].value
          }
          // Remove empty values
          if (formData[prop] === '') {
            delete formData[prop]
          }
        }

        // Support for Store Locator params
        if (formData.storeLocatorSearch) {
          const locationRes = await getCurrentLocation({
            address: formData.storeLocatorSearch,
          })
          if (locationRes.results.length > 0) {
            let location = locationRes.results[0].geometry.location
            formData.sort_by = `latitudine:${location.lat},longitudine:${location.lng}`
          }
        }

        params = new URLSearchParams(formData)
      } else if (method == 'POST') {
        options.body =
          contentType != 'formData' ? JSON.stringify(formData) : formData
      }

      const url = params ? `${endpoint}?${params}` : `${endpoint}`

      const data = await submitForm(url, baseUrlEndpoint, options)
      return data
    } catch (error) {
      console.error(`Submit error: ${error}`)
      return null
    }
  }

  const handleSubmit = async (values, { resetForm }) => {
    let formResponse = null

    startLoading()
    setSubmitError(null)

    try {
      const token = reCaptcha ? await handleReCaptchaVerify() : null
      if (token) {
        values['g-recaptcha-response'] = token
      }

      if (endpoint) {
        formResponse = await handleRequest(values)
        setSubmitted(true)
        const isNewsletterSubscription = Object.keys(values).length === 2 && ['email', 'g-recaptcha-response'].every(key => values.hasOwnProperty(key))
        const isAlreadyRegisteredToNewsletter = formResponse?.data?.registered
        if (
          isAlreadyRegisteredToNewsletter !== undefined &&
          isAlreadyRegisteredToNewsletter !== null &&
          isNewsletterSubscription
        ) {
          if (!isAlreadyRegisteredToNewsletter) setSubmitError(errorMessage)
        }
        if (formResponse.error) {
          if (
            formResponse.error.details &&
            Array.isArray(formResponse.error.details.errors)
          ) {
            let errors = []
            formResponse.error.details.errors.map((error) => {
              errors.push({
                message: error.message,
              })
            })
            setSubmitError({
              label: `${formResponse.error.message}`,
              items: errors,
            })
          } else {
            setSubmitError({
              label: `${errorMessage} ${formResponse.error.message}`,
            })
          }
        }
      } else {
        console.table(values)
        setSubmitted(true)
      }
    } catch (error) {
      console.warn(`Form error: ${error}`)
    } finally {
      if (dataLayer && triggerDataLayerEvent) {
        const eventType = 'formSubmit'
        triggerDataLayerEvent(dataLayer, eventType)
      }
      if (onSubmit) await onSubmit(values, formResponse)
      if (resetOnSubmit) await resetForm()
      stopLoading()
    }
  }

  const handleDataLayerChange = (formValues) => {
    if (onDataLayerChange && dataLayer) {
      onDataLayerChange(formValues)
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={object().shape(validationSchema)}
      validateOnMount={validateOnMount}
      onSubmit={handleSubmit}
    >
      {({
        isValid,
        isSubmitting,
        handleReset,
        errors,
        handleChange,
        values,
      }) => {
        return (
          <Form
            encType={encType}
            ref={formRef}
            onChange={(e) => {
              const { name, value, id, type } = e.target
              if (type !== 'file') handleChange(e)
              handleDataLayerChange({ [id]: value })
            }}
          >
            {fieldGroups && fieldGroups.length > 0
              ? fieldGroups.map((fieldset, i) => {
                  return (
                    <fieldset key={`form-fieldset-${i}`}>
                      {fieldset.title ? (
                        <S.FormLegend>{fieldset.title}</S.FormLegend>
                      ) : null}
                      {fieldset.fields && fieldset.fields.length > 0 ? (
                        <Row>
                          {fieldset.fields.map((field, idx) => {
                            const {
                              col,
                              validations,
                              validationType,
                              ...props
                            } = field
                            return (
                              <Col
                                key={`form-field-${props.id}-${i}`}
                                {...col}
                              >
                                <FormDispatcher {...props} />
                              </Col>
                            )
                          })}
                        </Row>
                      ) : null}
                    </fieldset>
                  )
                })
              : null}

            {privacyField ? (
              <S.FormPrivacy>
                <FormDispatcher {...privacyField} />
              </S.FormPrivacy>
            ) : null}

            {submitButton || cancelButton ? (
              <ItemsGrouped
                direction={{ xs: 'row' }}
                space={{ xs: 0 }}
                style={{ justifyContent: alignButtons }}
              >
                {submitButton ? (
                  <Button
                    ariaLabel='Invia il form'
                    primary
                    {...submitButton}
                    inheritedColorScheme='white'
                    disabled={
                      !isValid ||
                      !!Object.entries(errors).length ||
                      isSubmitting
                    }
                  />
                ) : null}
                {cancelButton ? (
                  <Button
                    type='button'
                    ariaLabel='Cancella tutti i campi del form'
                    {...cancelButton}
                    disabled={
                      !isValid ||
                      !!Object.entries(errors).length ||
                      isSubmitting
                    }
                    secondary
                    inheritedColorScheme='white'
                    handleClick={handleReset}
                  />
                ) : null}
              </ItemsGrouped>
            ) : null}

            {hasErrors ? (
              <FormMessage
                status='error'
                messages={submitError}
              />
            ) : null}

            {submitted && !isSubmitting && !hasErrors ? (
              successMessage ? (
                <FormMessage
                  status='success'
                  messages={successMessage}
                />
              ) : null
            ) : null}

            {debug ? <FormDebug /> : null}
          </Form>
        )
      }}
    </Formik>
  )
}

export default FormBuilder
