import { stripTags } from './stripTags'

export const getHeadingID = (text, id = null) => {
  const sanitizedText = text
    ? stripTags(text)
        .toLowerCase()
        .replace(/[^a-zA-Z ]/g, '')
        .replaceAll(' ', '-')
    : ''
  return `${sanitizedText}${id ? `-${id}` : ''}`
}
