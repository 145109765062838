import metropolis from '@theme/fonts'
import styled, { css } from 'styled-components'
import styledMap from 'styled-map'

/**
 * Base
 */
export const Field = styled.div`
  width: 100%;
  margin-bottom: ${({ theme }) => theme.rem(24)};
`

export const FieldLabel = styled.label`
  display: block;
  padding-bottom: ${({ theme }) => theme.rem(4)};

  ${({ theme }) => theme.typo('inputPlaceholder1')};

  font-weight: 700;
`

export const FieldContainer = styled.div`
  /**
   * Input & Textarea 
   */
  input:not([type='checkbox']):not([type='radio']),
  textarea {
    width: 100%;
    padding: ${({ theme }) => theme.rem(10, 14)};

    ${({ theme }) => theme.typo('inputValue1')};

    color: ${({ theme }) => theme.colors.primary.default};
    background-color: ${({ theme }) => theme.colors.grayscale.white};
    border: 1px solid;
    border-color: ${({ theme }) =>
      styledMap('error', {
        true: theme.colors.status.error.default,
        false: theme.colors.primary.light4,
      })};
    border-radius: ${({ theme }) => theme.rem(4)};
    transition:
      border 0.2s ease-in-out,
      box-shadow 0.2s ease-in-out;

    &:not([disabled]),
    &:not([aria-disabled='true']) {
      ${({ theme, error }) =>
        !error &&
        css`
          &:focus {
            border-color: ${theme.colors.secondary.default};
            box-shadow: 0 0 0 ${theme.rem(4)} ${theme.colors.secondary.light5};
          }

          &:not(:placeholder-shown):not(:focus) {
            border-color: ${theme.colors.primary.light2};
          }
        `}
    }

    &:disabled {
      opacity: 0.3;
      cursor: not-allowed;
    }
  }

  input:not([type='checkbox']):not([type='radio']) {
    &:has(+ button) {
      padding-right: ${({ theme }) => theme.rem(36)};

      & + button[aria-label='calendar'] {
        pointer-events: none;
      }
    }
  }

  /**
   * File 
   */
  input[type='file'] {
    cursor: pointer;
    border-color: ${({ theme }) => theme.colors.primary.light4} !important;
    font-weight: 700;

    ${({ theme }) =>
      theme.mediaQuery.lg(`
      padding: ${theme.rem(30, 40)} !important;
    `)};

    ::-webkit-file-upload-button {
      ${metropolis.style}
      padding: ${({ theme }) => theme.rem(12, 24)};
      margin-right: ${({ theme }) => theme.rem(16)};
      background-color: ${({ theme }) => theme.colors.secondary.default};
      color: ${({ theme }) => theme.colors.grayscale.white};
      border: 1px solid ${({ theme }) => theme.colors.secondary.default};
      border-radius: ${({ theme }) => theme.rem(8)};
      text-transform: uppercase;
      font-weight: 700;
      cursor: pointer;
    }
  }

  /**
   * Textarea 
   */
  textarea {
    width: 100% !important;
    max-width: 100%;
    min-width: 100%;
    min-height: ${({ theme }) => theme.rem(102)};
    ${metropolis.style}

    &:disabled {
      resize: none;
    }
  }

  /**
   * Date
   */
  input[type='date'] {
    ${metropolis.style}
    line-height: 1 !important;

    ${({ theme }) =>
      theme.mediaQuery.lg(
        `
      width: 100%;
      min-width: 85%;
      min-height: ${theme.rem(32)};
    `,
        true
      )};

    &::-webkit-calendar-picker-indicator {
      width: 36px;
      height: 36px;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      background: transparent;
      color: transparent;
      cursor: pointer;
      z-index: 1;
    }
  }
`

/**
 * Hint
 */
export const FieldHint = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${({ theme }) => theme.rem(8)};

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.3;
    `}
`

export const FieldHintItem = styled.div`
  display: flex;
  align-items: center;
  font-size: ${({ theme }) => theme.rem(12)};
  color: ${({ theme }) =>
    styledMap('error', {
      true: theme.colors.status.error.default,
      false: theme.colors.primary.default,
    })};

  &:not(:last-child) {
    margin-right: ${({ theme }) => theme.rem(8)};
  }

  i {
    margin-right: ${({ theme }) => theme.rem(4)};

    svg {
      width: ${({ theme }) => theme.rem(16)};
      height: ${({ theme }) => theme.rem(16)};
      color: ${({ theme }) =>
        styledMap('error', {
          true: theme.colors.status.error.default,
          false: theme.colors.primary.default,
        })};
    }
  }
`
