import normalizeLocation from './Location'

const normalizeLocations = ({ locationSearched, locations, banks }) => {
  const rad = (x) => {
    return (x * Math.PI) / 180
  }

  const getDistance = (p1, p2) => {
    const R = 6378137
    const dLat = rad(p2.lat - p1.lat)
    const dLong = rad(p2.lng - p1.lng)
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(rad(p1.lat)) *
        Math.cos(rad(p2.lat)) *
        Math.sin(dLong / 2) *
        Math.sin(dLong / 2)
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
    const d = R * c
    const km = d / 1000
    return km.toFixed(1)
  }

  const normalizedLocations = locations.reduce((acc, curr) => {
    const bank = banks.filter((item) => item.abi == curr.abi)
    const normalizedLocation = normalizeLocation({
      ...curr,
      bank: bank[0].name,
      website: bank[0].website,
      distance: getDistance(
        { lat: curr.latitudine, lng: curr.longitudine },
        { ...locationSearched }
      ),
    })
    return [...acc, normalizedLocation]
  }, [])

  return normalizedLocations
}

export default normalizeLocations
