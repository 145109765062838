import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
  max-width: 1440px;
  margin: 0 auto;
`

export const LinksPart = styled.div`
  width: 100%;
  position: relative;
  margin: ${({ theme }) => theme.rem(30, 0, 30, 30)};
  display: flex;
  flex-direction: column;
  height: max-content;
  row-gap: ${({ theme }) => theme.rem(24)};

  & > div {
    height: 90px;
  }
`

export const GroupSubmenu = styled.div`
  width: 100%;
  display: flex;
  ${({ $buttonHeight }) =>
    $buttonHeight &&
    css`
      height: calc(${$buttonHeight}px + 24px) !important;
    `}
`

export const GroupSubmenuButtonWrapper = styled.div`
  width: 50%;
  height: 100%;
  border-right: 1px solid ${({ theme }) => theme.colors.secondary.light5};
  padding-right: ${({ theme }) => theme.rem(28)};
  & > button {
    width: 100%;
    height: 100%;
  }
`

export const GroupSubmenuItem = styled.div`
  width: 50%;
  display: flex;
  height: 0;
  flex-direction: column;
  align-items: flex-start;
  padding-left: ${({ theme }) => theme.rem(28)};
  position: absolute;
  top: 0;
  right: 0;
  visibility: hidden;
  opacity: 0;
  z-index: 1;

  a,
  button {
    &:not(:last-child) {
      margin-bottom: ${({ theme }) => theme.rem(24)};
    }
  }

  ${({ $visibility }) =>
    $visibility &&
    css`
      height: 100%;
      visibility: visible;
      opacity: 1;
    `}
  ${({ $isSingleChildItem }) =>
    $isSingleChildItem &&
    css`
      position: relative;
      justify-content: center;
    `}
`

export const BlogPart = styled.div`
  width: 100%;
  height: calc(100% - 1px);
  padding: ${({ theme }) => theme.rem(120, 30)};
  background-color: ${({ theme }) => theme.colors.secondary.light5};
`
