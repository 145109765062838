import normalizeCta from './CTA'

const RelatedProducts = ({ theme, products }) => {
  const normalizedProducts = products.reduce((acc, curr) => {
    curr.cta = normalizeCta(curr.cta)
    return [...acc, curr]
  }, [])

  const normalizedData = {
    theme,
    products: normalizedProducts,
  }

  return normalizedData
}

export default RelatedProducts
