import styled, { css } from 'styled-components'
import { DocumentCard } from '@/molecules/Card/styles'

export const ListWrapper = styled.div`
  width: 100%;
  margin: ${({ theme }) => theme.rem(40, 0)};

  ${({ theme }) =>
    theme.mediaQuery.lg(`
    margin: ${theme.rem(80, 0)};
  `)};
`

export const ListHead = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.rem(24)};

  ${({ theme }) =>
    theme.mediaQuery.lg(
      `
    flex-direction: column;
    justify-content: center;
  `,
      true
    )};
`

export const ListItem = styled.div`
  &:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.rem(40)};
  }
`

export const ListContent = styled.div`
  ${DocumentCard} {
    margin-bottom: ${({ theme }) => theme.rem(24)};
  }
`
