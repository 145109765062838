import styled, { css } from 'styled-components'
import { SidebarWrapper } from '../ArchiveSidebar/styles'

export const BlogList = styled.div`
  ${SidebarWrapper} {
    ${({ theme }) =>
      theme.mediaQuery.xs(`
      margin: ${theme.rem(40, 0, 0, 0)};
    `)};
  }
`
