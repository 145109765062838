import React from 'react'
import Image from 'next/image'

import * as S from './styles'
import { Heading, Paragraph } from '@/atoms/Typography'
import { Container, SpacerBase } from '@/atoms/Grid'
import { Form } from '@/atoms'

const Newsletter = ({ image, title, paragraph, privacyText, form }) => {
  return (
    <S.Wrapper>
      {image ? (
        <>
          <S.ImageWrapper>
            <Image
              src={image.url}
              alt={image.alt}
              fill
              sizes='(max-width: 768px) 50vw, (max-width: 1200px) 50vw, 33vw'
            />
          </S.ImageWrapper>
          <SpacerBase xs={4} />
        </>
      ) : null}
      {title ? (
        <Heading
          {...title}
          bold
        />
      ) : null}
      {paragraph ? (
        <>
          <SpacerBase xs={1} />
          <Paragraph {...paragraph} />
        </>
      ) : null}
      {form ? (
        <Container fluid>
          <S.FormWrapper>
            <Form {...form} />
          </S.FormWrapper>
        </Container>
      ) : null}
      {privacyText ? (
        <S.PrivacyText>
          <Paragraph
            typo='label2'
            content={privacyText}
          />
        </S.PrivacyText>
      ) : null}
    </S.Wrapper>
  )
}

export default Newsletter
