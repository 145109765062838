import { useState, useEffect, useRef, useContext } from 'react'

import { SubmenuBackgroundContext } from '@contexts/SubmenuBackground'

import { Button } from '@/atoms'
import { SubmenuForwardingRef } from '../Submenu'

import { StyledMenuDesktopItem } from './styles'

const MenuDesktopItem = ({ items, wrapperRef }) => {
  const [submenu, setSubmenu] = useState(false)
  const submenuRef = useRef(null)
  const menuItem = useRef(null)

  const { setSubmenuBackgroundHeight } = useContext(SubmenuBackgroundContext)

  const openHandler = () => {
    setSubmenu((prev) => {
      const newSubmenuState = !prev
      setSubmenuBackgroundHeight(
        newSubmenuState ? submenuRef.current.offsetHeight : 0
      )
      return newSubmenuState
    })
  }

  const closeHandler = (event) => {
    if (
      submenu &&
      menuItem.current &&
      !menuItem.current.contains(event.target)
    ) {
      setSubmenu(false)
      if (
        !wrapperRef.current.contains(event.target) ||
        (event.code === 'Tab' &&
          submenu &&
          wrapperRef.current.contains(event.target))
      ) {
        setSubmenuBackgroundHeight(0)
      }
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', closeHandler)
    document.addEventListener('keydown', closeHandler)

    return () => {
      document.removeEventListener('mousedown', closeHandler)
      document.removeEventListener('keydown', closeHandler)
    }
  }, [submenu])

  useEffect(() => {
    setSubmenuBackgroundHeight(0)
  }, [])

  return (
    <StyledMenuDesktopItem ref={menuItem}>
      {items ? (
        <>
          <Button
            label={items.title}
            ariaLabel={`${submenu ? 'Chiudi' : 'Espandi'} ${items.title}`}
            tertiary
            uppercase
            typo={'button2'}
            iconEnd={submenu > 0 ? 'chevron-up' : 'chevron-down'}
            inheritedColorScheme='white'
            aria-haspopup='menu'
            aria-expanded={submenu ? 'true' : 'false'}
            handleClick={openHandler}
          />
          <SubmenuForwardingRef
            ref={submenuRef}
            data={items}
            visibility={submenu ? 1 : 0}
          />
        </>
      ) : (
        <a href='#'>{items.title}</a>
      )}
    </StyledMenuDesktopItem>
  )
}

export default MenuDesktopItem
