import styled, { css } from 'styled-components'
import gridConfig, { GRID_DIMENSIONS } from '@theme/gridConfig'

export const StyledItemsGrouped = styled.div`
  display: flex;

  ${(p) =>
    p.$direction &&
    css`
      ${typeof p.$direction === 'object'
        ? GRID_DIMENSIONS.map(
            (d) =>
              gridConfig(p).breakpoints[d] &&
              gridConfig(p).media[d]`${
                p.$direction[d] && `flex-direction: ${p.$direction[d]}`
              }`
          )
        : `flex-direction: ${p.$direction ? p.$direction : 'column'};`}
    `}

  ${({ theme }) => css`
    ${(p) =>
      p.space &&
      css`
        > *:not(:last-child) {
          ${(p) =>
            p.space &&
            css`
              ${typeof p.space === 'object'
                ? GRID_DIMENSIONS.map(
                    (d) =>
                      gridConfig(p).breakpoints[d] &&
                      gridConfig(p).media[d]`${
                        p.$direction[d] &&
                        `
              margin-${
                p.$direction[d] == 'row' ? 'right' : 'bottom'
              }: ${theme.rem(p.space[d] ? p.space[d] : 8)};
              margin-${p.$direction[d] == 'row' ? 'bottom' : 'right'}: 0;
            `
                      }`
                  )
                : `
              margin-right: ${theme.rem(p.space ? p.space : 8)};
            `}
            `}
        }
      `}
  `}

  ${({ isWrap }) =>
    isWrap &&
    css`
      flex-wrap: wrap;
      row-gap: ${({ theme }) => theme.rem(16)};
    `}
`
