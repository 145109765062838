import { useEffect, useState } from 'react'

import formFields from '@fixtures/contact-form-fields.json'

import { getHeadingID } from '@utils/getHeadingID'

import { Form } from '@/atoms'
import Section from '../Section'

import { usePiwikPro } from '@piwikpro/next-piwik-pro'
import { useRouter } from 'next/router'
import * as S from '../styles'

const DYNAMIC_FORM_FIELDS = {
  accidents: {
    title: 'Nr. Sinistro',
    fields: [
      {
        type: 'text',
        id: 'accidentNumber',
        name: 'accidentNumber',
        placeholder: 'Nr. Sinistro',
        col: {
          xs: 12,
          md: 6,
        },
      },
    ],
  },
}

const SectionsWrapper = ({
  section,
  dataLayer,
  onDataLayerChange,
  triggerDataLayerEvent,
}) => {
  const [form, setForm] = useState(formFields)
  const [showForm, setShowForm] = useState(false)
  const [sectionsList, setSectionsList] = useState([])
  const [prevSection, setPrevSection] = useState({})
  const { UserManagement, DataLayer, CustomEvent } = usePiwikPro()
  const router = useRouter()

  const findSection = (id, currentNode) => {
    let currentChild, result

    const sectionFound = currentNode.options.filter((item) => item.id == id)

    if (sectionFound.length > 0 && id == sectionFound[0].id) {
      return sectionFound[0]
    } else {
      for (let i = 0; i < currentNode.options.length; i += 1) {
        currentChild = currentNode.options[i].nextLevel

        // Search in the current child
        result = currentChild ? findSection(id, currentChild) : false

        // Return the result if the node has been found
        if (result !== false) {
          return result
        }
      }

      // The node has not been found and we have no more options
      return false
    }
  }

  const handleClick = (params) => {
    const { id, path, isLastLevel, depthLevel } = params

    if (prevSection.id == id) {
      return false
    }

    if (isLastLevel) {
      handleLastLevel(depthLevel, id, path)
    } else {
      handleNotLastLevel(id, depthLevel, prevSection, section)
    }

    function handleLastLevel(depthLevel, id, path) {
      if (depthLevel == 1) {
        setSectionsList([])
      }
      // Se siamo al secondo livello di profondità e ci sono più di una sezione, rimuovi l'ultima sezione
      else if (depthLevel == 2 && sectionsList.length > 1) {
        let list = sectionsList
        list.pop()
        setSectionsList([...list])
      }

      // Imposta i valori iniziali del form
      form.initialValues.recipient = id
      form.initialValues.path = path
      setShowForm(true)
    }

    function handleNotLastLevel(id, depthLevel, prevSection, section) {
      const nextSection = findSection(id, section)
      let list = sectionsList

      if (
        typeof prevSection.depthLevel != 'undefined' &&
        depthLevel > prevSection.depthLevel
      ) {
        form.initialValues.recipient = id
        form.initialValues.path = path
        list.push(nextSection.nextLevel ? nextSection.nextLevel : section)
      } else {
        if (depthLevel != 2 && depthLevel <= prevSection.depthLevel) {
          list = []
        }
        list.push(nextSection.nextLevel ? nextSection.nextLevel : section)
        setShowForm(false)
      }

      setSectionsList([...list])
    }

    updateDataLayer(params, prevSection)

    setPrevSection(params)
  }

  const updateDataLayer = ({ depthLevel, isLastLevel = false, path = '' }) => {
    const isFirstLevel = depthLevel === 1
    if (isFirstLevel) {
      if (isLastLevel) {
        const pathSlices = Array.isArray(path) ? path.split('->') : [path]
        onDataLayerChange({ pathSlices }, isFirstLevel)
        return
      }
      onDataLayerChange({}, isFirstLevel)
      return
    }
    if (isLastLevel) {
      const pathSlices = path.split('->')
      onDataLayerChange({ pathSlices })
    }
    return
  }

  const updateFormBasedOnPrevSection = (form, prevSection) => {
    if (!form || !prevSection?.id) return
    let updatedForm
    if (prevSection.id.includes('accidents')) {
      updatedForm = {
        ...form,
        fieldGroups: [DYNAMIC_FORM_FIELDS.accidents, ...form.fieldGroups],
      }
    } else {
      updatedForm = {
        ...form,
        fieldGroups: form.fieldGroups.filter(
          (group) => group.title !== 'Nr. Sinistro'
        ),
      }
    }
    setForm(updatedForm)
  }

  useEffect(
    () => updateFormBasedOnPrevSection(form, prevSection),
    [prevSection]
  )
  return (
    <S.SectionsWrapper>
      <Section
        {...section}
        handleClick={handleClick}
      />
      {sectionsList.length > 0
        ? sectionsList.map((item, i) => (
            <Section
              key={getHeadingID(item.label.text, item.label.id)}
              {...item}
              handleClick={handleClick}
            />
          ))
        : null}
      {showForm ? (
        <Form
          {...form}
          onDataLayerChange={onDataLayerChange}
          dataLayer={dataLayer}
          triggerDataLayerEvent={triggerDataLayerEvent}
        />
      ) : null}
    </S.SectionsWrapper>
  )
}

export default SectionsWrapper
