import { Field } from '@/atoms/Form/FormFields/FieldBase/styles'
import styled, { css } from 'styled-components'

export const MyQuoteBar = styled.div`
  width: 100%;
  position: fixed;
  bottom: 0;
  padding: ${({ theme }) => theme.rem(24, 0)};
  background-color: ${({ theme }) => theme.colors.primary.default};
  border-top: 0.5px solid ${({ theme }) => theme.colors.grayscale.white};
  border-radius: ${({ theme }) => theme.rem(20, 20, 0, 0)};
  color: ${({ theme }) => theme.colors.grayscale.white};
  z-index: ${({ theme }) => theme.layers.myQuoteBar};

  transform: translateY(100%);
  animation-duration: 0.2s;
  animation-fill-mode: both;

  ${({ $open }) =>
    !$open
      ? css`
          animation-name: slideOut;
        `
      : css`
          animation-name: slideIn;
          animation-delay: 0.6s;
        `}

  form {
    width: 100%;

    ${({ theme }) =>
      theme.mediaQuery.lg(`
      display: flex;
      align-items: flex-end;
    `)};

    ${({ theme }) =>
      theme.mediaQuery.lg(
        `
      margin-top: ${theme.rem(20)};
    `,
        true
      )};

    ${Field} {
      ${({ theme }) =>
        theme.mediaQuery.lg(`
        margin-bottom: 0;
        margin-right: ${theme.rem(10)};
      `)};
    }

    button {
      ${({ theme }) =>
        theme.mediaQuery.lg(
          `
        width: 100%;
      `,
          true
        )};
    }
  }

  @keyframes slideIn {
    from {
      transform: translateY(100%);
    }

    to {
      transform: translateY(0%);
    }
  }

  @keyframes slideOut {
    from {
      transform: translateY(0%);
    }

    to {
      transform: translateY(100%);
    }
  }
`

export const MyQuoteBarFormWrapper = styled.div`
  width: 100%;
`

export const MyQuoteBarClose = styled.div`
  button {
    margin-left: ${({ theme }) => theme.rem(20)};
    color: ${({ theme }) => theme.colors.grayscale.white};
    background-color: ${({ theme }) => theme.colors.primary.default};
    border: 1px solid ${({ theme }) => theme.colors.primary.default};

    &:focus,
    &:active {
      background-color: ${({ theme }) =>
        theme.colors.primary.default} !important;
      color: ${({ theme }) => theme.colors.grayscale.white} !important;
    }
  }
`

export const MyQuoteBarText = styled.div`
  position: relative;
  margin-left: ${({ theme }) => theme.rem(40)};
  padding-right: ${({ theme }) => theme.rem(76)};

  ${({ theme }) =>
    theme.mediaQuery.lg(
      `
    margin-left: ${theme.rem(20)};
  `,
      true
    )};

  ${MyQuoteBarClose} {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);

    button {
      margin-left: ${({ theme }) => theme.rem(60)};
    }
  }
`
