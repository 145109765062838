import styled, { css } from 'styled-components'
import { Field } from '../FormFields/FieldBase/styles'

export const FormLegend = styled.legend`
  ${({ theme }) => theme.typo('paragraph1')};
  font-weight: 700;
  margin-bottom: ${({ theme }) => theme.rem(8)};
`

export const FormPrivacy = styled.div`
  ${Field} {
    max-width: 340px;
    margin-left: auto;
    margin-right: auto;
  }
`
