import React, { useEffect, useRef } from 'react'

import { Button, Icon } from '@/atoms'
import { SpacerBase } from '@/atoms/Grid'
import { Heading, Paragraph } from '@/atoms/Typography'

import * as S from './styles'

const ContentCheckmark = () => {
  return (
    <Icon
      name='check'
      ariaLabel='Compreso nella polizza'
      huge
    />
  )
}

const ContentPrice = ({ price, discountedPrice, extraText }) => {
  return (
    <>
      {discountedPrice ? (
        <Paragraph>
          <del>{discountedPrice} €</del>
        </Paragraph>
      ) : null}
      {price ? <Paragraph>{price} €</Paragraph> : null}
      {extraText ? <Paragraph>{extraText}</Paragraph> : null}
    </>
  )
}

const Table = ({
  tableCornerTagline,
  tableCornerTitle,
  tableHead,
  tableRows,
}) => {
  const el = useRef(null)
  const startEl = useRef(null)
  const endEl = useRef(null)

  const setDimensions = (applySticky, mobile) => {
    const els = el.current.querySelectorAll('.table-head__column')
    const colW =
      el.current.getBoundingClientRect().width /
      el.current.querySelectorAll('.table-head__column').length

    els.forEach((col) => {
      if (!col.parentElement) return

      const { height } = col.parentElement.getBoundingClientRect() || {}
      col.style.width = col.parentElement.style.width = `${colW}px`
      col.parentElement.style.height = `${height}px`

      if (applySticky) {
        col.style.height = `${height}px`
        col.classList.add('sticky')
      } else {
        col.style.height = `100%`
        col.classList.remove('sticky')
      }
    })
  }

  const setDimensionsMobile = (applySticky) => {
    const els = el.current?.querySelectorAll('.table-head__column')

    els.forEach((col, _, arr) => {
      col.style.width = col.parentElement.style.width = '100%'
      col.parentElement.style.height = 'auto'
      col.style.height = '100%'

      if (applySticky) {
        col.classList.add('sticky')
      } else {
        col.classList.remove('sticky')
      }
    })
  }

  useEffect(() => {
    const setSizes = () => {
      if (!el.current) return

      if (startEl.current && endEl.current) {
        const thead = el.current.querySelectorAll('.table-head')
        const rows = el.current.querySelectorAll('.table-row')
        const theadHeight =
          ((thead && thead[0].getBoundingClientRect()) || {}).height || 0
        const lastRowHeight =
          ((rows && rows[rows.length - 1].getBoundingClientRect()) || {})
            .height || 0
        endEl.current.style.top = `-${theadHeight + lastRowHeight / 2}px`

        const states = new Map()
        const observer = new IntersectionObserver(
          (entries) => {
            entries.forEach((e) => {
              states.set(e.target, e.boundingClientRect)
            })
            const { top } = states.get(startEl.current) || {}
            const { top: bottom } = states.get(endEl.current) || {}

            if (top < 0 && bottom > 0) {
              if (el.current && window.innerWidth >= 992) {
                setDimensions(true)
              } else {
                setDimensionsMobile()
              }
            } else {
              if (el.current && window.innerWidth >= 992) {
                setDimensions(false)
              } else {
                setDimensionsMobile(false)
              }
            }
          },
          {
            threshold: [0],
          }
        )

        observer.observe(startEl.current)
        observer.observe(endEl.current)
      }
    }

    setSizes()
    window.addEventListener('resize', setSizes)

    return () => {
      window.removeEventListener('resize', setSizes)
    }
  }, [el, startEl, endEl])

  return (
    <>
      <S.TableWrapper ref={el}>
        <div ref={startEl} />
        <S.Table
          role='table'
          aria-label={tableCornerTitle}
          aria-describedby='semantic_elements_table_desc'
        >
          <S.TableHead
            className='table-head'
            role='rowgroup'
          >
            <S.TableHeadRow role='row'>
              <S.TableHeadHeader
                role='columnheader'
                aria-sort='none'
              >
                <S.TableHeadContent
                  columns={tableHead.length}
                  className='table-head__column'
                >
                  <Paragraph typo='label2'>{tableCornerTagline}</Paragraph>
                  <Heading
                    as='h5'
                    typo='displaySM'
                  >
                    {tableCornerTitle}
                  </Heading>
                </S.TableHeadContent>
              </S.TableHeadHeader>
              {tableHead.map((el, i) => {
                const ctaID = `table-head-cta-${i}-${el.cta.id}`
                return (
                  <S.TableHeadHeader
                    key={el.id}
                    role='columnheader'
                    aria-sort='none'
                  >
                    <S.TableHeadContent
                      columns={tableHead.length}
                      className='table-head__column'
                    >
                      <S.TableHeadText>
                        <S.TableHeadContentTitle>
                          {/* <Heading as='h5' typo={tableHead.length > 2 ? 'displaySM' : 'displayMD'} bold>{el.title}</Heading> */}
                          <Heading
                            as='h5'
                            typo='displaySM'
                            bold
                          >
                            {el.title}
                          </Heading>
                        </S.TableHeadContentTitle>
                        {el.description ? (
                          <S.TableHeadContentText>
                            <Paragraph typo='paragraph2'>
                              {el.description}
                            </Paragraph>
                          </S.TableHeadContentText>
                        ) : null}
                        {el.tag ? (
                          <S.TableHeadContentTag>
                            <Paragraph
                              typo='button2'
                              bold
                            >
                              {el.tag}
                            </Paragraph>
                          </S.TableHeadContentTag>
                        ) : null}
                      </S.TableHeadText>
                      {el.price || el.cta ? (
                        <div>
                          {el.price ? (
                            <S.TableHeadContentPrice>
                              <Heading
                                as='h6'
                                typo='displaySM'
                                bold
                              >
                                {el.price} €
                              </Heading>
                            </S.TableHeadContentPrice>
                          ) : null}
                          {el.cta.label ? (
                            <S.TableHeadContentCTA>
                              <Button
                                {...el.cta}
                                id={ctaID}
                                primary
                              />
                            </S.TableHeadContentCTA>
                          ) : null}
                        </div>
                      ) : null}
                    </S.TableHeadContent>
                  </S.TableHeadHeader>
                )
              })}
            </S.TableHeadRow>
          </S.TableHead>
          <S.TableBody role='rowgroup'>
            {tableRows.map((row, idx) => {
              return (
                <S.TableRow
                  key={row.id}
                  className='table-row'
                  role='row'
                  aria-rowindex={10 + idx}
                >
                  <S.TableBodyCell
                    highlighted={row.highlighted}
                    role='cell'
                  >
                    {row.tag ? (
                      <>
                        <S.TableBodyCellTag>
                          <Paragraph
                            typo='button2'
                            bold
                          >
                            {row.tag}
                          </Paragraph>
                        </S.TableBodyCellTag>
                        <SpacerBase xs={1} />
                      </>
                    ) : null}
                    <Paragraph
                      typo='paragraph1'
                      bold
                    >
                      {row.title}
                    </Paragraph>
                    {row.description ? (
                      <>
                        <SpacerBase xs={1} />
                        <Paragraph typo='paragraph2'>
                          {row.description}
                        </Paragraph>
                      </>
                    ) : null}
                  </S.TableBodyCell>
                  {row.cells.map((el) => {
                    const contentType = {
                      checkmark: ContentCheckmark,
                      price: ContentPrice,
                      empty: () => '',
                    }
                    const Component = contentType[el.type]
                    return (
                      <S.TableBodyCell
                        key={el.id}
                        highlighted={row.highlighted}
                        role='cell'
                      >
                        <Component {...el} />
                      </S.TableBodyCell>
                    )
                  })}
                </S.TableRow>
              )
            })}
          </S.TableBody>
          <S.TableFooter role='rowgroup'>
            <S.TableRow role='row'>
              <S.TableBodyCell role='cell'></S.TableBodyCell>
              {tableHead.map((el, i) => {
                const ctaID = `table-footer-cta-${i}-${el.cta.id}`
                return (
                  <S.TableBodyCell
                    role='cell'
                    key={`table-footer-${i}-${el.id}`}
                  >
                    {el.cta.label ? (
                      <Button
                        {...el.cta}
                        id={ctaID}
                        primary
                      />
                    ) : null}
                  </S.TableBodyCell>
                )
              })}
            </S.TableRow>
          </S.TableFooter>
        </S.Table>
        <div
          style={{ position: 'relative', zIndex: '-1' }}
          ref={endEl}
        />
      </S.TableWrapper>
    </>
  )
}

export default Table
