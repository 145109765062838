import styled, { css } from 'styled-components'

export const StyledSubmenu = styled.div`
  height: auto;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  /* border-bottom: 1px solid ${({ theme }) => theme.colors.primary.light2}; */
  z-index: ${({ theme }) => theme.layers.submenu};

  visibility: hidden;
  opacity: 0;
  transition:
    opacity 0.2s ease-out,
    visibility 0.2s ease-out;

  ${({ $visibility }) =>
    $visibility &&
    css`
      visibility: visible;
      opacity: 1;
      transition:
        opacity 0.3s ease-in 0.1s,
        visibility 0.3s ease-in 0.1s;
    `}
`
