import React, { useContext } from 'react'
import SectionThemeContext from '@contexts/SectionTheme'
import * as S from './styles'

const Spacer = ({ size, separator }) => {
  const colorScheme = useContext(SectionThemeContext)

  return (
    <S.Spacer
      colorScheme={colorScheme}
      size={size}
    >
      {separator && <S.SpacerSeparator />}
    </S.Spacer>
  )
}

export default Spacer
