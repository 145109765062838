import styled, { css } from 'styled-components'
import { StyledTab, StyledTabContent } from '../Tabs/styles'

export const FundHistory = styled.div``

export const FundHistoryWrapper = styled.div`
  max-width: ${({ theme }) => theme.rem(800)};
  margin: 0 auto;
`

export const FundHistoryContent = styled.div`
  ${StyledTab} {
    padding: 0;
  }

  ${StyledTabContent} {
    padding: ${({ theme }) => theme.rem(20, 0, 0)};
  }
`
