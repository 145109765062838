const normalizeImage = (data) => {
  const normalizedData = data
    ? {
        id: data?.id,
        url: data?.url,
        width: data?.width,
        height: data?.height,
        name: data?.name,
        // alt: data?.alternativeText ? data.alternativeText : (data?.name ? data.name : null),
        alt: data?.alternativeText ? data.alternativeText : '',
        caption: data?.caption,
      }
    : null

  return normalizedData
}

export default normalizeImage
