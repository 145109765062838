import React from 'react'
import PropTypes from 'prop-types'
import { StyledItemsGrouped } from './styles'

const ItemsGrouped = ({ children, direction, space, isWrap, style }) => {
  return (
    <StyledItemsGrouped
      $direction={direction}
      space={space}
      isWrap={isWrap}
      style={style}
    >
      {children}
    </StyledItemsGrouped>
  )
}

ItemsGrouped.displayName = 'Items Grouped'

ItemsGrouped.propTypes = {
  direction: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  space: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  children: PropTypes.node,
}

export default ItemsGrouped
