import normalizeCta from '@models/CTA'
import normalizeImage from '@models/Image'

const Banner = ({
  tagline,
  title,
  paragraph,
  image,
  bulletPoints,
  ctaPrimary,
  ctaSecondary,
  variant,
  theme = 'white',
  video,
}) => {
  const normalizedData = {
    tagline,
    title,
    paragraph,
    image: normalizeImage(image),
    bulletPoints,
    ctaPrimary: normalizeCta(ctaPrimary),
    ctaSecondary: normalizeCta(ctaSecondary),
    variant,
    theme,
    video,
  }

  return normalizedData
}

export default Banner
