import React from 'react'
import { Col, Container, Row } from '@/atoms/Grid'
import Card from '@/molecules/Card'
import * as S from './styles'

const RelatedProducts = ({ products }) => {
  return (
    <S.RelatedProducts>
      <Container>
        {products.length > 0 ? (
          <Row>
            {products.map((item, i) => {
              return (
                <S.RelatedProductsCard
                  key={item.id}
                  xs={12}
                  lg={6}
                >
                  <Card
                    data={item}
                    type={'relatedProduct'}
                  />
                </S.RelatedProductsCard>
              )
            })}
          </Row>
        ) : null}
      </Container>
    </S.RelatedProducts>
  )
}

export default RelatedProducts
