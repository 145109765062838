import { getHeadingID } from '@utils/getHeadingID'

import { Button } from '@/atoms'
import { SpacerBase } from '@/atoms/Grid'
import { Heading, Paragraph } from '@/atoms/Typography'
import { Fragment, useContext } from 'react'

import { CookiebotDeclarationContext } from '@contexts/CookiebotDeclaration'
import {
  FaqAccordion,
  FaqAccordionContent,
  FaqAccordionHeader,
  FaqAccordionHeaderContent,
  FaqAccordionToggle,
} from './styles'

const Faq = ({
  refs,
  accordion,
  tabIndex,
  disabled,
  title,
  content,
  link,
  linkSecondary,
  handleClick,
  passedKey,
}) => {
  const CookiebotDeclarationScript = useContext(CookiebotDeclarationContext)
  const id = getHeadingID(title, passedKey)

  const isCookieDeclaration = content === 'COOKIE_DECLARATION'

  return (
    <FaqAccordion
      tabIndex={tabIndex}
      ref={refs.wrapper}
      disabled={disabled}
      key={passedKey}
    >
      <FaqAccordionHeader
        ref={refs.header}
        open={accordion.open}
      >
        <FaqAccordionHeaderContent>
          <Heading
            typo={'displayXS'}
            medium
          >
            {title}
          </Heading>
        </FaqAccordionHeaderContent>
        <FaqAccordionToggle open={accordion.open}>
          <Button
            ariaLabel={
              accordion.open
                ? `Riduci${title ? ` ${title}` : ''}`
                : `Espandi${title ? ` ${title}` : ''}`
            }
            handleClick={() => handleClick(accordion)}
            secondary
            aria-expanded={accordion.open}
            aria-controls={id}
          >
            <span />
            <span />
          </Button>
        </FaqAccordionToggle>
      </FaqAccordionHeader>
      <FaqAccordionContent
        ref={refs.content}
        id={id}
        open={accordion.open}
        role={'region'}
      >
        {isCookieDeclaration ? (
          <CookiebotDeclarationScript />
        ) : (
          <Fragment>
            <Paragraph as='div'>{content}</Paragraph>
            {link ? (
              <>
                <SpacerBase xs={1} />
                <Button
                  {...link}
                  iconEnd='chevron-right'
                  tertiary
                />
              </>
            ) : null}
            {linkSecondary ? (
              <>
                <SpacerBase xs={1} />
                <Button
                  {...linkSecondary}
                  iconEnd='chevron-right'
                  tertiary
                />
              </>
            ) : null}
          </Fragment>
        )}
      </FaqAccordionContent>
    </FaqAccordion>
  )
}

export default Faq
