'use client'

import React, { memo, useEffect, useRef } from 'react'
// import dynamic from 'next/dynamic'

// const Plyr = dynamic(() => import('plyr-react'), { ssr: false })
// import 'plyr-react/plyr.css'

import * as S from './styles'

const Video = ({ provider = 'youtube', url, options = {}, ...props }) => {
  // const playerRef = useRef(null)

  // if (provider == 'youtube') {
  //   const noCookies = {
  //     youtube: {
  //       noCookie: true,
  //       widget_referrer: 0,
  //       rel: 0,
  //       showinfo: 0,
  //     },
  //   }
  //   options = { ...options, ...noCookies }
  // }

  // const videoProps = {
  //   source: {
  //     type: 'video',
  //     sources: [
  //       {
  //         src: url,
  //         provider,
  //       },
  //     ],
  //   },
  //   options,
  // }

  // useEffect(() => {
  //   console.log('internal plyr instance:', playerRef.current.plyr)
  // })

  const validURL = (str) => {
    var pattern = new RegExp(
      '^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$',
      'i'
    ) // fragment locator
    return !!pattern.test(str)
  }

  if (!validURL(url)) {
    url = `https://www.youtube-nocookie.com/embed/${url}?controls=1&autoplay=0&rel=0&noCookie=true`
  }

  return (
    <S.Video>
      {/* <Plyr ref={playerRef} {...videoProps} {...props}>
      
      </Plyr> */}
      <iframe
        width='100%'
        height='100%'
        src={url}
        title='YouTube video player'
        allow='accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
        allowFullScreen
      ></iframe>
    </S.Video>
  )
}

export default memo(Video, (prevProps, nextProps) => {
  return prevProps.url === nextProps.url
})
