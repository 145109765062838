import styled, { css } from 'styled-components'
import { RichText } from '@/organisms/RichText/styles'
import { Col } from '@/atoms/Grid'
import { DocumentCard } from '@/molecules/Card/styles'

export const StyledTab = styled.div`
  padding: ${({ theme }) => theme.rem(40, 0)};
  background-color: ${({ theme }) => theme.colors.secondary.light6};
  overflow: hidden;

  ${({ minimal }) =>
    minimal &&
    css`
      background-color: ${({ theme }) => theme.colors.grayscale.white};
    `}
`

export const StyledTabInner = styled.div`
  padding: ${({ theme }) => theme.rem(40, 0, 0, 0)};
  border-radius: ${({ theme }) => theme.rem(16)};
  background-color: ${({ theme }) => theme.colors.grayscale.white};

  ${({ theme }) =>
    theme.mediaQuery.lg(`
    padding: ${theme.rem(40, 80, 0, 80)};
  `)}
`

export const StyledTabHeader = styled.nav``

export const StyledTabHeaderItemList = styled.div`
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.primary.light4};
  display: flex;

  .swiper {
    width: 100%;
    margin: 0;
  }

  .swiper-slide {
    display: inline-flex;
    width: auto;
  }
`

export const StyledTabHeaderItem = styled.div`
  padding: ${({ theme }) => theme.rem(16, 24)};
  position: relative;
  z-index: 1;
  overflow: hidden;
  cursor: pointer;
  border-radius: ${({ theme }) => theme.rem(10, 10, 0, 0)};

  &:not(:last-child) {
    margin-right: ${({ theme }) => theme.rem(8)};
  }

  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.colors.secondary.light5};
    position: absolute;
    left: 0;
    bottom: 0;
    transform: translateY(100%);
    transition: transform ${({ theme }) => theme.timing.fast} 0s;
    z-index: -1;
    border-radius: ${({ theme }) => theme.rem(10, 10, 0, 0)};
    display: block;
  }

  &:after {
    content: '';
    width: 100%;
    height: 1.7px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    background-color: ${({ theme }) => theme.colors.secondary.default};
    transition: opacity ${({ theme }) => theme.timing.fast} 0s;
    z-index: 1;
    display: block;
    opacity: 0;
  }

  &:hover {
    &:before {
      transform: translateY(0);
    }
  }

  &:focus {
    &:before {
      background-color: ${({ theme }) => theme.colors.secondary.light4};
      transform: translateY(0);
    }
  }

  ${({ isSelected }) =>
    isSelected &&
    css`
      &:before {
        transform: translateY(0);
      }

      &:after {
        opacity: 1;
      }
    `}
`

export const StyledTabContent = styled.div`
  ${({ theme }) =>
    theme.mediaQuery.lg(`
    padding: ${theme.rem(80, 0)}
  `)}
`

/**
 * CONTENT
 */

export const StyledTabsContentInner = styled.div`
  padding: ${({ theme }) => theme.rem(0, 16, 40)};

  ${({ theme }) =>
    theme.mediaQuery.lg(`
    padding: 0;
  `)}
`

export const StyledTabsRichTextWrapper = styled.div`
  padding: ${({ theme }) => theme.rem(30, 0, 40, 0)};
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${({ theme }) =>
    theme.mediaQuery.lg(`
    padding: 0;
  `)}

  ${RichText} {
    padding: 0;
  }
`

export const StyledTabsCardWrapper = styled.div`
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: repeat(4, 280px);
  gap: 40px 20px;
  /* display: flex; */
  overflow-x: auto;

  ${({ theme }) =>
    theme.mediaQuery.lg(`
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(2, 1fr);
  `)}
`

export const StyledTabsImageWrapper = styled.div`
  width: 100%;
  margin-top: ${({ theme }) => theme.rem(20)};
  aspect-ratio: 4 / 5;
  border-radius: ${({ theme }) => theme.rem(32)};
  position: relative;
  overflow: hidden;

  ${({ theme }) =>
    theme.mediaQuery.lg(`
    height: ${theme.rem(560)};
    margin-top: 0;
  `)}

  img {
    object-fit: cover;
  }
`

export const StyledTabsVideoWrapper = styled(StyledTabsImageWrapper)`
  aspect-ratio: 16 / 9;
  align-self: flex-start;

  ${({ theme }) =>
    theme.mediaQuery.lg(`
    height: auto;
  `)}
`

export const StyledContentCardItem = styled.div``

export const StyledTabsDocWrapper = styled.div`
  &:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.rem(24)};
  }

  ${DocumentCard} {
    padding: ${({ theme }) => theme.rem(16)};
  }
`

/**
 * TABLE
 */

export const TabTable = styled.div`
  max-height: 550px;
  border: 0.2px solid ${({ theme }) => theme.colors.primary.light3};
  overflow-x: hidden;
  overflow-y: auto;

  ${({ theme }) =>
    theme.mediaQuery.md(
      `
    max-height: 500px;
  `,
      true
    )}
`

export const TabTableHead = styled.div`
  position: sticky;
  top: 0;
  background-color: ${({ theme }) => theme.colors.grayscale.white};
  border-bottom: 1px solid ${({ theme }) => theme.colors.primary.light4};
`

export const TabTableHeadCell = styled(Col)`
  justify-content: center;
  align-items: center;
  padding: ${({ theme }) => theme.rem(16, 0)};
  color: ${({ theme }) => theme.colors.primary.light2};

  &:not(:last-child) {
    border-right: 1px solid ${({ theme }) => theme.colors.primary.light4};
  }
`

export const TabTableBody = styled.div``

export const TabTableBodyCell = styled(Col)`
  justify-content: center;
  align-items: center;
  padding: ${({ theme }) => theme.rem(20)};
  border-bottom: 1px solid ${({ theme }) => theme.colors.primary.light4};

  &:not(:last-child) {
    border-right: 1px solid ${({ theme }) => theme.colors.primary.light4};
  }
`
