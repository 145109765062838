import styled, { css } from 'styled-components'
import ReactSelect from 'react-select'
import { StyledButton } from '@/atoms/Button/styles'

export const CustomSelect = styled(ReactSelect)`
  ${({ theme }) => theme.typo('inputValue1')};

  .caffeina {
    /* &__value-container {
      &--is-multi {
        position: relative;
      }
    } */

    &__placeholder {
      margin-left: 10px;
    }

    &__control {
      border-color: ${({ theme }) => theme.colors.primary.light4};
      cursor: pointer;

      &:hover {
        border-color: ${({ theme }) => theme.colors.primary.light4};
      }

      &--is-focused {
        border-color: ${({ theme }) =>
          theme.colors.secondary.default} !important;
        box-shadow: 0 0 0 ${({ theme }) => theme.rem(4)}
          ${({ theme }) => theme.colors.secondary.light5};
        outline: none;
      }

      &--is-selected {
        border-color: ${({ theme }) => theme.colors.primary.light2};
      }
    }

    &__dropdown-indicator {
      svg {
        color: ${({ theme }) => theme.colors.secondary.default};
      }
    }

    &__indicator-separator {
      display: none;
    }

    &__menu {
      background-color: ${({ theme }) => theme.colors.grayscale.white};
      overflow: hidden;
      z-index: 2;
    }

    &__menu-list {
      padding: 0;
    }

    &__input {
      box-shadow: none !important;
    }
  }
`

export const CustomSelectButtons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  bottom: 0;
  padding: ${({ theme }) => theme.rem(16)};
  background-color: ${({ theme }) => theme.colors.primary.light6};
`

export const CustomSelectClear = styled.span`
  ${StyledButton} {
    padding: ${({ theme }) => theme.rem(5)};
  }
`
