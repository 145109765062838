import styled, { css } from 'styled-components'
import gridConfig, { GRID_DIMENSIONS } from '@theme/gridConfig'
import { getSpacerSize } from '@utils/getSpacerSize'

export const Spacer = styled.div`
  ${({ size }) => size && getSpacerSize(size)}

  display: flex;
  align-items: center;

  ${({ theme, colorScheme }) =>
    colorScheme &&
    css`
      background-color: ${theme.colors.surface[colorScheme].background};
    `}
`

export const SpacerSeparator = styled.hr`
  ${(p) => css`
    ${({ theme }) =>
      GRID_DIMENSIONS.map(
        (d) =>
          gridConfig(p).container[d] &&
          gridConfig(p).media[d]`
      ${
        typeof gridConfig(p).container[d] === 'number'
          ? `max-width: ${theme.rem(gridConfig(p).container[d])};`
          : `width: 100%;`
      }
    `
      )}
  `}

  border: 0;
  border-top: 1px solid ${({ theme }) => theme.colors.primary.light4};
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25); */
`
