const ENDPOINTS = {
  pages: 'api/pages/?pagination[pageSize]=300&populate=deep',
  previewPages: 'api/pages',
  blog: 'api/blog-posts/all',
  previewBlogPost: 'api/blog-posts',
  blogHome: 'api/blog-homepage?populate=deep',
  faq: 'api/faq-categories?populate=deep',
  footer: 'api/footer?populate=deep',
  header: 'api/header?populate=deep',
  storeLocatorPage: 'api/store-locator?populate=deep',
  storeLocatorData: 'api/arca/banche',
  contactPage: 'api/contact-page?populate=deep',
  documents: 'api/product-info-doc-archive?populate=deep',
  productsFinancial: 'api/arca/fondi/prodotti',
  funds: 'api/arca/fondi',
  quotationPage: 'api/fund-listing?populate=deep',
  globalSearch: 'api/search',
  statements: 'api/prospect-report?populate=deep',
}

const API_URL = {
  cms: process.env.API_URL || process.env.NEXT_PUBLIC_API_URL,
  front: process.env.NEXT_PUBLIC_SITE_URL,
  proxy: process.env.NEXT_PUBLIC_API_PROXY_URL,
}

export { ENDPOINTS, API_URL }
