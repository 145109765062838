import React from 'react'

import Image from 'next/image'

import * as S from './styles'
import { Col, Container, Row } from '@/atoms/Grid'

const TwoImages = ({ imageLeft, imageRight, imageLeftAlignment }) => {
  return imageLeft && imageRight ? (
    <S.Wrapper imageLeftAlignment={imageLeftAlignment}>
      <Container>
        <Row
          reverse={
            imageLeftAlignment == 'right'
              ? ['xs', 'sm', 'md', 'lg', 'xl', 'xxl']
              : false
          }
        >
          <Col
            xs={12}
            lg={6}
            $offset={imageLeftAlignment == 'right' ? { lg: 1 } : null}
            align={'center'}
          >
            <S.ImageLeft>
              <Image
                src={imageLeft.url}
                alt={imageLeft.alt}
                fill
                sizes='(max-width: 768px) 50vw, (max-width: 1200px) 50vw, 50vw'
              />
            </S.ImageLeft>
          </Col>
          <Col
            xs={12}
            lg={5}
            $offset={imageLeftAlignment == 'right' ? null : { lg: 1 }}
            align={'center'}
          >
            <S.ImageRight>
              <Image
                src={imageRight.url}
                alt={imageRight.alt}
                fill
                sizes='(max-width: 768px) 50vw, (max-width: 1200px) 50vw, 50vw'
              />
            </S.ImageRight>
          </Col>
        </Row>
      </Container>
    </S.Wrapper>
  ) : null
}

export default TwoImages
