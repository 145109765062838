import React from 'react'

import { Heading } from '@/atoms/Typography'

import * as S from './styles'

const PostContentTitle = ({ id, title }) => {
  return (
    <S.PostContentTitle id={id}>
      <Heading
        as={title.as}
        typo={title.typo}
        bold
      >
        {title.content}
      </Heading>
    </S.PostContentTitle>
  )
}

export default PostContentTitle
