import React from 'react'
import dynamic from 'next/dynamic'

import { Container } from '@/atoms/Grid'

const Video = dynamic(() => import('@/molecules/Video'), { ssr: false })

import * as S from './styles'

const VideoSection = ({ provider, url }) => {
  return (
    <S.VideoSection>
      <Container>
        <Video
          provider={provider}
          url={url}
        />
      </Container>
    </S.VideoSection>
  )
}

export default VideoSection
