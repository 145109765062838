import React from 'react'
import FieldBase from '../FieldBase'

const Textarea = ({
  id,
  name,
  label,
  placeholder,
  autocomplete,
  disabled,
  hint,
  meta,
  form,
  ...props
}) => {
  return (
    <FieldBase
      id={id || name}
      label={label}
      disabled={disabled}
      meta={meta}
      hint={hint}
    >
      <textarea
        name={name}
        id={id || name}
        placeholder={placeholder || label}
        aria-label={label ? null : placeholder}
        disabled={disabled}
        rows='5'
        {...props}
      />
    </FieldBase>
  )
}

export default Textarea
