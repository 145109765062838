import React from 'react'

import * as S from './styles'
import { Container } from '@/atoms/Grid'

const ContainerBoxed = ({ children, paddingX = 100, paddingY = 60 }) => {
  return (
    <S.Wrapper>
      <Container>
        <S.Content
          paddingX={paddingX}
          paddingY={paddingY}
        >
          {children}
        </S.Content>
      </Container>
    </S.Wrapper>
  )
}

export default ContainerBoxed
