/**
 * Accessibility specifications
 *
 * @url Accessibility https://www.w3.org/WAI/ARIA/apg/patterns/button/
 * @url Docs https://developer.mozilla.org/en-US/docs/Web/HTML/Element/button
 *
 * TODO:
 * - 'aria-pressed' props management
 * - probably 'aria-hidden="true"' and 'focusable="false"' on <Icon> components
 */

import React, { forwardRef, useContext } from 'react'
import PropTypes from 'prop-types'

import SectionThemeContext from '@contexts/SectionTheme'

import { getAriaProps } from '@utils/linkUtils'

import { Icon } from '@/atoms'
import { StyledButton } from './styles'
import { ButtonLabel } from '../Typography'
import LinkWrapper from './LinkWrapper'

const Button = ({
  children,
  type,
  href,
  label,
  typo,
  primary,
  secondary,
  tertiary,
  quaternary,
  fifth,
  inheritedColorScheme,
  handleClick = null,
  iconStart,
  iconEnd,
  useIconColor,
  uppercase,
  iconEndWeight = null,
  handleOnKeyDown,
  innerRef,
  changeVariantOnClick,
  scrollTo,
  ...props
}) => {
  const colorScheme = useContext(SectionThemeContext) || inheritedColorScheme

  // Set Variant
  const variantsObj = {
    primary,
    secondary,
    tertiary,
    quaternary,
    fifth,
  }
  const variant = Object.keys(variantsObj).find((key) => variantsObj[key])

  // Button Variant
  const btnProps = Object.assign(
    {},
    label ? { $label: label } : null,
    iconStart ? { iconStart } : null,
    iconEnd ? { iconEnd } : null,
    primary ? { primary } : null,
    secondary ? { secondary } : null,
    tertiary ? { tertiary } : null,
    quaternary ? { quaternary } : null,
    fifth ? { fifth } : null
  )

  // Button label props
  const buttonLabelProps = Object.assign(
    {},
    typo ? { typo } : null,
    uppercase ? { uppercase } : null
  )

  // Get Aria props
  const ariaProps = getAriaProps({
    href,
    target: props.target,
    type,
    label,
    ariaLabel: props.ariaLabel,
    disabled: props.disabled,
    tabIndex: props.tabIndex,
    iconStart,
    iconEnd,
  })

  const handleScrollTo = () => {
    const offset = 104
    const element = document.getElementById(scrollTo)

    if (!!element) {
      const y = element.getBoundingClientRect().top + window.scrollY - offset

      // Scroll to section
      window.scrollTo({ top: y, behavior: 'smooth' })
      // Set focus on first focusable element of current section
      const focusable = element.querySelectorAll(
        'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
      )
      if (focusable && focusable.length > 0) {
        focusable[0].focus({ preventScroll: true })
      }
    }
  }

  return (
    <LinkWrapper
      href={href}
      target={props.target}
    >
      <StyledButton
        {...props}
        {...btnProps}
        {...ariaProps}
        as={href ? 'a' : 'button'}
        href={href}
        variant={variant}
        colorScheme={colorScheme}
        ref={innerRef}
        onClick={scrollTo ? handleScrollTo : handleClick}
      >
        {iconStart ? (
          <Icon
            name={iconStart}
            aria-hidden='true'
            useIconColor={useIconColor}
          />
        ) : null}
        {label || children ? (
          <ButtonLabel
            {...buttonLabelProps}
            {...btnProps}
          >
            {label ? label : null}
            {children ? children : null}
          </ButtonLabel>
        ) : null}
        {iconEnd || fifth ? (
          <Icon
            name={fifth && !iconEnd ? 'chevron-right' : iconEnd}
            aria-hidden='true'
            useIconColor={useIconColor}
            {...iconEndWeight}
          />
        ) : null}
      </StyledButton>
    </LinkWrapper>
  )
}

Button.propTypes = {
  children: PropTypes.node,
  href: PropTypes.string,
  label: PropTypes.string,
  iconStart: PropTypes.string,
  iconEnd: PropTypes.string,
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
  tertiary: PropTypes.bool,
  disabled: PropTypes.bool,
  handleClick: PropTypes.func,
  handleOnKeyDown: PropTypes.func,
}

export const ButtonForwardingRef = forwardRef((props, ref) => (
  <Button
    {...props}
    innerRef={ref}
  />
))

export default Button
