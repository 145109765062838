import React, { Fragment } from 'react'
import Link from 'next/link'

const LinkWrapper = ({ target, href, children }) => {
  let linkProps = {}
  let isLink = false

  if (href) {
    if (['_blank', '_self', 'self'].includes(target)) {
      isLink = true
    }

    if (target && target == '_blank') {
      linkProps = Object.assign(
        {},
        target == '_blank' ? { prefetch: false } : null
      )
    }
  }

  return isLink ? (
    <Link
      href={href}
      target={target}
      passHref
      legacyBehavior
      {...linkProps}
    >
      {children}
    </Link>
  ) : (
    <Fragment>{children}</Fragment>
  )
}

export default LinkWrapper
