import React, { useState } from 'react'

import { Button } from '@/atoms'
import { Container } from '@/atoms/Grid'
import { Paragraph } from '@/atoms/Typography'

import * as S from './styles'

const FormMessage = ({ status, messages }) => {
  const [isShow, setIsShow] = useState(true)

  const items =
    messages?.items && Array.isArray(messages.items)
      ? `<ul>${messages.items
          .map((item) => `<li>${item.message}</li>`)
          .join('')}</ul>`
      : null

  return (
    <S.FormMessage
      status={status}
      show={isShow}
    >
      <Container>
        <S.FormMessageWrapper>
          <div>
            <Paragraph
              typo='paragraphLG'
              bold
              content={messages.label}
            />
            {items ? (
              <Paragraph
                typo='paragraph2'
                content={items}
              />
            ) : null}
          </div>
          <Button
            type='button'
            ariaLabel='Chiudi il messaggio'
            iconEnd='close'
            tertiary
            inheritedColorScheme='white'
            handleClick={() => setIsShow(!isShow)}
          />
        </S.FormMessageWrapper>
      </Container>
    </S.FormMessage>
  )
}

export default FormMessage
