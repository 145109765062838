import React from 'react'

import { Icon } from '@/atoms'
import { Paragraph } from '@/atoms/Typography'
import { SpacerBase } from '@/atoms/Grid'

import { StyledTabCard } from './styles'

const Tab = ({ icon, title, paragraph }) => {
  return (
    <StyledTabCard>
      <Icon name={icon ? icon : 'plus'} />
      <Paragraph
        content={title}
        bold
      />
      <SpacerBase xs={1} />
      <Paragraph content={paragraph} />
    </StyledTabCard>
  )
}

export default Tab
