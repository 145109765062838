const CardsGrid = ({ theme, items }) => {
  const normalizedData = {
    theme,
    items,
  }

  return normalizedData
}

export default CardsGrid
