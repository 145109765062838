import React from 'react'

import { Heading, Paragraph } from '@/atoms/Typography'
import { Col, Row } from '@/atoms/Grid'
import ContainerBoxed from '@/molecules/ContainerBoxed'

import { Icon } from '@/atoms'

import * as S from './styles'

const FundPerformance = ({ items = [] }) => {
  return items.length > 0 ? (
    <S.Performance>
      <ContainerBoxed>
        <Row>
          <Col
            xs={12}
            md={3}
            align={{ xs: 'flex-start' }}
          >
            <S.PerformanceTitle>
              <Icon
                name='trend'
                huge
              />
              <Heading
                typo='displayMD'
                content='Performance'
                bold
              />
            </S.PerformanceTitle>
          </Col>
          <Col
            xs={12}
            md={6}
            $offset={{ xs: false, md: 3 }}
            $direction={{ xs: 'column' }}
          >
            {items.length > 0
              ? items.map((item, i) => {
                  return (
                    <S.PerformanceItem key={`performance-item-${i}`}>
                      <Paragraph
                        typo='paragraph1'
                        content={item.label}
                      />
                      <Paragraph
                        typo='paragraphLG'
                        content={item.value ? `${item.value} %` : 'Nessun dato'}
                        bold
                      />
                    </S.PerformanceItem>
                  )
                })
              : null}
          </Col>
        </Row>
      </ContainerBoxed>
    </S.Performance>
  ) : null
}

export default FundPerformance
