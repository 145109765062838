import normalizeImage from '@models/Image'

const Images = ({
  theme,
  type,
  image,
  fullWidth,
  imageLeft,
  imageRight,
  imageLeftAlignment,
}) => {
  const normalizedData = {
    theme,
    type,
    image: normalizeImage(image),
    fullWidth,
    imageLeft: normalizeImage(imageLeft),
    imageRight: normalizeImage(imageRight),
    imageLeftAlignment,
  }

  return normalizedData
}

export default Images
