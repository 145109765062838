import React, { useState, useEffect, useRef, memo } from 'react'

import Base from './Base'
import Faq from './Faq'
import Links from './Links'
import ProductFinancial from './ProductFinancial'

import {
  open as openAnimation,
  close as closeAnimation,
} from '@animations/accordion'

const normalizedComponents = {
  base: Base,
  faq: Faq,
  links: Links,
  productFinancial: ProductFinancial,
}

//TODO: useMemo per evitare rerender quando il padre aggiorna lo stato

const Accordion = ({ data, disabled, parentHandler, open }) => {
  const [accordion, setAccordion] = useState({
    open,
    isAnimating: false,
  })

  const refs = {
    wrapper: useRef(null),
    header: useRef(null),
    content: useRef(null),
  }

  const variant = data.variant || 'base'
  const Component = normalizedComponents[variant]

  useEffect(() => {
    setAccordion((previousState) => ({
      ...previousState,
      open,
    }))
  }, [open])

  useEffect(() => {
    handleAnimation(accordion)
  }, [accordion.open, data])

  const handleAnimation = (accordion) => {
    if (accordion.open) {
      openAnimation(refs, () => {
        setAccordion((previousState) => ({
          ...previousState,
          isAnimating: false,
        }))
      })
    } else {
      closeAnimation(refs, () => {
        setAccordion((previousState) => ({
          ...previousState,
          isAnimating: false,
        }))
      })
    }
  }

  const handleClick = ({ open, isAnimating }) => {
    if (isAnimating || disabled) return
    setAccordion({
      open: !open,
      isAnimating: true,
    })
    if (typeof parentHandler === 'function') parentHandler()
  }

  return (
    <Component
      {...data}
      refs={refs}
      accordion={accordion}
      handleClick={handleClick}
      key={'accordionDispatcher' + data.id}
    />
  )
}

export default memo(Accordion)
