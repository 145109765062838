import styled, { css } from 'styled-components'
import { BulletPointCardWrap, StyledCard } from '@/molecules/Card/styles'
import { Col } from '@/atoms/Grid'

export const BulletPoints = styled.div`
  padding: ${({ theme }) => theme.rem(32, 0)};

  .swiper-slide {
    height: auto !important;

    ${StyledCard} {
      height: 100%;
    }
  }
`

export const BulletPointsCard = styled(Col)`
  &:nth-child(4):nth-child(3),
  &:not(:last-child) {
    ${BulletPointCardWrap} {
      ${({ theme }) => css`
        border-right: 0.5px solid ${theme.colors.primary.light5};
      `}
    }
  }

  &:nth-child(3n),
  &:last-child {
    ${BulletPointCardWrap} {
      border-right: 0;
    }
  }

  ${({ length }) =>
    length == 4 &&
    css`
      &:nth-child(3) {
        ${BulletPointCardWrap} {
          ${({ theme }) => css`
            border-right: 0.5px solid ${theme.colors.primary.light5} !important;
          `}
        }
      }
    `}
`
