import styled, { css } from 'styled-components'
import { Col } from '@/atoms/Grid'
import { RelatedProductCard } from '@/molecules/Card/styles'

export const RelatedProducts = styled.div`
  /* padding: ${({ theme }) => theme.rem(40, 0)}; */

  /* ${({ theme }) =>
    theme.mediaQuery.lg(`
    padding: ${theme.rem(80, 0)};
  `)}; */
`

export const RelatedProductsCard = styled(Col)`
  &:nth-child(2n) {
    ${RelatedProductCard} {
      border-right: 0;
    }
  }

  ${RelatedProductCard} {
    width: 100%;
  }
`
