import styled, { css } from 'styled-components'

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;

  button {
    position: absolute;
    right: 0;
    padding: ${({ theme }) => theme.rem(10)};
    border-radius: 4px;

    svg {
      color: ${({ theme }) => theme.colors.primary.default};
    }
  }
`
