import React, { useState, useEffect } from 'react'

import { motion, AnimatePresence } from 'framer-motion'

import FirstLevel from './FirstLevel.jsx'
import SecondLevel from './SecondLevel.jsx'

import { Icon } from '@/atoms'
import { Paragraph } from '@/atoms/Typography'

import {
  StyledMenuMobile,
  StyledMenuMobileContent,
  StyledMenuMobileBack,
} from './styles'

const MenuMobile = ({ menuData, preHeaderData, isOpen }) => {
  const [dataToShow, setDataToShow] = useState(false)
  const [template, setTemplate] = useState('first-level')

  useEffect(() => {
    if (!isOpen) {
      setTemplate('first-level')
    }
  }, [isOpen])

  useEffect(() => {
    if (template === 'first-level') setDataToShow({ menuData, preHeaderData })
  }, [template])

  const handleMenuNavigation = (template, subMenuId) => {
    const selectedMenu = menuData[subMenuId]
    const data =
      selectedMenu?.columns ||
      selectedMenu?.groups ||
      selectedMenu?.links ||
      false
    setTemplate(template)
    if (data) setDataToShow(data)
  }

  const renderTemplate = (template) => {
    const normalizeTemplate = {
      'first-level': FirstLevel,
      'second-level': SecondLevel,
    }

    const Component = normalizeTemplate[template]

    return (
      <Component
        data={dataToShow}
        handleMenuNavigation={handleMenuNavigation}
      />
    )
  }

  return (
    <StyledMenuMobile isOpen={isOpen}>
      {template !== 'first-level' ? (
        <StyledMenuMobileBack
          onClick={() => handleMenuNavigation('first-level')}
        >
          <Icon name={'chevron-left'} />
          <Paragraph
            typo={'paragraph1'}
            content='Indietro'
          />
        </StyledMenuMobileBack>
      ) : null}
      <StyledMenuMobileContent>
        <AnimatePresence mode={'wait'}>
          <motion.div
            initial={{ x: 10, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: -10, opacity: 0 }}
            transition={{ duration: 0.2 }}
            key={template}
          >
            {renderTemplate(template)}
          </motion.div>
        </AnimatePresence>
      </StyledMenuMobileContent>
    </StyledMenuMobile>
  )
}

export default MenuMobile
