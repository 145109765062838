import React, { useEffect, useState } from 'react'
import { Icon } from '@/atoms'
import * as S from './styles'

const FieldBase = ({ children, id, label, disabled, meta = {}, hint = [] }) => {
  const [mounted, setMounted] = useState(false)

  useEffect(() => setMounted(true), [])

  return mounted ? (
    <S.Field disabled={disabled}>
      <S.FieldContainer error={!!meta?.error}>
        {label ? <S.FieldLabel htmlFor={id}>{label}</S.FieldLabel> : null}
        {children}
      </S.FieldContainer>

      {hint.length > 0 || meta.error ? (
        <S.FieldHint disabled={disabled}>
          {meta.error ? (
            <S.FieldHintItem error={!!meta?.error}>
              {meta.error}
            </S.FieldHintItem>
          ) : (
            hint.map((el, i) => (
              <S.FieldHintItem key={`form-field-hint-${id}-${i}`}>
                {el.withIcon ? <Icon name={'plus'} /> : null}
                <span>{el.text}</span>
              </S.FieldHintItem>
            ))
          )}
        </S.FieldHint>
      ) : null}
    </S.Field>
  ) : null
}

export default FieldBase
