import React, { useContext } from 'react'
import Image from 'next/image'

import SectionThemeContext from '@contexts/SectionTheme'

import { Button } from '@/atoms'
import { Heading, Paragraph } from '@/atoms/Typography'
import { SpacerBase } from '@/atoms/Grid'

import normalizeImage from '@models/Image'

import * as S from './styles'

const RelatedProduct = ({ title, paragraph, cta, image }) => {
  const normalizedImage = normalizeImage(image)

  const colorScheme = useContext(SectionThemeContext)

  return (
    <S.RelatedProductCard colorScheme={colorScheme}>
      {image ? (
        <S.RelatedProductImage>
          <Image
            src={normalizedImage.url}
            alt={normalizedImage.alt}
            fill
            sizes='(max-width: 768px) 50vw, (max-width: 1200px) 50vw, 33vw'
          />
        </S.RelatedProductImage>
      ) : null}
      <S.RelatedProductText>
        {title ? (
          <Heading
            as='div'
            typo='displayXS'
            bold
          >
            {title}
          </Heading>
        ) : null}
        {paragraph ? (
          <>
            <SpacerBase xs={1} />
            <Paragraph typo='paragraphLG'>{paragraph}</Paragraph>
          </>
        ) : null}
        {cta ? (
          <>
            <SpacerBase xs={3} />
            <Button
              href={cta.href}
              label={cta.label}
              ariaLabel={
                title && cta?.label ? `${cta.label} ${title}` : cta.label
              }
              target={cta.target}
              tertiary
              inheritedColorScheme='white'
              iconEnd={'plus'}
            />
          </>
        ) : null}
      </S.RelatedProductText>
    </S.RelatedProductCard>
  )
}

export default RelatedProduct
