import styled, { css } from 'styled-components'
import { getSpacerSize } from '@utils/getSpacerSize'

export const RichTextWrapper = styled.div`
  max-width: 760px;
`

export const RichText = styled.div`
  ${({ space }) => space && getSpacerSize(space, 'padding')}

  ${({ theme, colorScheme }) =>
    colorScheme &&
    css`
      color: ${theme.colors.surface[colorScheme].text};
    `}

  ${({ align }) =>
    align &&
    css`
      text-align: ${align};

      ${RichTextWrapper} {
        margin: 0 ${align == 'center' ? 'auto' : 0};
      }
    `}
`

export const RichTextTitle = styled.div`
  margin-top: ${({ theme }) => theme.rem(16)};
`

export const RichTextParagraph = styled.div`
  margin-top: ${({ theme }) => theme.rem(16)};
`

export const RichTextButtons = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: ${({ theme }) => theme.rem(26)};
`

export const RichTextColumns = styled.div`
  margin-top: ${({ theme }) => theme.rem(48)};
`
