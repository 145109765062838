import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import useEventListener from '@use-it/event-listener'
import { withTheme } from 'styled-components'
import { getScreen } from './Screens'

const Visible = ({ xs, sm, md, lg, xl, xxl, theme, children }) => {
  const [currentScreen, setCurrentScreen] = useState('xs')

  const setScreen = () => {
    const newScreen = getScreen({ theme })
    if (currentScreen !== newScreen) {
      setCurrentScreen(newScreen)
    }
  }

  useEffect(() => {
    setScreen()
  }, [])

  useEventListener('orientationchange', setScreen)
  useEventListener('resize', setScreen)

  const isVisible = () => {
    switch (currentScreen) {
      case 'xs':
        return xs
      case 'sm':
        return sm
      case 'md':
        return md
      case 'lg':
        return lg
      case 'xl':
        return xl
      case 'xxl':
        return xxl
    }
  }

  if (!isVisible()) return false

  return <React.Fragment>{children}</React.Fragment>
}

Visible.displayName = 'Visible'

Visible.defaultProps = {
  xs: false,
  sm: false,
  md: false,
  lg: false,
  xl: false,
  xxl: false,
  children: null,
  theme: {},
}

Visible.propTypes = {
  xs: PropTypes.bool,
  sm: PropTypes.bool,
  md: PropTypes.bool,
  lg: PropTypes.bool,
  xl: PropTypes.bool,
  xxl: PropTypes.bool,
  theme: PropTypes.object,
  children: PropTypes.node,
}

export default withTheme(Visible)
