import styled, { css } from 'styled-components'
import { Field } from '@/atoms/Form/FormFields/FieldBase/styles'

export const Wrapper = styled.div`
  padding: ${({ theme }) => theme.rem(20, 0)};
  background-color: ${({ theme }) => theme.colors.secondary.light6};

  ${({ theme }) =>
    theme.mediaQuery.lg(`
    padding: ${theme.rem(40, 0)};
  `)};
`

export const Content = styled.div`
  padding: ${({ theme }) => theme.rem(0, 0)};
  background-color: ${({ theme }) => theme.colors.grayscale.white};
  border-radius: 30px;
  overflow: hidden;

  ${({ theme }) =>
    theme.mediaQuery.lg(`
    padding: ${theme.rem(0, 0)};
  `)};
`

export const Filters = styled.div`
  margin-bottom: ${({ theme }) => theme.rem(40)};

  ${Field} {
    ${({ theme }) =>
      theme.mediaQuery.lg(`
      margin-bottom: 0;
    `)};
  }
`

export const FiltersFundsSearch = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${({ theme }) =>
    theme.mediaQuery.lg(`
    flex-direction: row;
  `)};

  & > p {
    white-space: nowrap;

    ${({ theme }) =>
      theme.mediaQuery.lg(
        `
      margin-bottom: ${theme.rem(20)};
    `,
        true
      )};
  }
`

export const FiltersFundsSearchWrapper = styled.div`
  width: 100%;
  position: relative;

  ${({ theme }) =>
    theme.mediaQuery.lg(`
    margin-left: ${theme.rem(20)};
  `)};
`

export const FundSearch = styled.div`
  width: 100%;
  max-height: 226px;
  position: absolute;
  top: 36px;
  left: 0;
  margin-top: ${({ theme }) => theme.rem(8)};
  background-color: ${({ theme }) => theme.colors.grayscale.white};
  border: 1px solid ${({ theme }) => theme.colors.primary.light4};
  border-radius: 4px;
  overflow-y: auto;
  z-index: 1;

  > a {
    display: block;
    padding: ${({ theme }) => theme.rem(8, 16)};

    &:hover {
      background: ${({ theme }) => theme.colors.primary.light5};
    }
  }
`

export const FundSearchNoRes = styled.div`
  padding: ${({ theme }) => theme.rem(8, 16)};
`
