const normalizeLocation = ({
  abi,
  cab,
  codice,
  nome,
  indirizzo,
  cap,
  localita,
  provincia,
  numeroDiTelefono,
  latitudine,
  longitudine,
  // Added by Caffeina
  bank,
  website,
  distance,
}) => {
  const withHttp = (url) =>
    !/^https?:\/\//i.test(url) ? `https://${url}` : url

  const normalizedData = {
    IDAbi: abi,
    IDCab: cab,
    id: codice,
    name: nome,
    address: indirizzo,
    cap: cap,
    locations: localita,
    province: provincia,
    tel: numeroDiTelefono,
    lat: latitudine,
    lng: longitudine,
    // Added by Caffeina
    bank,
    website: withHttp(website),
    distance,
  }

  return normalizedData
}

export default normalizeLocation
