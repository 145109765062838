import styled, { css } from 'styled-components'

export const StyledMenuDesktop = styled.nav`
  height: 100%;
  margin: 0 ${({ theme }) => theme.rem(60)};
`

export const MenuDesktopWrapper = styled.div`
  height: 100%;
  display: flex;
`

export const SubmenuBackground = styled.div`
  height: 0;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: ${({ theme }) => theme.colors.secondary.light6};
  border-bottom: 0.5px solid ${({ theme }) => theme.colors.primary.light3};
  transition: height 0.2s cubic-bezier(0.79, 0.14, 0.16, 0.86);
`
