import React, { useCallback, useEffect, useState } from 'react'

import Image from 'next/image'

import { Button, InnerContent } from '@/atoms'
import { SpacerBase } from '@/atoms/Grid'
import Container from '@/atoms/Grid/Container.js'
import { ItemsGrouped } from '@/organisms'

import MarqueeRow from './MarqueeRow'

import usePrefersReducedMotion from '@hooks/usePrefersReducedMotion'

import * as S from './styles.js'

const ChildrenFocusBlur = ({ children, onFocus, onBlur, ...props }) => {
  const handleFocusBlur = useCallback(
    (e) => {
      const currentTarget = e.currentTarget

      // Give browser time to focus the next element
      requestAnimationFrame(() => {
        // Check if the new focused element is a child of the original container
        if (
          currentTarget.contains(document.activeElement) &&
          !['A'].includes(document.activeElement.tagName)
        ) {
          onFocus(e)
        } else {
          if (!['A'].includes(document.activeElement.tagName)) {
            onBlur(e)
          }
        }
      })
    },
    [onFocus, onBlur]
  )

  return (
    <div
      {...props}
      onFocus={handleFocusBlur}
      onBlur={handleFocusBlur}
    >
      {children}
    </div>
  )
}

const Marquee = ({ title, ctaPrimary, ctaSecondary, rows }) => {
  const [focus, setFocus] = useState(false)
  const [playable, setPlayable] = useState(false)
  const [play, setPlay] = useState(true)

  const prefersReducedMotion = usePrefersReducedMotion()

  const onFocus = () => {
    setFocus(true)
  }

  const onBlur = () => {
    setFocus(false)
  }

  const toggleMarquee = () => {
    setPlay(!play)
  }

  // Check if Marquee rows are playable
  useEffect(() => {
    let playable = false

    for (const marquee of rows) {
      if (marquee.length >= 5) {
        playable = true
      }
    }

    setPlayable(playable)
  }, [])

  return (
    <ChildrenFocusBlur
      onFocus={() => {
        onFocus()
      }}
      onBlur={() => {
        onBlur()
      }}
    >
      <S.Marquee
        tabIndex='0'
        isFocus={focus && playable}
      >
        <Container>
          {title.content ? (
            <S.MarqueeTitle>
              <InnerContent
                {...title}
                content={title.content}
                bold
              />
            </S.MarqueeTitle>
          ) : null}
          {(focus && playable) || prefersReducedMotion ? (
            <>
              <Button
                ariaLabel={play ? 'Pausa animazione' : 'Inizia animazione'}
                iconEnd={play ? 'pause' : 'play'}
                typo={'button2'}
                secondary
                handleClick={toggleMarquee}
              />
              <SpacerBase xs={4} />
            </>
          ) : null}
          {rows
            ? rows.map((marquee, i) => {
                return marquee.length > 0 ? (
                  <MarqueeRow
                    key={`marquee-row-${i}`}
                    direction={i % 2 == 0 ? 'right' : 'left'}
                    play={play && playable}
                  >
                    {marquee
                      ? marquee.map((el) => (
                          <S.MarqueeItem key={el.id}>
                            <Image
                              src={el.url}
                              alt={el.alt}
                              width={el.width}
                              height={el.height}
                            />
                          </S.MarqueeItem>
                        ))
                      : null}
                  </MarqueeRow>
                ) : null
              })
            : null}
          {ctaPrimary || ctaSecondary ? (
            <S.MarqueeCTA>
              <ItemsGrouped
                direction={{ xs: 'row' }}
                space={{ xs: 16 }}
              >
                {ctaPrimary ? (
                  <Button
                    {...ctaPrimary}
                    primary
                    typo={'button2'}
                  />
                ) : null}
                {ctaSecondary ? (
                  <Button
                    {...ctaSecondary}
                    secondary
                    typo={'button2'}
                  />
                ) : null}
              </ItemsGrouped>
            </S.MarqueeCTA>
          ) : null}
        </Container>
      </S.Marquee>
    </ChildrenFocusBlur>
  )
}

export default Marquee
