const normalizeProductsFinancialList = (items = []) => {
  let normalizedAccordion = []

  for (const item of items) {
    normalizedAccordion.push({
      variant: 'productFinancial',
      contentType: 'productsFinancial',
      id: item.id,
      title: item.name,
      // description: `Valorizzazione settimanale al `,
      items: item.funds,
    })
  }

  const normalizedData = {
    list: normalizedAccordion,
  }

  return normalizedData
}

export default normalizeProductsFinancialList
