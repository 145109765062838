import { Col } from '@/atoms/Grid'
import { StyledIcon } from '@/atoms/Icon/styles'
import styled, { css } from 'styled-components'

export const StyledAccordionList = styled.div`
  width: 100%;
`

/**
 * Utils
 */
const preventChildrenFromBeingTabbed = css`
  visibility: hidden;
  opacity: 0;
  transition: all ${({ theme }) => theme.timing.fast} ease-in 0s;

  // Prevent children from being tabbed
  ${({ open }) =>
    open &&
    css`
      visibility: visible;
      opacity: 1;
    `}
`

/**
 * BASE
 */

export const BaseAccordion = styled.div`
  background-color: ${({ theme }) => theme.colors.grayscale.white};
  border: 1px solid ${({ theme }) => theme.colors.primary.light4};
  overflow: hidden;
  margin-bottom: ${({ theme }) => theme.rem(24)};

  ${({ theme, disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      background-color: ${({ theme }) => theme.colors.primary.light4};
      box-shadow: none !important;
      color: ${({ theme }) => theme.colors.primary.light3};

      * {
        box-shadow: none !important;
        color: ${({ theme }) => theme.colors.primary.light3};
      }
    `}

  ${({ theme }) =>
    theme.mediaQuery.lg(`
    height: ${theme.rem(100)};
    min-height: ${theme.rem(152)};
  `)}

  &:focus,
  &:active:focus {
    box-shadow: 0 0 0 ${({ theme }) => theme.rem(4)}
      ${({ theme }) => theme.colors.secondary.light4};
  }
`

export const BaseAccordionHeader = styled.div`
  padding: ${({ theme }) => theme.rem(16, 60, 16, 16)};
  display: flex;
  flex-direction: column;
  transition: background-color ${({ theme }) => theme.timing.fast} ease-in 0s;
  position: relative;
  cursor: pointer;

  ${({ theme }) =>
    theme.mediaQuery.lg(`
    min-height: ${theme.rem(152)};
    padding: ${theme.rem(16, 110, 16, 60)};
    flex-direction: row;
    align-items: center;
  `)}

  &:hover {
    background-color: ${({ theme }) => theme.colors.secondary.light6};
  }
`

export const BaseAccordionHeaderContent = styled.div``

export const BaseAccordionContent = styled.div`
  ${({ theme }) => theme.typo('paragraph1')};
  padding: ${({ theme }) => theme.rem(50, 60)};
  background-color: ${({ theme }) => theme.colors.primary.light6};
  ${preventChildrenFromBeingTabbed};

  strong {
    font-weight: 700;
  }

  a {
    &:focus,
    &:active:focus {
      box-shadow: 0 0 0 ${({ theme }) => theme.rem(4)}
        ${({ theme }) => theme.colors.secondary.light4};
    }
  }
`

export const BaseAccordionIconLeft = styled.div`
  margin-right: ${({ theme }) => theme.rem(16)};

  i {
    font-size: ${({ theme }) => theme.rem(48)};
  }

  ${({ theme }) =>
    theme.mediaQuery.lg(`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: ${theme.rem(8)};
  `)}
`

export const BaseAccordionIconRight = styled.div``

export const BaseAccordionToggle = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: ${({ theme }) => theme.rem(16)};
  transition: transform ${({ theme }) => theme.timing.fast} ease-in 0s;

  ${({ theme }) =>
    theme.mediaQuery.lg(`
    right: ${theme.rem(60)};
  `)}

  i {
    font-size: ${({ theme }) => theme.rem(32)};
  }

  button {
    color: ${({ theme }) => theme.colors.primary.default};
  }

  ${({ open, theme }) =>
    open &&
    css`
      transform: translateY(-50%) scaleY(-1);
    `}
`

/**
 * FAQ
 */

export const FaqAccordion = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.primary.light4};
  height: ${({ theme }) => theme.rem(48)};
  overflow: hidden;
  margin-bottom: ${({ theme }) => theme.rem(24)};

  ${({ theme, disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      background-color: ${theme.colors.primary.light4};
      box-shadow: none !important;
      color: ${theme.colors.primary.light3};

      * {
        box-shadow: none !important;
        color: ${theme.colors.primary.light3};
      }
    `}

  &:focus,
  &:active:focus {
    box-shadow: 0 0 0 ${({ theme }) => theme.rem(4)}
      ${({ theme }) => theme.colors.secondary.light4};
  }
`

export const FaqAccordionHeader = styled.div`
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => theme.rem(8)};
  display: flex;
  align-items: center;
  transition: background-color ${({ theme }) => theme.timing.fast} ease-in 0s;
  position: relative;

  &:hover {
    background-color: ${({ theme }) => theme.colors.secondary.light6};
  }
`

export const FaqAccordionHeaderContent = styled.div``

export const FaqAccordionContent = styled.div`
  ${({ theme }) => theme.typo('paragraph1')};
  padding: ${({ theme }) => theme.rem(16, 56)};
  ${preventChildrenFromBeingTabbed};

  strong {
    font-weight: 700;
  }

  a {
    &:focus,
    &:active:focus {
      box-shadow: 0 0 0 ${({ theme }) => theme.rem(4)}
        ${({ theme }) => theme.colors.secondary.light4};
    }
  }
`

export const FaqAccordionToggle = styled.div`
  margin-left: ${({ theme }) => theme.rem(32)};
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: none;
  border: none;

  button {
    border: none;
    background: transparent;
  }

  span {
    width: ${({ theme }) => theme.rem(16)};
    height: ${({ theme }) => theme.rem(2)};
    border-radius: ${({ theme }) => theme.rem(2)};
    background-color: ${({ theme }) => theme.colors.primary.default};
    display: block;
    pointer-events: none;

    &:first-child {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) rotate(90deg);
      transition: transform ${({ theme }) => theme.timing.fast} ease-in 0s;

      ${({ open }) =>
        open &&
        css`
          transform: translate(-50%, -50%) rotate(0deg);
        `}
    }
  }
`

/**
 * LINKS
 */

export const LinksAccordion = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.primary.light4};
  height: ${({ theme }) => theme.rem(48)};
  overflow: hidden;
  margin-bottom: ${({ theme }) => theme.rem(24)};

  ${({ theme, disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      box-shadow: none !important;
      color: ${theme.colors.primary.light3};

      * {
        box-shadow: none !important;
        color: ${theme.colors.primary.light3};
      }
    `}

  &:focus,
  &:active:focus {
    box-shadow: 0 0 0 ${({ theme }) => theme.rem(4)}
      ${({ theme }) => theme.colors.secondary.light4};
  }
`

export const LinksAccordionHeader = styled.div`
  height: ${({ theme }) => theme.rem(48)};
  display: flex;
  align-items: center;
  transition: background-color ${({ theme }) => theme.timing.fast} ease-in 0s;
  position: relative;

  /* &:hover {
    background-color: ${({ theme }) => theme.colors.secondary.light6};
  } */
`

export const LinksAccordionHeaderContent = styled.div`
  color: ${({ theme }) => theme.colors.grayscale.white};
`

export const LinksAccordionContent = styled.div`
  ${({ theme }) => theme.typo('paragraph1')};
  padding: ${({ theme }) => theme.rem(0, 0, 0, 8)};
  ${preventChildrenFromBeingTabbed};

  a,
  button {
    color: ${({ theme }) => theme.colors.primary.light5};
  }
`

export const LinksAccordionIconLeft = styled.div`
  margin-right: ${({ theme }) => theme.rem(16)};

  i {
    font-size: ${({ theme }) => theme.rem(48)};
  }
`

export const LinksAccordionIconRight = styled.div``

export const LinksAccordionToggle = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: ${({ theme }) => theme.rem(4)};
  transition: transform ${({ theme }) => theme.timing.fast} ease-in 0s;

  i {
    font-size: ${({ theme }) => theme.rem(32)};
  }

  button {
    color: ${({ theme }) => theme.colors.grayscale.white};
  }

  ${({ open, theme }) =>
    open &&
    css`
      transform: translateY(-50%) scaleY(-1);
    `}
`

/**
 * Product Financial
 */

export const ProductFinancialAccordion = styled(BaseAccordion)`
  margin-bottom: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 0;

  ${({ theme }) =>
    theme.mediaQuery.lg(`
    min-height: 100px;
  `)}

  &:first-child {
    border-top: 0;
  }

  ${BaseAccordionHeader} {
    padding: ${({ theme }) => theme.rem(32, 16)};

    ${({ theme }) =>
      theme.mediaQuery.lg(`
      min-height: 100px;
      padding: ${theme.rem(32, 100)};
    `)}

    &:hover {
      background-color: ${({ theme }) => theme.colors.grayscale.white};
    }
  }

  ${BaseAccordionContent} {
    padding: ${({ theme }) => theme.rem(0)};

    ${({ theme }) =>
      theme.mediaQuery.lg(`
      padding: ${theme.rem(0, 100, 32, 100)};
    `)}

    background-color: ${({ theme }) => theme.colors.grayscale.white};
  }
`

export const ProductFinancialAccordionHeaderContent = styled.div`
  width: 100%;
  padding-right: ${({ theme }) => theme.rem(42)};
`

export const ProductFinancialAccordionHeaderContentTitle = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    color: ${({ theme }) => theme.colors.primary.light2};
    padding-left: ${({ theme }) => theme.rem(42)};

    ${({ theme }) =>
      theme.mediaQuery.lg(`
      white-space: nowrap;
    `)}

    ${({ theme }) =>
      theme.mediaQuery.lg(
        `
      padding-top: ${theme.rem(10)};
      padding-left: ${theme.rem(0)};
    `,
        true
      )}
  }

  ${({ theme }) =>
    theme.mediaQuery.lg(
      `
    flex-direction: column;
    align-items: flex-start;
  `,
      true
    )}
`

export const ProductFinancialAccordionFundsHeader = styled.div`
  padding: ${({ theme }) => theme.rem(16, 0)};
`

export const ProductFinancialAccordionFund = styled.div`
  padding: ${({ theme }) => theme.rem(16, 0)};
  border-top: 1px solid ${({ theme }) => theme.colors.primary.light4};

  ${({ theme }) =>
    theme.mediaQuery.lg(
      `
    padding: ${theme.rem(0)};
    text-align: center;
  `,
      true
    )}

  a, button {
    ${({ theme }) =>
      theme.mediaQuery.xl(`
      white-space: nowrap;
    `)}
  }

  ${Col} {
    ${({ theme }) =>
      theme.mediaQuery.lg(
        `
      padding: ${theme.rem(10)};

      &:not(:first-child) {
        border-top: 1px solid ${theme.colors.primary.light4};

        &:nth-child(even) {
          border-right: 1px solid ${theme.colors.primary.light4};
        }
      }
    `,
        true
      )}
  }
`

export const ProductFinancialTag = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${({ theme }) => theme.rem(4, 10)};
  color: ${({ theme }) => theme.colors.status.success.dark1};
  background-color: ${({ theme }) => theme.colors.status.success.light1};
  border-radius: ${({ theme }) => theme.rem(38)};

  ${StyledIcon} {
    margin-right: ${({ theme }) => theme.rem(7)};

    svg {
      fill: ${({ theme }) => theme.colors.status.success.dark1};
    }
  }

  > span {
    font-size: ${({ theme }) => theme.rem(8)};
    font-weight: 700;
  }
`

export const ProductFinancialAccordionTrend = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  ${StyledIcon} {
    margin-right: ${({ theme }) => theme.rem(10)};
  }
`
