import styled, { css } from 'styled-components'
import { ButtonLabel, Heading } from '@/atoms/Typography/styles'

export const TableWrapper = styled.div``

export const Table = styled.div`
  width: 100%;
  max-width: 100%;
  height: auto;
  display: table;
  table-layout: fixed;
  margin: 0 auto;
  border: 1px solid ${({ theme }) => theme.colors.primary.light4};
  border-spacing: 0;
  border-radius: 20px;
  overflow: hidden;

  ${({ theme }) =>
    theme.mediaQuery.lg(
      css`
        max-height: 800px;
        overflow: scroll;
        /* white-space: nowrap; */
      `,
      true
    )};

  a,
  button {
    width: 100%;

    ${({ theme }) =>
      theme.mediaQuery.lg(
        `
      padding: ${theme.rem(12, 0)};
    `,
        true
      )};

    ${ButtonLabel} {
      ${({ theme }) =>
        theme.mediaQuery.lg(
          `
        font-size: ${theme.rem(12)};
      `,
          true
        )};
    }
  }
`

export const TableHead = styled.div`
  display: table-header-group;
  vertical-align: middle;
  position: relative;
  z-index: 2;

  ${({ theme }) =>
    theme.mediaQuery.lg(
      `
    position: sticky;
    top: 0;
  `,
      true
    )};
`

export const TableHeadContent = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  padding: ${({ theme }) => theme.rem(40, 48)};

  &.sticky {
    position: fixed;
    top: 104px;
    background: inherit;
    box-shadow: 0 8px 8px -8px rgba(16, 24, 40, 0.1);
  }

  ${({ columns }) =>
    columns > 2 &&
    css`
      padding: ${({ theme }) => theme.rem(40, 8)};
    `}
`

export const TableHeadText = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const TableHeadContentTitle = styled.div`
  ${Heading} {
    ${({ theme }) =>
      theme.mediaQuery.lg(
        `
      font-size: ${theme.rem(16)};
    `,
        true
      )};
  }
`

export const TableHeadContentText = styled.div`
  margin-top: ${({ theme }) => theme.rem(8)};

  ${({ theme }) =>
    theme.mediaQuery.lg(
      `
    display: none;
  `,
      true
    )};
`

export const TableHeadContentTag = styled.div`
  width: fit-content;
  margin: 8px auto 0 auto;
  padding: ${({ theme }) => theme.rem(10)};
  background-color: ${({ theme }) => theme.colors.status.warning.light1};
  color: ${({ theme }) => theme.colors.status.warning.dark1};
  border-radius: 5px;
  text-transform: uppercase;

  ${({ theme }) =>
    theme.mediaQuery.lg(
      `
    display: none;
  `,
      true
    )};
`

export const TableHeadContentPrice = styled.div`
  margin-top: ${({ theme }) => theme.rem(16)};
  color: ${({ theme }) => theme.colors.secondary.default};

  ${({ theme }) =>
    theme.mediaQuery.lg(
      `
    margin-top: ${theme.rem(8)};
  `,
      true
    )};

  ${Heading} {
    ${({ theme }) =>
      theme.mediaQuery.lg(
        `
      font-size: ${theme.rem(16)};
    `,
        true
      )};
  }
`

export const TableHeadContentCTA = styled.div`
  margin-top: ${({ theme }) => theme.rem(16)};

  ${({ theme }) =>
    theme.mediaQuery.lg(
      `
    display: none;
    margin-top: ${theme.rem(8)};
  `,
      true
    )};
`

export const TableHeadHeader = styled.div`
  width: 20%;
  height: 100%;
  display: table-cell;
  position: relative;
  vertical-align: middle;
  background-color: ${({ theme }) => theme.colors.grayscale.white};
  border: 1px solid ${({ theme }) => theme.colors.primary.light4};
  border-top-width: 0;
  border-left-width: 0;

  ${({ theme }) =>
    theme.mediaQuery.lg(
      css`
        /* position: sticky;
    top: 0; */
        /* left: 0; */
        white-space: normal;
        z-index: 2;

        display: block;
        flex: 1;
      `,
      true
    )};

  &:not(:first-child) {
    ${TableHeadContent} {
      ${({ theme }) =>
        theme.mediaQuery.lg(
          `
        padding: ${theme.rem(10, 8)};
      `,
          true
        )};
    }
  }

  &:first-child {
    ${({ theme }) =>
      theme.mediaQuery.lg(
        css`
          /* min-width: calc(40vw - 32px);
      max-width: calc(40vw - 32px); */
          /* left: 0; */
          border-right-width: 0;
          z-index: 3;

          flex: 1 1 100%;
        `,
        true
      )};

    ${TableHeadContent} {
      justify-content: center;
      text-align: center;

      ${({ theme }) =>
        theme.mediaQuery.lg(
          `
        padding: ${theme.rem(20, 8)};
        white-space: normal;
      `,
          true
        )};
    }
  }

  &:last-child {
    border-right-width: 0;
  }
`

export const TableBody = styled.div`
  display: table-row-group;
  vertical-align: middle;
`

export const TableBodyCell = styled.div`
  display: table-cell;
  vertical-align: inherit;
  padding: ${({ theme }) => theme.rem(30)};
  text-align: center;
  border: 1px solid ${({ theme }) => theme.colors.primary.light4};
  border-top-width: 0;
  border-left-width: 0;

  ${({ theme }) =>
    theme.mediaQuery.lg(
      `
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    padding: ${theme.rem(10)};
  `,
      true
    )};

  &:first-child {
    background-color: ${({ theme }) => theme.colors.secondary.light6};
    padding: ${({ theme }) => theme.rem(8, 14)};
    text-align: left;

    ${({ theme }) =>
      theme.mediaQuery.lg(
        css`
          /* position: sticky;
      left: 0; */
          border-right-width: 0;
          white-space: normal;

          flex: 1 1 100%;
        `,
        true
      )};
  }

  &:last-child {
    border-right-width: 0;
  }

  ${({ highlighted }) =>
    highlighted &&
    css`
      background-color: ${({ theme }) =>
        theme.colors.secondary.light5} !important;
    `}
`

export const TableBodyCellTag = styled.div`
  width: fit-content;
  padding: ${({ theme }) => theme.rem(10)};
  background-color: ${({ theme }) => theme.colors.secondary.default};
  color: ${({ theme }) => theme.colors.grayscale.white};
  border-radius: 5px;
  text-transform: uppercase;
`

export const TableRow = styled.div`
  display: table-row;
  vertical-align: inherit;

  &:last-child ${TableBodyCell} {
    border-bottom-width: 0;
  }

  ${({ theme }) =>
    theme.mediaQuery.lg(
      css`
        display: flex;
        flex-wrap: wrap;
      `,
      true
    )};
`

export const TableHeadRow = styled.div`
  display: table-row;
  vertical-align: inherit;

  &:last-child ${TableBodyCell} {
    border-bottom-width: 0;
  }

  ${({ theme }) =>
    theme.mediaQuery.lg(
      css`
        display: flex;
        flex-wrap: wrap;
      `,
      true
    )};
`

export const TableFooter = styled.div`
  display: table-footer-group;
  vertical-align: middle;
  white-space: normal;
  position: relative;
  z-index: 1;

  ${({ theme }) =>
    theme.mediaQuery.lg(
      css`
        position: sticky;
        bottom: 0;
      `,
      true
    )};

  ${TableBodyCell} {
    border-top: 1px solid ${({ theme }) => theme.colors.primary.light4};
    background-color: ${({ theme }) => theme.colors.grayscale.white};

    ${({ theme }) =>
      theme.mediaQuery.lg(
        `
      padding: ${theme.rem(10, 4)};
    `,
        true
      )};

    &:first-child {
      ${({ theme }) =>
        theme.mediaQuery.lg(
          `
        display: none;
      `,
          true
        )};
    }
  }

  a,
  button {
    width: 100%;

    ${ButtonLabel} {
      ${({ theme }) =>
        theme.mediaQuery.sm(
          `
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 70px;
      `,
          true
        )};
    }
  }
`
