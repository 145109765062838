import React, { useState, useContext, useEffect } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'

import { ThemeContext } from 'styled-components'

import { Responsive } from '@contexts/Responsive'
import { ModalContext } from '@contexts/Modal'

import { Button, Icon, AccessibilityLinks } from '@/atoms'
import { Container, Row, Col, Visible } from '@/atoms/Grid'
import MenuDesktop from '@/molecules/MenuDesktop'
import MenuMobile from '@/molecules/MenuMobile'
import Modal from '@/molecules/Modal'
import MenuBurger from '@/molecules/MenuMobile/MenuBurger'
import { ItemsGrouped } from '@/organisms'

import {
  StyledHeader,
  PreHeader,
  MainHeader,
  MainHeaderWrapper,
  MainHeaderSearch,
  MainHeaderLogo,
} from './styles'

const Header = ({ data: { preHeaderLinks, menus } }) => {
  const router = useRouter()

  const { checkBreakpoint } = useContext(ThemeContext)
  const { handleModalChange } = useContext(ModalContext)
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const [isSticky, setIsSticky] = useState(false)

  const toggleMobileMenu = () => {
    const nextMobileMenuOpen = !mobileMenuOpen

    setMobileMenuOpen(nextMobileMenuOpen)

    if (nextMobileMenuOpen) {
      document.body.classList.add('scroll-lock')
    } else {
      document.body.classList.remove('scroll-lock')
    }
  }

  const handleOpenModal = async (values, formResponse) => {
    handleModalChange(true, 'global-search')
  }

  useEffect(() => {
    const handleChangeView = () => {
      if (mobileMenuOpen) {
        toggleMobileMenu()
      }
    }

    router.events.on('routeChangeComplete', handleChangeView)

    return () => {
      router.events.off('routeChangeComplete', handleChangeView)
    }
  }, [router])

  useEffect(() => {
    if (window.innerWidth >= 992) {
      let timeout
      let lastScrollTop = 0

      const handleScroll = () => {
        if (timeout) {
          clearTimeout(timeout)
        }

        timeout = setTimeout(() => {
          let scrollTop =
            window.pageYOffset || document.documentElement.scrollTop

          if (scrollTop > lastScrollTop && window.scrollY > 100) {
            setIsSticky(true)
          } else if (scrollTop < lastScrollTop) {
            setIsSticky(false)
          }

          lastScrollTop = scrollTop <= 0 ? 0 : scrollTop
        }, 10)
      }

      window.addEventListener('scroll', handleScroll)

      return () => {
        window.removeEventListener('scroll', handleScroll)
      }
    }
  }, [])

  return (
    <>
      <StyledHeader>
        <AccessibilityLinks />
        <Visible
          lg
          xl
          xxl
        >
          <PreHeader sticky={isSticky}>
            <Container>
              <Row>
                <Col justify={{ xs: 'flex-end' }}>
                  <ItemsGrouped
                    direction={{ xs: 'row' }}
                    space={{ xs: 32 }}
                  >
                    {preHeaderLinks &&
                      preHeaderLinks.map((el) => (
                        <Button
                          key={el.id}
                          {...el}
                          typo={'button2'}
                          tertiary
                          inheritedColorScheme='white'
                          uppercase
                        />
                      ))}
                  </ItemsGrouped>
                </Col>
              </Row>
            </Container>
          </PreHeader>
        </Visible>
        <MainHeader>
          <MainHeaderWrapper>
            <MainHeaderLogo>
              <Link href='/'>
                <Icon
                  name={'logo-mixed-dark'}
                  ariaLabel='Logo Arca Vita Arca Assicurazioni'
                  unlockedProportions
                  useIconColor
                />
              </Link>
            </MainHeaderLogo>
            <Responsive on={checkBreakpoint.xl}>
              <MenuDesktop menuData={menus} />
            </Responsive>
            <Visible xxl>
              <ItemsGrouped
                direction={{ xs: 'row' }}
                space={{ xs: 8 }}
              >
                <Button
                  href='https://my.arcassicura.it/myarca/login'
                  target='_blank'
                  label='Entra in MyArca'
                  primary
                  inheritedColorScheme='secondaryLight'
                  typo={'button2'}
                  iconEnd='user'
                />
                <Button
                  href='/store-locator'
                  label='Trova la filiale più vicina'
                  secondary
                  inheritedColorScheme='secondaryLight'
                  typo={'button2'}
                  iconEnd='map-2'
                />
              </ItemsGrouped>
            </Visible>
            <Visible xl>
              <ItemsGrouped
                direction={{ xs: 'row' }}
                space={{ xs: 8 }}
              >
                <Button
                  href='https://my.arcassicura.it/myarca/login'
                  target='_blank'
                  label='My Arca'
                  ariaLabel='Entra in MyArca'
                  primary
                  inheritedColorScheme='secondaryLight'
                  small
                  typo={'button2'}
                  iconEnd='user'
                />
                <Button
                  href='/store-locator'
                  label='Filiale più vicina'
                  ariaLabel='Trova la filiale più vicina'
                  secondary
                  inheritedColorScheme='secondaryLight'
                  small
                  typo={'button2'}
                  iconEnd='map-2'
                />
              </ItemsGrouped>
            </Visible>
            <Visible
              xl
              xxl
            >
              <MainHeaderSearch>
                <Button
                  ariaLabel='Apri ricerca'
                  tertiary
                  inheritedColorScheme='secondaryLight'
                  iconEnd='search'
                  iconEndWeight={{ huge: true }}
                  handleClick={handleOpenModal}
                />
              </MainHeaderSearch>
            </Visible>
            <Responsive on={checkBreakpoint.not.xl}>
              <ItemsGrouped
                direction={{ xs: 'row' }}
                space={{ xs: 8 }}
              >
                <Button
                  ariaLabel='Entra in MyArca'
                  href='https://my.arcassicura.it/myarca/login'
                  target='_blank'
                  primary
                  inheritedColorScheme='secondaryLight'
                  iconEnd='user'
                />
                <Button
                  ariaLabel='Apri ricerca'
                  iconEnd='search'
                  secondary
                  inheritedColorScheme='secondaryLight'
                  handleClick={handleOpenModal}
                />
                <MenuBurger
                  onMenuClick={toggleMobileMenu}
                  isOpen={mobileMenuOpen}
                />
              </ItemsGrouped>
            </Responsive>
            <Responsive on={checkBreakpoint.not.xl}>
              <MenuMobile
                menuData={menus}
                preHeaderData={preHeaderLinks}
                isOpen={mobileMenuOpen}
              />
            </Responsive>
          </MainHeaderWrapper>
        </MainHeader>
      </StyledHeader>
      <Modal
        id='global-search'
        variant='search'
      />
    </>
  )
}

export default Header
