import React, { useContext } from 'react'

import SectionThemeContext from '@contexts/SectionTheme'

import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Scrollbar, A11y } from 'swiper'

import useSwiperRef from '@hooks/useSwiperRef'

import { ButtonForwardingRef } from '@/atoms/Button'
import Card from '@/molecules/Card'
import { ItemsGrouped } from '@/organisms'

import * as S from './styles'

const Carousel = ({ items }) => {
  const colorScheme = useContext(SectionThemeContext)

  const [nextEl, nextElRef] = useSwiperRef()
  const [prevEl, prevElRef] = useSwiperRef()
  const [scrollbarEl, scrollbarElRef] = useSwiperRef()

  return (
    <Swiper
      modules={[Navigation, Scrollbar, A11y]}
      spaceBetween={24}
      slidesPerView={'auto'}
      navigation={{
        prevEl,
        nextEl,
      }}
      scrollbar={{ el: scrollbarEl }}
      a11y={{
        prevSlideMessage: 'Precedente card',
        nextSlideMessage: 'Prossima card',
      }}
    >
      {items.map(({ item, type }, i) => {
        return (
          <SwiperSlide
            key={i}
            style={{ width: '80%' }}
          >
            <Card
              key={i}
              data={item}
              type={type}
            />
          </SwiperSlide>
        )
      })}
      <S.Nav>
        <S.NavScrollbar ref={scrollbarElRef} />
        <ItemsGrouped
          direction={{ xs: 'row' }}
          space={{ xs: 16 }}
        >
          <ButtonForwardingRef
            ref={prevElRef}
            iconEnd={'arrow-left-big'}
            secondary
            inheritedColorScheme={colorScheme}
          />
          <ButtonForwardingRef
            ref={nextElRef}
            iconEnd={'arrow-right-big'}
            secondary
            inheritedColorScheme={colorScheme}
          />
        </ItemsGrouped>
      </S.Nav>
    </Swiper>
  )
}

export default Carousel
