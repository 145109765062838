import styled, { css } from 'styled-components'

export const Video = styled.div`
  /* --plyr-color-main: ${({ theme }) => theme.colors.secondary.default}; */

  // Only iframe
  height: 100%;
  margin: auto;
  overflow: hidden;
  position: relative;
  width: 100%;
  aspect-ratio: 16 / 9;

  iframe {
    display: block;
    border: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
`
