import styled, { css } from 'styled-components'

export const InputGroupItem = styled.div`
  display: flex;
  align-items: center;
  position: relative;

  &:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.rem(20)};
  }

  input {
    width: 0;
    height: 0;
    margin: 0;

    ${({ theme }) => theme.typo('inputPlaceholder1')};
  }

  label {
    display: flex;
    align-items: center;
    cursor: pointer;

    span {
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      margin-right: ${({ theme }) => theme.rem(8)};
      color: ${({ theme }) => theme.colors.primary.light3};
      background-color: ${({ theme }) => theme.colors.grayscale.white};
      border: 1px solid ${({ theme }) => theme.colors.primary.light3};
      transition:
        background-color 0.3s ease-in-out,
        border 0.3s ease-in-out,
        box-shadow 0.3s ease-in-out;
    }
  }

  input:not(:checked):not(:disabled) {
    &:focus {
      ~ label {
        span {
          box-shadow: 0 0 0 ${({ theme }) => theme.rem(4)}
            ${({ theme }) => theme.colors.secondary.light5};
          color: ${({ theme }) => theme.colors.primary.default};
          border: 1px solid ${({ theme }) => theme.colors.primary.default};
        }
      }
    }

    ~ label {
      &:hover {
        span {
          color: ${({ theme }) => theme.colors.primary.default};
          border: 1px solid ${({ theme }) => theme.colors.primary.default};
        }
      }
    }
  }

  input:checked ~ label span {
    border: 1px solid ${({ theme }) => theme.colors.secondary.default};
    color: ${({ theme }) => theme.colors.grayscale.white};
  }

  /**
   * Radio 
   */
  [type='radio'] ~ label {
    span {
      border-radius: 50%;

      &:after {
        content: '';
        position: absolute;
        width: 10px;
        height: 10px;
        top: 4px;
        left: 4px;
        border-radius: 50%;
        background-color: ${({ theme }) => theme.colors.secondary.default};
        transition: transform 0.3s ease-in-out;
        transform: scale(0);
      }
    }
  }

  [type='radio']:checked ~ label {
    span {
      &:after {
        transform: scale(1);
      }
    }
  }

  /**
   * Checkbox 
   */
  [type='checkbox'] ~ label {
    span {
      border-radius: 4px;

      i {
        opacity: 0;
      }
    }
  }

  [type='checkbox']:checked ~ label {
    span {
      background-color: ${({ theme }) => theme.colors.secondary.default};

      i {
        opacity: 1;
      }
    }
  }

  /**
   * Disabled 
   */
  [type='radio']:disabled ~ label span,
  [type='checkbox']:disabled ~ label span {
    background-color: ${({ theme }) => theme.colors.primary.light5};
    border: 1px solid ${({ theme }) => theme.colors.primary.light4};
    color: ${({ theme }) => theme.colors.primary.light4};
  }

  [type='radio']:disabled ~ label span {
    &::after {
      background-color: ${({ theme }) => theme.colors.primary.light4};
    }
  }
`

export const InputGroupItemLabel = styled.div`
  a {
    text-decoration: underline;
    color: ${({ theme }) => theme.colors.secondary.default};

    &:focus {
      color: ${({ theme }) => theme.colors.secondary.light1};
    }
  }
`
