import React from 'react'

import Accordion from '@/molecules/Accordion'

import * as S from './styles'

const AccordionSection = ({ list }) => {
  return (
    <S.Wrapper>
      <Accordion list={list} />
    </S.Wrapper>
  )
}

export default AccordionSection
