import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
  padding: ${({ theme }) => theme.rem(60, 0)};
  background-color: ${({ theme }) => theme.colors.secondary.light6};

  ${({ theme }) =>
    theme.mediaQuery.lg(`
    padding: ${theme.rem(100, 0)};
  `)};
`

export const Content = styled.div`
  padding: ${({ theme }) => theme.rem(80, 16)};
  background-color: ${({ theme }) => theme.colors.grayscale.white};
  border-radius: 30px;

  ${({ theme }) =>
    theme.mediaQuery.lg(`
    padding: ${theme.rem(80, 100)};
  `)};
`

export const Section = styled.div``

export const SectionsWrapper = styled.div`
  ${Section} {
    &:not(:last-child) {
      margin-bottom: ${({ theme }) => theme.rem(32)};
    }
  }
`
