import { useEffect, useRef } from 'react'

import { ButtonForwardingRef } from '@/atoms/Button'
import { SpacerBase } from '@/atoms/Grid'
import { Paragraph } from '@/atoms/Typography'
import { ItemsGrouped } from '@/organisms'

import * as S from '../styles'

const Section = ({ handleClick, ...section }) => {
  const buttonsRef = useRef([])

  const updateState = (ev) => {
    const btn = ev.target.closest('button')
    buttonsRef.current.map((el) => el && el.removeAttribute('aria-current'))
    btn.setAttribute('aria-current', 'true')
  }

  useEffect(() => {
    buttonsRef.current.map((el) => el && el.removeAttribute('aria-current'))
  }, [])

  return (
    <S.Section>
      <Paragraph
        typo='paragraph1'
        bold
      >
        {section.label.text}
      </Paragraph>
      <SpacerBase xs={2} />
      {section.options.length > 0 ? (
        <ItemsGrouped
          direction={{ xs: 'row' }}
          space={{ xs: 16 }}
          isWrap
        >
          {section.options.map((option) => {
            return (
              <ButtonForwardingRef
                key={`dynamic-form-section-${option.id}`}
                {...option.cta}
                handleClick={(ev) => {
                  handleClick(option)
                  updateState(ev)
                }}
                secondary
                inheritedColorScheme='white'
                ref={(el) => buttonsRef.current.push(el)}
                data-content-piece={
                  typeof section?.label?.text === 'undefined'
                    ? `form-contacts-info`
                    : `form-contacts-${section.label.text}`.toLowerCase()
                }
              />
            )
          })}
        </ItemsGrouped>
      ) : null}
    </S.Section>
  )
}

export default Section
