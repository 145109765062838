import React from 'react'
import dynamic from 'next/dynamic'

import { Col, Container, Row, Visible } from '@/atoms/Grid'
import Card from '@/molecules/Card'

import * as S from './styles'

const Carousel = dynamic(() => import('@/molecules/Carousel'))

const CardsGrid = ({ items }) => {
  const carouselItems =
    items.length > 0
      ? items.map((item, i) => {
          return {
            type: 'baseCard',
            item,
          }
        })
      : null

  return (
    <S.CardsGrid>
      <Container>
        {items.length > 0 ? (
          <>
            <Visible
              lg
              xl
              xxl
            >
              <Row>
                {items.map((item, i) => {
                  return (
                    <Col
                      key={item.id}
                      xs={12}
                      lg={6}
                    >
                      <Card
                        data={item}
                        type={'baseCard'}
                      />
                    </Col>
                  )
                })}
              </Row>
            </Visible>
            <Visible
              xs
              sm
              md
            >
              {carouselItems ? <Carousel items={carouselItems} /> : null}
            </Visible>
          </>
        ) : null}
      </Container>
    </S.CardsGrid>
  )
}

export default CardsGrid
