import { StyledButton } from '@/atoms/Button/styles'
import styled, { css } from 'styled-components'

export const StyledMenuDesktopItem = styled.div`
  & > ${StyledButton} {
    height: 100%;
    border: 0;
    border-radius: 0;
    background: none;
    color: ${({ theme }) => theme.colors.primary.default};

    &:focus,
    &:active:focus {
      box-shadow: 0 0 0 0;
    }

    &::after {
      content: '';
      width: 100%;
      height: 4px;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: ${({ theme }) => theme.colors.secondary.default};
      transform: scaleX(0);
      transform-origin: right center;
      transition: transform 0.3s cubic-bezier(0.79, 0.14, 0.16, 0.86);
    }

    &:hover,
    &:focus,
    &[aria-expanded='true'] {
      &::after {
        transform: scaleX(1);
        transform-origin: left center;
      }
    }
  }

  &:not(:last-child) {
    & > ${StyledButton} {
      padding-right: ${({ theme }) => theme.rem(12)};

      &::after {
        width: calc(100% - 12px);
      }
    }
  }
`
