import React from 'react'

import { Button, Icon } from '@/atoms'
import { Heading, Paragraph } from '@/atoms/Typography'
import { SpacerBase } from '@/atoms/Grid'

import * as S from './styles'

const Hero = ({ title, paragraph, href, target, icon }) => {
  return (
    <S.HeroCard>
      {icon ? (
        <>
          <S.HeroCardIcon>
            <Icon
              name={icon}
              unlockedProportions
            />
          </S.HeroCardIcon>
        </>
      ) : null}
      <S.HeroCardText>
        <Heading
          as='h6'
          typo='displayXS'
          bold
        >
          {title}
        </Heading>
        {paragraph ? (
          <>
            <SpacerBase xs={1} />
            <Paragraph typo='paragraph2'>{paragraph}</Paragraph>
          </>
        ) : null}
      </S.HeroCardText>
      <Button
        href={href}
        target={target}
        ariaLabel={`Vai a ${title}`}
        primary
        iconEnd={'arrow-right-big'}
        inheritedColorScheme='white'
      />
    </S.HeroCard>
  )
}

export default Hero
