import React from 'react'
import { StyledMenuBurger, StyledMenuBurgerLine } from './styles'
import { Button } from '@/atoms'

const MenuBurger = ({ isOpen, onMenuClick }) => {
  return (
    <StyledMenuBurger>
      <Button
        handleClick={onMenuClick}
        ariaLabel={isOpen ? 'Chiudi il menu' : 'Apri il menu'}
        primary
        inheritedColorScheme='white'
      >
        <StyledMenuBurgerLine isOpen={isOpen} />
        <StyledMenuBurgerLine isOpen={isOpen} />
      </Button>
    </StyledMenuBurger>
  )
}

export default MenuBurger
