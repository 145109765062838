import normalizeCta from '@models/CTA'

const RelatedPosts = ({ tagline, title, items, ctaPrimary = null }) => {
  const normalizedData = {
    theme: 'white',
    tagline,
    title,
    items,
    ctaPrimary: normalizeCta(ctaPrimary),
  }

  return normalizedData
}

export default RelatedPosts
