import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import gridConfig, { GRID_DIMENSIONS } from '@theme/gridConfig'

const Container = styled.div`
  max-width: 100%;
  margin-right: auto;
  margin-left: auto;
  box-sizing: border-box;

  /* ${(p) =>
    !p.noGutter &&
    css`
      ${GRID_DIMENSIONS.map(
        (d) =>
          gridConfig(p).container[d] &&
          gridConfig(p).media[d]`
      padding-left: ${({ theme }) => theme.rem(gridConfig(p).paddingWidth[d])};
      padding-right: ${({ theme }) => theme.rem(gridConfig(p).paddingWidth[d])};
    `
      )}
    `} */

  ${(p) =>
    !p.noGutter &&
    css`
      padding-left: ${({ theme }) =>
        theme.rem(gridConfig(p).paddingWidth['xs'])};
      padding-right: ${({ theme }) =>
        theme.rem(gridConfig(p).paddingWidth['xs'])};
    `}

  ${(p) =>
    !p.fluid &&
    css`
      ${({ theme }) =>
        GRID_DIMENSIONS.map(
          (d) =>
            gridConfig(p).container[d] &&
            gridConfig(p).media[d]`
      ${
        typeof gridConfig(p).container[d] === 'number'
          ? `max-width: ${theme.rem(gridConfig(p).container[d])};`
          : `width: 100%;`
      }
    `
        )}
    `}

  ${({ debug }) =>
    debug &&
    css`
      background-color: #cce2fc;
      outline: #fff solid 1px !important;
    `}
`

Container.displayName = 'Container'

Container.propTypes = {
  fluid: PropTypes.bool,
  children: PropTypes.node,
  debug: PropTypes.bool,
}

export default Container
