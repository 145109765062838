import React from 'react'
import Image from 'next/image'

import * as S from './styles'
import { Paragraph } from '@/atoms/Typography'

const PostContentImage = ({ description, image }) => {
  return (
    <S.Wrapper>
      {image ? (
        <S.PostContentImage>
          <Image
            src={image.url}
            alt={image.alt}
            fill
          />
        </S.PostContentImage>
      ) : null}
      {description ? (
        <S.PostContentImageText>
          <Paragraph typo='paragraph2'>{description}</Paragraph>
        </S.PostContentImageText>
      ) : null}
    </S.Wrapper>
  )
}

export default PostContentImage
