import React from 'react'

import { Paragraph } from '@/atoms/Typography'

import * as S from './styles'

const PostContentText = ({ text }) => {
  return (
    <S.PostContentText>
      <Paragraph
        as={text.as}
        typo={text.typo}
      >
        {text.content}
      </Paragraph>
    </S.PostContentText>
  )
}

export default PostContentText
