const FundPerformance = ({
  performance1Month,
  performance3Months,
  performance6Months,
  performance12Months,
}) => {
  const normalizedData = (performance1Month ||
    performance3Months ||
    performance6Months ||
    performance12Months) && {
    items: [
      {
        label: '1 mese',
        value: performance1Month,
      },
      {
        label: '3 mesi',
        value: performance3Months,
      },
      {
        label: '6 mesi',
        value: performance6Months,
      },
      {
        label: '1 anno',
        value: performance12Months,
      },
    ],
  }

  return normalizedData
}

export default FundPerformance
