import React, { useContext } from 'react'

import SectionThemeContext from '@contexts/SectionTheme'

import { Icon } from '@/atoms'
import { Heading, Paragraph } from '@/atoms/Typography'
import { SpacerBase } from '@/atoms/Grid'

import * as S from './styles'

const BulletPoint = ({ title, paragraph, icon, reverse }) => {
  const colorScheme = useContext(SectionThemeContext) || 'white'

  return (
    <S.BulletPointCard
      reverse={reverse}
      colorScheme={colorScheme}
    >
      <S.BulletPointCardWrap>
        <S.BulletPointHeader reverse={reverse}>
          {icon ? (
            <Icon
              name={icon}
              unlockedProportions
            />
          ) : null}
          <Heading
            as='div'
            typo='displayXS'
            content={title}
            bold={reverse ? true : false}
          />
        </S.BulletPointHeader>
        {paragraph ? (
          <>
            <SpacerBase xs={1} />
            <Paragraph typo='paragraph2'>{paragraph}</Paragraph>
          </>
        ) : null}
      </S.BulletPointCardWrap>
    </S.BulletPointCard>
  )
}

export default BulletPoint
