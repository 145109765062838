import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
  margin: ${({ theme }) => theme.rem(48, 0)};
`

export const PostContentImageText = styled.div`
  margin-top: ${({ theme }) => theme.rem(16)};
`

export const PostContentImage = styled.div`
  position: relative;
  border-radius: ${({ theme }) => theme.rem(20)};
  overflow: hidden;
  aspect-ratio: 4 / 3;

  ${({ theme }) =>
    theme.mediaQuery.lg(`
    aspect-ratio: 16 / 12;
  `)};

  img {
    object-fit: cover;
  }
`
