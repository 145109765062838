import React from 'react'

import { SpacerBase } from '@/atoms/Grid'
import Card from '@/molecules/Card'

import { StyledTabsContentInner, StyledTabsDocWrapper } from './styles'

const ContentCard = ({ documents }) => {
  return documents.length > 0 ? (
    <StyledTabsContentInner>
      <SpacerBase
        xs={4}
        md={0}
      />
      {documents.map((item, i) => {
        return (
          <StyledTabsDocWrapper key={`tabs-content-card-doc-${i}`}>
            <Card
              data={item}
              type='document'
            />
          </StyledTabsDocWrapper>
        )
      })}
    </StyledTabsContentInner>
  ) : null
}

export default ContentCard
