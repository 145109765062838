/**
 * Accessibility specifications
 *
 * @url Accessibility https://www.w3.org/WAI/ARIA/apg/patterns/accordion/examples/accordion/
 */

import React, { memo, useState } from 'react'
import { StyledAccordionList } from './styles'
import Accordion from './Accordion'

const AccordionList = ({ list, multiple = false }) => {
  const [expanded, setExpanded] = useState([])

  const handleExpanded = (index, expanded) => {
    if (expanded.indexOf(index) !== -1) {
      const indexOfExpanded = expanded.indexOf(index)
      const newExpanded = expanded
      newExpanded.splice(indexOfExpanded, 1)
      setExpanded(newExpanded)
    } else {
      const newExpanded = multiple ? [...expanded, index] : [index]
      setExpanded(newExpanded)
    }
  }

  return list ? (
    <StyledAccordionList>
      {list.map((item, index) => {
        return (
          <Accordion
            data={item}
            // passedKey={index}
            key={'accordionComponent' + item.id}
            open={expanded.indexOf(index) !== -1}
            parentHandler={() => handleExpanded(index, expanded)}
          />
        )
      })}
    </StyledAccordionList>
  ) : null
}

export default memo(AccordionList)
