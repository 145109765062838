import { formattedDate } from '@utils/formattedDate'
import normalizeImage from '@models/Image'

const normalizePostCard = ({
  image,
  category,
  title,
  excerpt,
  date,
  link,
  slug,
}) => {
  const normalizedData = {
    image: normalizeImage(image),
    category,
    title,
    excerpt,
    date: formattedDate(date),
    link: `blog/${link ? link : slug}`,
  }

  return normalizedData
}

export default normalizePostCard
