import React from 'react'
import Image from 'next/image'
import Link from 'next/link'

import normalizePostCard from '@models/PostCard'

import { Paragraph } from '@/atoms/Typography'
import { SpacerBase } from '@/atoms/Grid'

import * as S from './styles'

const PostMin = (data) => {
  const { image, category, title, excerpt, link } = normalizePostCard(data)

  return (
    <S.PostMinCard>
      <Link href={link}>
        {title ? <span className='sr-only'>{title}</span> : null}
        {image ? (
          <S.PostMinCardImage>
            <Image
              src={image.url}
              alt={image.alt}
              fill
              sizes='(max-width: 768px) 50vw, (max-width: 1200px) 50vw, 33vw'
            />
          </S.PostMinCardImage>
        ) : null}
        {category ? (
          <>
            <SpacerBase xs={2} />
            <S.PostMinCardTag>
              <Paragraph
                typo='link1'
                content={category.title}
                bold
              />
            </S.PostMinCardTag>
          </>
        ) : null}
        {title ? (
          <>
            <SpacerBase xs={1} />
            <S.PostCardTitle>
              <Paragraph
                as='div'
                typo='paragraphLG'
                content={title}
                bold
              />
            </S.PostCardTitle>
          </>
        ) : null}
        {excerpt ? (
          <>
            <SpacerBase xs={1} />
            <S.PostCardExcerpt>
              <Paragraph
                typo='paragraph2'
                content={excerpt}
              />
            </S.PostCardExcerpt>
          </>
        ) : null}
      </Link>
    </S.PostMinCard>
  )
}

export default PostMin
