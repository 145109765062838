import { useState } from 'react'

import data from '@fixtures/my-quote-bar.json'

import { Button, Icon } from '@/atoms'
import { Input } from '@/atoms/Form/FormFields'
import { Col, Container, Row, SpacerBase, Visible } from '@/atoms/Grid'
import { Heading } from '@/atoms/Typography'

import * as S from './styles'

const MyQuoteBar = () => {
  const [open, setOpen] = useState(true)

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <S.MyQuoteBar $open={open}>
      <Container>
        <Row>
          <Col
            xs={12}
            lg={5}
            align='center'
          >
            <Icon
              name={data.icon}
              large
            />
            <S.MyQuoteBarText>
              {/* <Paragraph typo="label2" content={data.texts.tagline} bold uppercase /> */}
              <Heading
                typo='displayXS'
                content={data.texts.paragraph}
                bold
              />
              <Heading
                typo='label2'
                content={data.texts.disclaimer}
              />
              <Visible
                xs
                sm
                md
              >
                <S.MyQuoteBarClose>
                  <Button
                    ariaLabel='Chiudi ricerca MyQuote'
                    secondary
                    iconEnd='close'
                    inheritedColorScheme='dark'
                    handleClick={handleClose}
                  />
                </S.MyQuoteBarClose>
              </Visible>
            </S.MyQuoteBarText>
          </Col>
          <Col
            xs={12}
            lg={7}
            align='center'
            $direction={{ xs: 'column', lg: 'row' }}
          >
            <Visible
              xs
              sm
              md
            >
              <SpacerBase xs={2} />
              <Button
                href={data.form.action}
                label='Calcola'
                secondary
                typo='label2'
                inheritedColorScheme='dark'
                target='_blank'
                style={{ width: '100%' }}
              />
            </Visible>
            <Visible
              lg
              xl
              xxl
            >
              <S.MyQuoteBarFormWrapper>
                <form
                  method={data.form.method}
                  action={data.form.action}
                  target='_blank'
                >
                  {data.form.fields
                    ? data.form.fields.map((field, i) => (
                        <Input
                          key={`my-quote-bar-field-${i}`}
                          {...field}
                        />
                      ))
                    : null}
                  <Button
                    {...data.form.submitButton}
                    secondary
                    inheritedColorScheme='dark'
                    typo='label2'
                  />
                </form>
                <Button
                  label='Informativa privacy'
                  href='https://static.sitewww.arcassicura.it/Informativa_AA_AMQ_23_06_2023_9e7f6d1f2a.pdf'
                  target='_blank'
                  quaternary
                  inheritedColorScheme='dark'
                />
              </S.MyQuoteBarFormWrapper>
              <S.MyQuoteBarClose>
                <Button
                  ariaLabel='Chiudi ricerca MyQuote'
                  secondary
                  inheritedColorScheme='dark'
                  iconEnd='close'
                  handleClick={handleClose}
                />
              </S.MyQuoteBarClose>
            </Visible>
          </Col>
        </Row>
      </Container>
    </S.MyQuoteBar>
  )
}

export default MyQuoteBar
