import React from 'react'

import { Button, InnerContent } from '@/atoms'
import { Col, Container, Row, SpacerBase } from '@/atoms/Grid'

import { Spacer, ItemsGrouped } from '@/organisms'

import * as S from './styles'

const Hero2 = ({
  paragraphLeft,
  paragraphRight,
  ctaPrimary,
  ctaTertiary,
  title,
}) => {
  return (
    <S.Hero>
      <Container>
        <InnerContent
          {...title}
          bold
        >
          {title.content}
        </InnerContent>
        <Spacer
          size={'md'}
          separator
        />
        <Row>
          <Col
            xs={12}
            lg={6}
            $direction={'column'}
          >
            <SpacerBase lg={3} />
            {paragraphLeft.content ? (
              <InnerContent
                {...paragraphLeft}
                bold
              >
                {paragraphLeft.content}
              </InnerContent>
            ) : null}
          </Col>
          <Col
            xs={12}
            lg={5}
            $offset={{ lg: 1 }}
            $direction={'column'}
          >
            <SpacerBase xs={3} />
            {paragraphRight.content ? (
              <InnerContent {...paragraphRight}>
                {paragraphRight.content}
              </InnerContent>
            ) : null}
            {ctaPrimary || ctaTertiary ? (
              <S.HeroButtons>
                <ItemsGrouped
                  direction={{ xs: 'column', md: 'row' }}
                  space={{ xs: 16, md: 24 }}
                >
                  {ctaPrimary ? (
                    <Button
                      {...ctaPrimary}
                      primary
                    />
                  ) : null}
                  {ctaTertiary ? (
                    <Button
                      {...ctaTertiary}
                      tertiary
                    />
                  ) : null}
                </ItemsGrouped>
              </S.HeroButtons>
            ) : null}
          </Col>
        </Row>
      </Container>
    </S.Hero>
  )
}

export default Hero2
