import styled, { css } from 'styled-components'

const SpacerBase = styled.div`
  ${({ theme, xs, md, lg }) => css`
    ${typeof xs === 'number' && `height: ${theme.spacing(xs)};`}

    ${typeof md === 'number' &&
    `
      ${theme.mediaQuery.lg(`
        height: ${theme.spacing(md)};
      `)}
    `}

    ${typeof lg === 'number' &&
    `
      ${theme.mediaQuery.lg(`
        height: ${theme.spacing(lg)};
      `)}
    `}
  `}
`

export default SpacerBase
