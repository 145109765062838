import React from 'react'

import { Row } from '@/atoms/Grid'
import { Paragraph } from '@/atoms/Typography'

import * as S from './styles'

const ContentTable = ({ table }) => {
  return (
    <S.TabTable>
      <S.TabTableHead>
        <Row>
          {table.head.map((item, i) => (
            <S.TabTableHeadCell
              key={`tab-table-head-${i}`}
              xs={6}
              justify='center'
            >
              <Paragraph
                typo='label2'
                content={item.label}
                bold
              />
            </S.TabTableHeadCell>
          ))}
        </Row>
      </S.TabTableHead>
      <S.TabTableBody>
        {table.body.map((row, i) => (
          <Row key={`tab-table-body-row-${i}`}>
            {row.map((item, i) => (
              <S.TabTableBodyCell
                key={`tab-table-body-cell-${i}`}
                xs={6}
              >
                <Paragraph
                  typo={i % 2 == 0 ? 'paragraph2' : 'paragraphLG'}
                  content={item.value}
                  bold
                />
              </S.TabTableBodyCell>
            ))}
          </Row>
        ))}
      </S.TabTableBody>
    </S.TabTable>
  )
}

export default ContentTable
