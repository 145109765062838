import React, { useContext } from 'react'

import SectionThemeContext from '@contexts/SectionTheme'

import { Button, InnerContent } from '@/atoms'
import { Container, Row, Col } from '@/atoms/Grid'
import { ItemsGrouped } from '@/organisms'

import * as S from './styles'

const RichText = ({
  tagline,
  title,
  paragraph,
  ctaPrimary,
  ctaSecondary,
  columnLeft,
  columnRight,
  align,
  space = 'lg',
  embedded = 0,
}) => {
  const colorScheme = useContext(SectionThemeContext)

  return (
    <S.RichText
      align={align}
      colorScheme={colorScheme}
      space={space}
    >
      <Container noGutter={embedded}>
        <S.RichTextWrapper>
          {tagline?.content ? (
            <InnerContent
              as={tagline.as}
              typo={tagline.typo}
              content={tagline.content}
              uppercase
              bold
              isTagline={true}
            />
          ) : null}
          {title?.content ? (
            <S.RichTextTitle>
              <InnerContent
                as={title.as}
                typo={title.typo}
                content={title.content}
                bold
              />
            </S.RichTextTitle>
          ) : null}
          {paragraph?.content ? (
            <S.RichTextParagraph>
              <InnerContent
                as={paragraph.as}
                typo={paragraph.typo}
                content={paragraph.content}
              />
            </S.RichTextParagraph>
          ) : null}
          {ctaPrimary || ctaSecondary ? (
            <S.RichTextButtons>
              <ItemsGrouped
                direction={{ xs: 'column', md: 'row' }}
                space={{ xs: 16, md: 16 }}
              >
                {ctaPrimary ? (
                  <Button
                    {...ctaPrimary}
                    primary
                  />
                ) : null}
                {ctaSecondary ? (
                  <Button
                    {...ctaSecondary}
                    secondary
                  />
                ) : null}
              </ItemsGrouped>
            </S.RichTextButtons>
          ) : null}
        </S.RichTextWrapper>
        {columnLeft?.content || columnRight?.content ? (
          <S.RichTextColumns>
            <Row>
              {columnLeft.content ? (
                <Col
                  xs={12}
                  md={6}
                >
                  <InnerContent
                    as={columnLeft.as}
                    typo={columnLeft.typo}
                    content={columnLeft.content}
                  />
                </Col>
              ) : null}
              {columnRight.content ? (
                <Col
                  xs={12}
                  md={6}
                  $offset={!columnLeft ? { md: 6 } : null}
                >
                  <InnerContent
                    as={columnRight.as}
                    typo={columnRight.typo}
                    content={columnRight.content}
                  />
                </Col>
              ) : null}
            </Row>
          </S.RichTextColumns>
        ) : null}
      </Container>
    </S.RichText>
  )
}

export default RichText
