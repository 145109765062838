import styled, { css } from 'styled-components'
import { Video } from '@/molecules/Video/styles'

export const VideoSection = styled.div`
  max-height: 700px;

  ${Video} {
    border-radius: 16px;
    overflow: hidden;
  }
`
