import React, { useContext } from 'react'
import SectionThemeContext from '@contexts/SectionTheme'

import * as S from './styles'

const Heading = ({ children, content, ...props }) => {
  return <S.Heading {...props}>{children ? children : content}</S.Heading>
}

const Paragraph = ({ children, content, ...props }) => {
  const colorScheme = useContext(SectionThemeContext) || 'white'

  return (
    <S.Paragraph
      {...props}
      colorScheme={colorScheme}
      dangerouslySetInnerHTML={{
        __html: children ? children : content,
      }}
    />
  )
}

const Label = ({ children, content, ...props }) => {
  return <S.Label {...props}>{children ? children : content}</S.Label>
}

const ButtonLabel = ({ children, content, ...props }) => {
  return (
    <S.ButtonLabel {...props}>{children ? children : content}</S.ButtonLabel>
  )
}

export { Heading, Paragraph, Label, ButtonLabel }
