import { getCurrentLocation } from '../api'

const getGeolocation = () => {
  return new Promise((resolve, reject) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const location = getCurrentLocation({
            latlng: `${position.coords.latitude},${position.coords.longitude}`,
          })
          resolve(location)
        },
        () => {
          reject('Error: The Geolocation service failed.')
          console.error('Error: The Geolocation service failed.')
        },
        {
          maximumAge: 10000,
          timeout: 5000,
          enableHighAccuracy: true,
        }
      )
    } else {
      // Browser doesn't support Geolocation
      reject("Error: Your browser doesn't support geolocation.")
      console.error("Error: Your browser doesn't support geolocation.")
    }
  })
}

export default getGeolocation
