import React from 'react'

import { Icon, Button } from '@/atoms'
import { Heading, Paragraph } from '@/atoms/Typography'
import { SpacerBase } from '@/atoms/Grid'

import {
  BaseAccordion,
  BaseAccordionHeader,
  BaseAccordionContent,
  BaseAccordionHeaderContent,
  BaseAccordionIconLeft,
  BaseAccordionToggle,
} from './styles'

const Base = ({
  refs,
  accordion,
  iconLeft,
  tabIndex,
  disabled,
  title,
  subtitle,
  content,
  handleClick,
  passedKey,
}) => {
  return (
    <BaseAccordion
      tabIndex={tabIndex}
      ref={refs.wrapper}
      disabled={disabled}
      key={passedKey}
      aria-expanded={accordion.open}
    >
      <BaseAccordionHeader
        ref={refs.header}
        open={accordion.open}
        onClick={() => handleClick(accordion)}
      >
        {iconLeft ? (
          <BaseAccordionIconLeft>
            <Icon name={iconLeft} />
          </BaseAccordionIconLeft>
        ) : null}
        <BaseAccordionHeaderContent>
          {title ? <Heading typo={'displayXS'}>{title}</Heading> : null}
          {subtitle ? (
            <>
              <SpacerBase xs={0.5} />
              <Paragraph
                typo={'paragraphLG'}
                bold
              >
                {subtitle}
              </Paragraph>
            </>
          ) : null}
        </BaseAccordionHeaderContent>
        <BaseAccordionToggle open={accordion.open}>
          <Button
            ariaLabel={
              accordion.open
                ? `Riduci${title ? ` ${title}` : ''}`
                : `Espandi${title ? ` ${title}` : ''}`
            }
            iconStart={'chevron-down'}
            tertiary
            inheritedColorScheme='white'
            handleClick={() => handleClick(accordion)}
          />
        </BaseAccordionToggle>
      </BaseAccordionHeader>
      <BaseAccordionContent
        ref={refs.content}
        open={accordion.open}
        role={'region'}
      >
        <Paragraph as="div">
          {content}
        </Paragraph>
      </BaseAccordionContent>
    </BaseAccordion>
  )
}

export default Base
