const Video = ({ provider, url }) => {
  const normalizedData = {
    theme: 'white',
    provider,
    url,
  }

  return normalizedData
}

export default Video
