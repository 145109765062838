import React from 'react'

import { formattedDate } from '@utils/formattedDate'

import { Paragraph } from '@/atoms/Typography'
import ContainerBoxed from '@/molecules/ContainerBoxed'
import { ItemsGrouped, Tabs } from '@/organisms'

import * as S from './styles'

const FundHistory = ({ name, lastPriceDate, tabs }) => {
  return tabs.length > 0 ? (
    <S.FundHistory id='storico-fondo'>
      <ContainerBoxed
        paddingX={30}
        paddingY={30}
      >
        <S.FundHistoryWrapper>
          <ItemsGrouped
            direction={{ xs: 'column', md: 'row' }}
            space={{ xs: 10 }}
            style={{ alignItems: 'center', justifyContent: 'space-between' }}
          >
            <Paragraph
              typo='paragraphLG'
              content={name}
              bold
              uppercase
            />
            <Paragraph
              typo='paragraph2'
              content={`Ultimo aggiornamento al ${formattedDate(
                lastPriceDate,
                'short'
              )}`}
            />
          </ItemsGrouped>
          <S.FundHistoryContent>
            <Tabs
              tabs={tabs}
              minimal
            />
          </S.FundHistoryContent>
        </S.FundHistoryWrapper>
      </ContainerBoxed>
    </S.FundHistory>
  ) : null
}

export default FundHistory
