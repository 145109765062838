import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
  padding: ${({ theme }) => theme.rem(16, 0)};
  background-color: ${({ theme }) => theme.colors.secondary.light6};

  ${({ theme }) =>
    theme.mediaQuery.lg(`
    padding: ${theme.rem(30, 0)};
  `)};
`

export const Content = styled.div`
  padding: ${({ theme, paddingY, paddingX }) =>
    theme.rem(paddingY / 2, paddingX / 2.5)};
  background-color: ${({ theme }) => theme.colors.grayscale.white};
  border-radius: 30px;

  ${({ theme, paddingY, paddingX }) =>
    theme.mediaQuery.lg(`
    padding: ${theme.rem(paddingY, paddingX)};
  `)};
`
