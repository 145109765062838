import React, { Fragment } from 'react'

import Hero2 from './Hero2'
import Hero3 from './Hero3'

const Hero = ({ type, ...data }) => {
  const herosType = {
    Hero2: Hero2,
    Hero3: Hero3,
  }

  const Component = herosType[type]

  return (
    <Fragment>
      <Component {...data} />
    </Fragment>
  )
}

export default Hero
