import { Button, Icon } from '@/atoms'
import { Row, Container, SpacerBase } from '@/atoms/Grid'
import { Paragraph } from '@/atoms/Typography'

import * as S from './styles'

const SubmenuContentColumns = ({ data }) => {
  return (
    <S.Wrapper>
      <Container fluid>
        <Row>
          {data.columns &&
            data.columns.map((el, i) => (
              <S.Item key={el.id}>
                <Button
                  href={el.href}
                  ariaLabel={
                    el?.title
                      ? `${el.title}${
                          el?.description ? `: ${el.description}` : ''
                        }`
                      : null
                  }
                  fifth
                  fifthWithBG
                  iconEnd={'arrow-right-big'}
                  iconEndWeight={{ big: true }}
                  brandColor={el.color}
                  inheritedColorScheme='white'
                >
                  {el.icon && (
                    <S.ItemIcon>
                      <Icon
                        name={el.icon}
                        large
                      />
                    </S.ItemIcon>
                  )}
                  {el.title && (
                    <>
                      <Paragraph
                        as='div'
                        typo='paragraph1'
                        content={el.title}
                        bold
                      />
                      <SpacerBase xs={0.5} />
                    </>
                  )}
                  {el.description && (
                    <Paragraph
                      typo='paragraph2'
                      content={el.description}
                    />
                  )}
                </Button>
                <S.ItemButton>
                  {el.links &&
                    el.links.map((el) => (
                      <Button
                        key={el.id}
                        {...el}
                        iconEnd={'arrow-right-big'}
                        iconEndWeight={{ big: true }}
                        fifth
                        fifthWithBG
                        inheritedColorScheme='white'
                      />
                    ))}
                </S.ItemButton>
              </S.Item>
            ))}
        </Row>
      </Container>
    </S.Wrapper>
  )
}

export default SubmenuContentColumns
