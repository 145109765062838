import React, { useRef } from 'react'

import { Button } from '@/atoms'

import * as S from './styles'

const AccessibilityLinks = () => {
  const wrapperRef = useRef(null)

  return (
    <S.Wrapper
      className='sr-only'
      ref={wrapperRef}
    >
      <Button
        scrollTo='main-page-content'
        label='Salta al contenuto principale della pagina'
        primary
        small
        typo={'button2'}
        inheritedColorScheme='white'
        handleClick={() => wrapperRef.current.classList.remove('sr-only')}
        onFocus={() => wrapperRef.current.classList.remove('sr-only')}
        onBlur={() => wrapperRef.current.classList.add('sr-only')}
      />
      <Button
        scrollTo='footer-content'
        label='Vai al footer'
        secondary
        small
        typo={'button2'}
        inheritedColorScheme='white'
        handleClick={() => wrapperRef.current.classList.remove('sr-only')}
        onFocus={() => wrapperRef.current.classList.remove('sr-only')}
        onBlur={() => wrapperRef.current.classList.add('sr-only')}
      />
    </S.Wrapper>
  )
}

export default AccessibilityLinks
