import React from 'react'

import OneImage from './OneImage'
import TwoImages from './TwoImages'

const Images = ({ type, ...data }) => {
  const componentType = {
    OneImage: OneImage,
    TwoImages: TwoImages,
  }

  const Component = componentType[type]

  return <Component {...data} />
}

export default Images
