import { Field } from '@/atoms/Form/FormFields/FieldBase/styles'
import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
  max-width: 840px;
  margin: 0 auto;
  padding: ${({ theme }) => theme.rem(32, 0)};
  text-align: center;
`

export const ImageWrapper = styled.div`
  max-width: 260px;
  position: relative;
  margin: 0 auto;
  overflow: hidden;
  aspect-ratio: 4 / 3;

  ${({ theme }) =>
    theme.mediaQuery.lg(`
    aspect-ratio: 4 / 4;
  `)};

  img {
    object-fit: cover;
  }
`

export const PrivacyText = styled.div`
  padding: ${({ theme }) => theme.rem(0, 16)};
  color: ${({ theme }) => theme.colors.primary.default};
`

export const FormWrapper = styled.div`
  max-width: 560px;
  margin: ${({ theme }) => theme.rem(16)} auto;

  ${Field} {
    margin-bottom: ${({ theme }) => theme.rem(16)};
  }
`
