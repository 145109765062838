import styled, { css } from 'styled-components'
import styledMap from 'styled-map'

const helpers = css`
  font-weight: ${styledMap`
    light: 300;
    medium: 500;
    bold: 700;
    extraBold: 800;
    default: 400;
  `};
  text-align: ${styledMap`
    center: center;
    right: right;
    default: inherit;
  `};
`

const base = css`
  ${helpers}
  ${({ uppercase }) => uppercase && 'text-transform: uppercase;'};
`

export const Heading = styled.div`
  ${base};
  ${({ theme, as }) => theme.typo(as || 'div')};
  ${({ theme, typo }) => theme.typo(typo || 'displaySM')};
`

export const Paragraph = styled.p`
  ${base};
  ${({ theme, typo }) => theme.typo(typo || 'paragraph1')};

  a {
    font-weight: 500;
    text-decoration: underline;
    transition: color 0.3s ease-in-out;

    ${({ theme, colorScheme }) =>
      colorScheme &&
      css`
        &:hover,
        &:focus {
          color: ${theme.colors.surface[colorScheme].textHover};
          box-shadow: 0 0 0 ${theme.rem(2)} ${theme.colors.secondary.light4};
        }
      `}
  }

  strong {
    font-weight: 700;
  }

  em {
    font-style: italic;
  }

  ol,
  ul {
    margin-block-start: ${({ theme }) => theme.rem(5)};
    margin-block-end: ${({ theme }) => theme.rem(5)};
    padding-inline-start: ${({ theme }) => theme.rem(16)};

    li {
      margin: ${({ theme }) => theme.rem(5, 0)};
    }
  }

  ol {
    list-style: decimal;
  }

  ul {
    list-style: disc;
  }
`

export const Label = styled.div`
  ${base};
  ${({ theme, typo }) => theme.typo(typo || 'label1')};
`

export const ButtonLabel = styled.span`
  ${base};
  font-weight: 700;
  text-transform: ${({ uppercase }) =>
    uppercase
      ? 'uppercase'
      : styledMap`
    default: uppercase;
    secondary: uppercase;
    tertiary: inherit;
    quaternary : inherit;
    fifth : inherit;
  `};
  margin: ${styledMap`
    default: 0;
    iconStart: 0 0 0 ${({ theme }) => theme.rem(12)};
    iconEnd: 0 ${({ theme }) => theme.rem(12)} 0 0;
  `};
  ${({ theme, typo }) => theme.typo(typo || 'button1')};
  ${({ quaternary, fifth }) =>
    (quaternary || fifth) &&
    css`
      font-weight: 400;
      margin-inline-end: auto;
    `}
  z-index: 1;
`
