import { Field, useField } from 'formik'
import React from 'react'

import * as Fields from '../FormFields'

const FormDispatcher = ({ type, ...props }) => {
  const components = {
    text: 'Input',
    email: 'Input',
    tel: 'Input',
    inputGroup: 'InputGroupCompatible',
    date: '',
    file: 'Input',
    search: 'Input',
    textarea: 'Textarea',
    select: 'Select',
  }

  const Component = Fields[components[type]] ? Fields[components[type]] : null

  return Component ? (
    <Field {...props}>
      {({ field, meta, form }) => (
        <Component
          type={type}
          {...props}
          {...field}
          meta={meta}
          form={form}
        />
      )}
    </Field>
  ) : null
}

export default FormDispatcher
