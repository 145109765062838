import React, { forwardRef } from 'react'
import * as SubmenuComponents from '../SubmenuContent'
import { StyledSubmenu } from './styles'

const Submenu = ({ data, visibility, innerRef }) => {
  const submenuContentType = {
    'header.one-level-columns': 'SubmenuContentColumns',
    'header.one-level-with-blog': 'SubmenuContentLinks',
    'header.two-levels-with-blog': 'SubmenuContentGroups',
  }

  const Component = SubmenuComponents[submenuContentType[data.__component]]

  return (
    <StyledSubmenu
      $visibility={visibility}
      ref={innerRef}
    >
      <Component data={data} />
    </StyledSubmenu>
  )
}

export const SubmenuForwardingRef = forwardRef((props, ref) => (
  <Submenu
    {...props}
    innerRef={ref}
  />
))

export default Submenu
