import styled, { css } from 'styled-components'

export const ImagesWrapper = styled.div`
  width: 100%;
  position: relative;
  border-radius: ${({ theme }) => theme.rem(20)};
  overflow: hidden;

  img {
    object-fit: cover;
  }
`

export const ImageLeft = styled(ImagesWrapper)`
  aspect-ratio: 8 / 9;

  ${({ theme }) =>
    theme.mediaQuery.lg(`
    aspect-ratio: 16 / 16;
  `)};
`

export const ImageRight = styled(ImagesWrapper)`
  aspect-ratio: 8 / 6;

  ${({ theme }) =>
    theme.mediaQuery.lg(`
    aspect-ratio: 16 / 17;
  `)};
`

export const Wrapper = styled.div`
  padding: ${({ theme }) => theme.rem(32, 0)};

  ${ImageLeft} {
    ${({ theme, imageLeftAlignment }) =>
      theme.mediaQuery.lg(
        `
      margin-${imageLeftAlignment == 'left' ? 'bottom' : 'top'}: ${theme.rem(
        24
      )};
    `,
        true
      )};
  }
`
