import React from 'react'

import Tab from './Tab.jsx'
import Hero from './Hero.jsx'
import BulletPoint from './BulletPoint.jsx'
import RelatedProduct from './RelatedProduct.jsx'
import PostFull from './PostFull.jsx'
import PostMin from './PostMin.jsx'
import Document from './Document.jsx'
import Filial from './Filial.jsx'
import BaseCard from './BaseCard.jsx'

const Card = ({ type, data }) => {
  const cardsType = {
    tab: Tab,
    hero: Hero,
    bulletPoint: BulletPoint,
    relatedProduct: RelatedProduct,
    postFull: PostFull,
    postMin: PostMin,
    document: Document,
    filial: Filial,
    baseCard: BaseCard,
  }

  const Component = cardsType[type]

  return <Component {...data} />
}

export default Card
