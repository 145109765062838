const Header = ({ data }) => {
  const { preHeaderLinks, menus } = data

  const normalizedData = {
    preHeaderLinks,
    menus,
  }

  return normalizedData
}

export default Header
