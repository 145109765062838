import React, { Fragment } from 'react'

import { getHeadingID } from '@utils/getHeadingID'

import normalizeCta from '@models/CTA'

import { Button } from '@/atoms'
import { Paragraph } from '@/atoms/Typography'
import { SpacerBase } from '@/atoms/Grid'

import {
  LinksAccordion,
  LinksAccordionHeader,
  LinksAccordionContent,
  LinksAccordionHeaderContent,
  LinksAccordionToggle,
} from './styles'

const Links = ({
  refs,
  accordion,
  tabIndex,
  disabled,
  title,
  links,
  handleClick,
  passedKey,
}) => {
  const id = getHeadingID(title, passedKey)

  return (
    <LinksAccordion
      tabIndex={tabIndex}
      ref={refs.wrapper}
      disabled={disabled}
      key={passedKey}
    >
      <LinksAccordionHeader
        ref={refs.header}
        open={accordion.open}
      >
        <LinksAccordionHeaderContent>
          <Paragraph
            typo={'paragraph2'}
            bold
          >
            {title}
          </Paragraph>
        </LinksAccordionHeaderContent>
        <LinksAccordionToggle open={accordion.open}>
          <Button
            ariaLabel={
              accordion.open
                ? `Riduci${title ? ` ${title}` : ''}`
                : `Espandi${title ? ` ${title}` : ''}`
            }
            iconStart={'chevron-down'}
            tertiary
            inheritedColorScheme='dark'
            aria-expanded={accordion.open}
            aria-controls={id}
            handleClick={() => handleClick(accordion)}
          />
        </LinksAccordionToggle>
      </LinksAccordionHeader>
      <LinksAccordionContent
        ref={refs.content}
        id={id}
        open={accordion.open}
        role={'region'}
      >
        {links
          ? links.map((link, index) => {
              const normalizedLink = normalizeCta(link)
              return (
                <Fragment key={index}>
                  <Button
                    {...normalizedLink}
                    quaternary
                    inheritedColorScheme='dark'
                  />
                  <SpacerBase xs={2} />
                </Fragment>
              )
            })
          : null}
      </LinksAccordionContent>
    </LinksAccordion>
  )
}

export default Links
