import React from 'react'
import * as Components from '../'
import * as Models from '@models/index'

import SectionThemeContext from '@contexts/SectionTheme'

const SectionBuilder = ({ data }) => {
  const normalizedComponents = {
    Spacer: 'Spacer',
    Hero2: 'Hero',
    Hero3: 'Hero',
    RichText: 'RichText',
    Accordion: 'AccordionSection',
    Marquee: 'Marquee',
    Tabs: 'Tabs',
    Banner: 'Banner',
    BulletPoints: 'BulletPoints',
    RelatedProducts: 'RelatedProducts',
    VideoSection: 'VideoSection',
    ProductComparisonTable: 'ProductComparisonTable',
    RelatedPosts: 'RelatedPosts',
    BlogTitle: 'PostContentTitle',
    BlogText: 'PostContentText',
    BlogImage: 'PostContentImage',
    OneImage: 'Images',
    TwoImages: 'Images',
    Newsletter: 'Newsletter',
    CardsGrid: 'CardsGrid',
    BannerVideo: 'Banner',
  }

  return data
    ? data.map((props, i) => {
        const Component = Components[normalizedComponents[props.type]]
        const formattedData = Component
          ? Models[normalizedComponents[props.type]](props)
          : null

        // if (process.env.NODE_ENV !== "production") {
        //   console.log("[SECTION] - ", props.type)
        //   console.log("data - ", props)
        // }

        return Component ? (
          <SectionThemeContext.Provider
            key={`section-${props.type}-${i}-${props.id}`}
            value={formattedData.theme}
          >
            <Components.Section
              id={props?.sectionID}
              as={props.type == 'Spacer' ? 'div' : 'section'}
            >
              <Component {...formattedData} />
            </Components.Section>
          </SectionThemeContext.Provider>
        ) : null
      })
    : null
}

export default SectionBuilder
