import React from 'react'
import dynamic from 'next/dynamic'

const Table = dynamic(() => import('@/molecules/Table'), {
  ssr: false,
  loading: () => <p>Caricamento...</p>,
})

import { Container } from '@/atoms/Grid'

import * as S from './styles'

const ProductComparisonTable = ({ table }) => {
  return (
    <S.ProductComparisonTable>
      <Container>
        <Table {...table} />
      </Container>
    </S.ProductComparisonTable>
  )
}

export default ProductComparisonTable
