import styled, { css } from 'styled-components'

export const OneImageWrapper = styled.div`
  max-width: 100%;
  position: relative;
  margin: 0 auto;
  border-radius: ${({ theme }) => theme.rem(20)};
  overflow: hidden;
  aspect-ratio: 4 / 3;

  img {
    object-fit: cover;
  }
`

export const OneImage = styled.div`
  padding: ${({ theme }) => theme.rem(32, 16)};

  ${({ theme, fullWidth }) =>
    fullWidth &&
    css`
      padding: ${theme.rem(0)};

      ${OneImageWrapper} {
        max-width: ${theme.rem(1920)};

        ${theme.mediaQuery.lg(`
        aspect-ratio: 21 / 9;
      `)};
      }
    `}

  ${({ theme, fullWidth }) =>
    !fullWidth &&
    css`
      ${OneImageWrapper} {
        ${theme.mediaQuery.lg(`
        max-width: ${theme.rem(1060)};
        aspect-ratio: 16 / 8;
      `)};
      }
    `}
`
