import styled, { css } from 'styled-components'

export const Nav = styled.div`
  display: flex;
  align-items: center;
  padding: ${({ theme }) => theme.rem(20, 8, 0, 8)};
`

export const NavScrollbar = styled.div`
  width: 100%;
  height: 1px;
  margin-right: ${({ theme }) => theme.rem(16)};
  background-color: ${({ theme }) => theme.colors.primary.light5};

  .swiper-scrollbar-drag {
    background-color: ${({ theme }) => theme.colors.primary.default};
    opacity: 0.5;
  }
`
