import styled, { css } from 'styled-components'

export const SidebarWrapper = styled.div`
  margin: ${({ theme }) => theme.rem(40, 0)};

  ${({ theme }) =>
    theme.mediaQuery.lg(`
    margin: ${theme.rem(80, 0)};
  `)};
`

export const SidebarButton = styled.div`
  &:not(:first-child) {
    margin-bottom: ${({ theme }) => theme.rem(16)};
    padding-bottom: ${({ theme }) => theme.rem(16)};
    border-bottom: 0.5px solid ${({ theme }) => theme.colors.primary.light4};
  }
`
