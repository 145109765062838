import normalizeImage from '@models/Image'

const PostContentImage = ({ description = null, image }) => {
  const normalizedData = {
    description,
    image: normalizeImage(image),
  }

  return normalizedData
}

export default PostContentImage
