import React, { useContext, useRef } from 'react'

import { SubmenuBackgroundContext } from '@contexts/SubmenuBackground'

import MenuDesktopItem from './Item'

import {
  StyledMenuDesktop,
  MenuDesktopWrapper,
  SubmenuBackground,
} from './styles'

const MenuDesktop = ({ menuData }) => {
  const wrapperRef = useRef(null)
  const submenuBackgroundRef = useRef(null)
  const { height } = useContext(SubmenuBackgroundContext)

  const styles = height <= 0 ? { borderBottom: '0' } : { height: `${height}px` }

  return (
    <StyledMenuDesktop>
      <MenuDesktopWrapper ref={wrapperRef}>
        {menuData
          ? menuData.map((el, i) => {
              return (
                <MenuDesktopItem
                  key={`menu-desktop-item-${el.id}-${i}`}
                  items={el}
                  wrapperRef={wrapperRef}
                />
              )
            })
          : null}
      </MenuDesktopWrapper>
      <SubmenuBackground
        ref={submenuBackgroundRef}
        style={{ ...styles }}
      />
    </StyledMenuDesktop>
  )
}

export default MenuDesktop
