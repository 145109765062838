import { getHeadingID } from '@utils/getHeadingID'

const PostContentTitle = ({ title }) => {
  const normalizedData = {
    id: getHeadingID(title.content, title?.id),
    title,
  }

  return normalizedData
}

export default PostContentTitle
