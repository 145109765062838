import React from 'react'

import { Button, InnerContent } from '@/atoms'
import { Col, Container, Row, SpacerBase } from '@/atoms/Grid'
import { Heading } from '@/atoms/Typography'
import { Spacer, ArchiveSidebar } from '@/organisms'
import Card from '@/molecules/Card'

import * as S from './styles'

const BlogList = ({ sidebar, posts }) => {
  return (
    <S.BlogList>
      <Container>
        <Row>
          <Col
            xs={12}
            md={4}
          >
            <ArchiveSidebar
              {...sidebar}
              entity='blog'
            />
          </Col>
          {posts
            ? posts.map((post, i) => (
                <Col
                  key={`blog-list-post-item-${post.link}`}
                  xs={12}
                  md={4}
                >
                  <Card
                    data={post}
                    type={'postFull'}
                  />
                </Col>
              ))
            : null}
        </Row>
        <Spacer
          size='md'
          separator
        />
      </Container>
    </S.BlogList>
  )
}

export default BlogList
