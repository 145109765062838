import normalizeCta from '@models/CTA'

const RichText = (data) => {
  const {
    tagline = '',
    title = '',
    paragraph = '',
    ctaPrimary = null,
    ctaSecondary = null,
    columnLeft = null,
    columnRight = null,
    align = '',
    space,
    theme = '',
  } = data || {}

  const normalizedData = {
    tagline,
    title,
    paragraph,
    ctaPrimary: normalizeCta(ctaPrimary),
    ctaSecondary: normalizeCta(ctaSecondary),
    columnLeft,
    columnRight,
    align,
    space,
    theme,
  }

  return normalizedData
}

export default RichText
