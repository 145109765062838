import { Button } from '@/atoms'
import { Col, Row } from '@/atoms/Grid'
import { Heading, Paragraph } from '@/atoms/Typography'

import * as S from './styles'

const SubmenuContentLinks = ({ data }) => {
  return (
    <S.Wrapper>
      <Row>
        <Col
          xs={12}
          md={3}
        >
          <S.LinksPart>
            {data.links.map((el, i) => {
              return (
                <Button
                  key={el.id}
                  fifth
                  fifthWithBG
                  inheritedColorScheme='white'
                  iconEnd={'arrow-right-big'}
                >
                  {el.label ? (
                    <Heading
                      typo={'displayXS'}
                      bold
                    >
                      {el.label}
                    </Heading>
                  ) : null}
                  {el.description ? (
                    <Paragraph typo={'paragraph2'}>{el.description}</Paragraph>
                  ) : null}
                </Button>
              )
            })}
          </S.LinksPart>
        </Col>
        <Col
          xs={12}
          md={9}
        >
          <S.BlogPart>Blog</S.BlogPart>
        </Col>
      </Row>
    </S.Wrapper>
  )
}

export default SubmenuContentLinks
