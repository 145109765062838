import styled, { css } from 'styled-components'

export const PostContent = styled.div``

export const PostContentIntro = styled.div``

export const PostContentIntroHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.rem(32)};
`

export const PostContentIntroHeaderCategory = styled.div`
  font-size: ${({ theme }) => theme.rem(12)};
  font-weight: 700;
  padding: ${({ theme }) => theme.rem(8)};
  margin-right: ${({ theme }) => theme.rem(16)};
  background-color: ${({ theme }) => theme.colors.secondary.light4};
  color: ${({ theme }) => theme.colors.primary.light1};
  border-radius: 5px;
  text-transform: uppercase;
`

export const PostContentIntroHeaderDate = styled.div`
  font-size: ${({ theme }) => theme.rem(14)};
  text-transform: capitalize;
`

export const PostContentIntroHeaderRead = styled.div`
  position: relative;
  font-size: ${({ theme }) => theme.rem(14)};
  padding-left: ${({ theme }) => theme.rem(10)};
  margin-left: ${({ theme }) => theme.rem(8)};

  &::before {
    content: '';
    width: 4px;
    height: 4px;
    display: block;
    position: absolute;
    top: 4px;
    left: 0;
    background-color: ${({ theme }) => theme.colors.primary.default};
    border-radius: 100%;
  }
`
