import styled, { css } from 'styled-components'

export const Hero = styled.div`
  padding: ${({ theme }) => theme.rem(32, 0)};

  /* ${({ theme }) =>
    theme.mediaQuery.lg(`
    padding: ${theme.rem(80, 0)};
  `)}; */
`

export const HeroImage = styled.div`
  min-height: 470px;
  order: 0;
  position: relative;
  margin: ${({ theme }) => theme.rem(0, 0, 32, 0)};
  border-radius: ${({ theme }) => theme.rem(20)};
  overflow: hidden;

  ${({ theme }) =>
    theme.mediaQuery.lg(`
    margin: ${theme.rem(32, 0, 0, 0)};
  `)};

  img {
    object-fit: cover;
  }
`
