import React from 'react'

import { Button, Icon } from '@/atoms'
import { Container } from '@/atoms/Grid'

import * as S from '../styles'

const ModalBase = ({ children, handleCloseModal }) => {
  return (
    <S.ModalBase>
      <S.ModalHeader>
        <Container>
          <S.ModalHeaderContent>
            <Icon
              name='logo-mixed-dark'
              ariaLabel='Logo Arca Vita Arca Assicurazioni'
              unlockedProportions
              useIconColor
            />
            <Button
              label='Chiudi'
              ariaLabel='Chiudi modale'
              iconEnd='close'
              tertiary
              inheritedColorScheme='white'
              uppercase
              handleClick={handleCloseModal}
            />
          </S.ModalHeaderContent>
        </Container>
      </S.ModalHeader>
      <S.ModalBody>{children}</S.ModalBody>
    </S.ModalBase>
  )
}

export default ModalBase
