import React, { Fragment, useEffect, useState, useRef } from 'react'
import * as S from './styles'

const MarqueeRow = ({
  minItems = 5,
  play = false,
  pauseOnHover = false,
  pauseOnClick = false,
  direction = 'left',
  speed = 30,
  delay = 0,
  loop = 0,
  onFinish,
  onCycleComplete,
  children,
}) => {
  const [animate, setAnimate] = useState(false)
  const [isPlay, setIsPlay] = useState(play)
  const [containerWidth, setContainerWidth] = useState(0)
  const [marqueeWidth, setMarqueeWidth] = useState(0)
  const [isMounted, setIsMounted] = useState(false)
  const containerRef = useRef(null)
  const marqueeRef = useRef(null)

  useEffect(() => {
    if (!isMounted) return

    // Pause by default if children elements are minus then minItems
    if (children.length >= minItems) {
      setAnimate(true)
      setIsPlay(true)
    }

    const calculateWidth = () => {
      // Find width of container and width of marquee
      if (marqueeRef.current && containerRef.current) {
        setContainerWidth(containerRef.current.getBoundingClientRect().width)
        setMarqueeWidth(marqueeRef.current.getBoundingClientRect().width)
      }
    }

    calculateWidth()

    // Rerender on window resize
    window.addEventListener('resize', calculateWidth)
    return () => {
      window.removeEventListener('resize', calculateWidth)
    }
  }, [isMounted])

  useEffect(() => {
    setIsMounted(true)
  }, [])

  useEffect(() => {
    if (children.length >= minItems) {
      setIsPlay(play)
    }
  }, [play])

  useEffect(() => {
    if (window.innerWidth <= marqueeWidth && !isPlay) {
      setAnimate(true)
      setIsPlay(true)
    }
  }, [marqueeWidth])

  // Animation duration
  const duration =
    marqueeWidth < containerWidth
      ? containerWidth / speed
      : marqueeWidth / speed

  return (
    <Fragment>
      {!isMounted ? null : (
        <S.MarqueeRow
          ref={containerRef}
          style={{
            ['justifyContent']: animate ? 'flex-start' : 'center',
            ['--pause-on-hover']:
              !isPlay || pauseOnHover ? 'paused' : 'running',
            ['--pause-on-click']:
              !isPlay || (pauseOnHover && !pauseOnClick) || pauseOnClick
                ? 'paused'
                : 'running',
          }}
        >
          <S.MarqueeInner
            ref={marqueeRef}
            onAnimationIteration={onCycleComplete}
            onAnimationEnd={onFinish}
            style={{
              ['--play']: isPlay ? 'running' : 'paused',
              ['--direction']:
                direction === 'left' || children.length < minItems
                  ? 'normal'
                  : 'reverse',
              ['--duration']: `${duration}s`,
              ['--delay']: `${delay}s`,
              ['--iteration-count']: !!loop ? `${loop}` : 'infinite',
            }}
          >
            {children}
          </S.MarqueeInner>
          {animate ? (
            <S.MarqueeInner
              aria-hidden='true'
              style={{
                ['--play']: isPlay ? 'running' : 'paused',
                ['--direction']:
                  direction === 'left' || children.length < minItems
                    ? 'normal'
                    : 'reverse',
                ['--duration']: `${duration}s`,
                ['--delay']: `${delay}s`,
                ['--iteration-count']: !!loop ? `${loop}` : 'infinite',
              }}
            >
              {children}
            </S.MarqueeInner>
          ) : null}
        </S.MarqueeRow>
      )}
    </Fragment>
  )
}

export default MarqueeRow
