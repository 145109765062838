const FundDataSheet = ({
  name,
  performanceStartYear,
  trend,
  sustainabilityTypology,
  lastPriceDate,
  lastPrice,
  quotations,
}) => {
  const minFilterDate = new Date(
    new Date().setMonth(new Date().getMonth() - 12)
  )
    .toISOString()
    .split('T')[0]
  const maxFilterDate = new Date().toISOString().split('T')[0]

  const normalizedData = {
    name,
    performanceStartYear,
    trend,
    sustainabilityTypology,
    lastPriceDate,
    lastPrice,
    quotations: quotations.filter(
      (item) =>
        item.dataQuotazione >= minFilterDate &&
        item.dataQuotazione <= maxFilterDate
    ),
    minFilterDate,
    maxFilterDate,
  }

  return normalizedData
}

export default FundDataSheet
