import React from 'react'

import Image from 'next/image'

import { Button } from '@/atoms'
import { Row, Col, SpacerBase } from '@/atoms/Grid'
import { ItemsGrouped, RichText } from '@/organisms'

import {
  StyledTabsContentInner,
  StyledTabsRichTextWrapper,
  StyledTabsImageWrapper,
} from './styles'

const ContentImage = ({
  tagline,
  title,
  description,
  image,
  ctaPrimary,
  ctaSecondary,
}) => {
  return (
    <StyledTabsContentInner>
      <Row reverse={['xs', 'sm', 'md']}>
        <Col
          xs={12}
          lg={5}
        >
          <StyledTabsRichTextWrapper>
            <RichText
              tagline={tagline}
              title={title}
              paragraph={description}
              embedded
            />
            {ctaPrimary || ctaSecondary ? (
              <>
                <SpacerBase xs={4} />
                <ItemsGrouped
                  direction={{ xs: 'column', md: 'row' }}
                  space={{ xs: 16, md: 16 }}
                >
                  {ctaPrimary ? (
                    <Button
                      {...ctaPrimary}
                      primary
                      small
                      typo='button2'
                    />
                  ) : null}
                  {ctaSecondary ? (
                    <Button
                      {...ctaSecondary}
                      secondary
                      small
                      typo='button2'
                    />
                  ) : null}
                </ItemsGrouped>
              </>
            ) : null}
          </StyledTabsRichTextWrapper>
        </Col>
        <Col
          xs={12}
          lg={6}
          $offset={{ lg: 1 }}
        >
          {image ? (
            <StyledTabsImageWrapper>
              <Image
                alt={image.alt}
                src={image.url}
                fill
                sizes='(max-width: 768px) 50vw, (max-width: 1200px) 50vw, 33vw'
              />
            </StyledTabsImageWrapper>
          ) : null}
        </Col>
      </Row>
    </StyledTabsContentInner>
  )
}

export default ContentImage
