import React from 'react'

import Image from 'next/image'

import { InnerContent } from '@/atoms'
import { Col, Container, Row, SpacerBase } from '@/atoms/Grid'
import Card from '@/molecules/Card'
import { Spacer, ItemsGrouped } from '@/organisms'

import * as S from './styles'

const Hero3 = ({ cards, images, paragraph, title }) => {
  return (
    <S.Hero>
      <Container>
        <InnerContent
          {...title}
          bold
        >
          {title.content}
        </InnerContent>
        <Spacer
          size={'md'}
          separator
        />
        <Row>
          <Col
            xs={12}
            lg={5}
            $direction={'column'}
            reverse={['xs', 'sm', 'md']}
          >
            <SpacerBase
              xs={3}
              lg={0}
            />
            {paragraph.content ? (
              <InnerContent {...paragraph}>{paragraph.content}</InnerContent>
            ) : null}
            {images
              ? images.map((el) => (
                  <S.HeroImage key={el.id}>
                    <Image
                      src={el.url}
                      alt={el.alt}
                      fill
                      sizes='(max-width: 768px) 50vw, (max-width: 1200px) 50vw, 33vw'
                    />
                  </S.HeroImage>
                ))
              : null}
          </Col>
          <Col
            xs={12}
            lg={6}
            $offset={{ lg: 1 }}
            align={'center'}
          >
            <ItemsGrouped
              direction={{ xs: 'column' }}
              space={{ xs: 24, lg: 48 }}
            >
              {cards.map((item) => {
                return (
                  <Card
                    key={item.id}
                    data={item}
                    type={'hero'}
                  />
                )
              })}
            </ItemsGrouped>
          </Col>
        </Row>
      </Container>
    </S.Hero>
  )
}

export default Hero3
