import React from 'react'
import dynamic from 'next/dynamic'

import { Col, Container, Row, Visible, SpacerBase } from '@/atoms/Grid'

import * as S from './styles'
import { Heading } from '@/atoms/Typography'
import { Button } from '@/atoms'
import Card from '@/molecules/Card'

const Carousel = dynamic(() => import('@/molecules/Carousel'))

const RelatedPosts = ({ tagline, title, items, ctaPrimary }) => {
  const carouselItems =
    items.length > 0
      ? items.map((item, i) => {
          return {
            type: 'postFull',
            item,
          }
        })
      : null

  return (
    <S.RelatedPosts>
      <Container>
        {tagline ? (
          <Heading
            typo='label2'
            bold
            uppercase
          >
            {tagline}
          </Heading>
        ) : null}
        <SpacerBase xs={3} />
        {title ? (
          <Heading
            {...title}
            bold
          >
            {title.content}
          </Heading>
        ) : null}
        <Visible
          lg
          xl
          xxl
        >
          {items.length > 0 ? (
            <Row>
              {items.map((item, i) => {
                return (
                  <Col key={`blog-list-post-item-${item.link}-${i}`}>
                    <Card
                      data={item}
                      type={'postFull'}
                    />
                  </Col>
                )
              })}
            </Row>
          ) : null}
        </Visible>
        <Visible
          xs
          sm
          md
        >
          {carouselItems ? <Carousel items={carouselItems} /> : null}
        </Visible>
        {ctaPrimary ? (
          <S.RelatedPostsButton>
            {ctaPrimary ? (
              <Button
                {...ctaPrimary}
                primary
                small
                typo={'button2'}
              />
            ) : null}
          </S.RelatedPostsButton>
        ) : null}
      </Container>
    </S.RelatedPosts>
  )
}

export default RelatedPosts
