import React from 'react'

import Image from 'next/image'

import { Heading, Paragraph } from '@/atoms/Typography'
import { SpacerBase } from '@/atoms/Grid'

import * as S from './styles'
import Link from 'next/link'
import normalizePostCard from '@models/PostCard'

const PostFull = (data) => {
  const { image, category, title, excerpt, date, link } =
    normalizePostCard(data)
  return (
    <S.PostCard>
      <Link href={link}>
        {title ? <span className='sr-only'>{title}</span> : null}
        {image ? (
          <S.PostCardImage>
            <Image
              src={image.url}
              alt={image.alt}
              fill
              sizes='(max-width: 768px) 50vw, (max-width: 1200px) 50vw, 33vw'
            />
          </S.PostCardImage>
        ) : null}
        {category ? (
          <>
            <SpacerBase xs={3} />
            <Paragraph typo='link1'>{category}</Paragraph>
          </>
        ) : null}
        {title ? (
          <>
            <SpacerBase xs={1} />
            <S.PostCardTitle>
              <Heading
                as='div'
                typo='displayXS'
              >
                {title}
              </Heading>
            </S.PostCardTitle>
          </>
        ) : null}
        {excerpt ? (
          <>
            <SpacerBase xs={2} />
            <S.PostCardExcerpt>
              <Paragraph typo='paragraph2'>{excerpt}</Paragraph>
            </S.PostCardExcerpt>
          </>
        ) : null}
        {date ? (
          <S.PostCardDate>
            <SpacerBase xs={2} />
            <Paragraph typo='link1'>{date}</Paragraph>
          </S.PostCardDate>
        ) : null}
      </Link>
    </S.PostCard>
  )
}

export default PostFull
