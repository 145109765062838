import styled, { css } from 'styled-components'

export const Performance = styled.div``

export const PerformanceTitle = styled.div`
  display: flex;
  align-items: center;

  ${({ theme }) =>
    theme.mediaQuery.lg(
      `
    width: 100%;
    justify-content: center;
    margin-bottom: ${theme.rem(30)};
  `,
      true
    )};

  i {
    margin-right: 20px;
  }
`

export const PerformanceItem = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => theme.rem(18, 8)};
  border-top: 1px solid ${({ theme }) => theme.colors.primary.light3};

  &:last-child {
    border-bottom: 1px solid ${({ theme }) => theme.colors.primary.light3};
  }
`
