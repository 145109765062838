import styled, { css } from 'styled-components'
import { Col } from '@/atoms/Grid'
import { StyledButton } from '@/atoms/Button/styles'

export const Wrapper = styled.div`
  max-width: 1440px;
  margin: 0 auto;
`

export const Item = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: ${({ theme }) => theme.rem(30, 0)};
  padding: ${({ theme }) => theme.rem(0, 30)};

  &:not(:first-child) {
    border-left: 1px solid ${({ theme }) => theme.colors.secondary.light5};
  }
`

export const ItemIcon = styled.div``

export const ItemButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: ${({ theme }) => theme.rem(20)};

  ${StyledButton} {
    &:not(:last-child) {
      margin-bottom: ${({ theme }) => theme.rem(20)};
    }
  }
`
