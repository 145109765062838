import GroupsSubmenu from './GroupsSubmenu'

import { Col, Row } from '@/atoms/Grid'
import Card from '@/molecules/Card'

import * as S from './styles'

const SubmenuContentGroups = ({ data }) => {
  return (
    <S.Wrapper>
      <Row>
        <Col
          xs={12}
          md={6}
        >
          <S.LinksPart>
            {data.groups.map((el, i) => (
              <GroupsSubmenu
                key={el.id}
                data={el}
              />
            ))}
          </S.LinksPart>
        </Col>
        <Col
          xs={12}
          md={6}
        >
          <S.BlogPart>
            <Row>
              {data?.posts && data?.posts.length > 0
                ? data.posts.slice(0, 2).map((el, i) => (
                    <Col
                      xs={12}
                      md={6}
                      key={el.id}
                    >
                      <Card
                        type='postMin'
                        data={el}
                      />
                    </Col>
                  ))
                : null}
            </Row>
          </S.BlogPart>
        </Col>
      </Row>
    </S.Wrapper>
  )
}

export default SubmenuContentGroups
