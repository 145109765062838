import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import gridConfig, { GRID_DIMENSIONS } from '@theme/gridConfig'

const Col = styled.div`
  max-width: 100%;
  display: flex;

  ${(p) =>
    p.$direction &&
    css`
      ${typeof p.$direction === 'object'
        ? GRID_DIMENSIONS.map(
            (d) =>
              gridConfig(p).breakpoints[d] &&
              gridConfig(p).media[d]`${
                p.$direction[d] && `flex-direction: ${p.$direction[d]}`
              }`
          )
        : `flex-direction: ${p.$direction ? p.$direction : 'column'};`}
    `}

  flex: 1 0 0%;
  box-sizing: border-box;

  /* ${(p) =>
    typeof p.noGutter === 'object'
      ? css`
          ${GRID_DIMENSIONS.map(
            (d) =>
              gridConfig(p).breakpoints[d] &&
              gridConfig(p).media[d]`
      padding-right: ${({ theme }) =>
        theme.rem(gridConfig(p.noGutter[d]).gutterWidth[d] / 2)};
      padding-left: ${({ theme }) =>
        theme.rem(gridConfig(p.noGutter[d]).gutterWidth[d] / 2)};
    `
          )}
        `
      : !p.noGutter
      ? css`
          ${GRID_DIMENSIONS.map(
            (d) =>
              gridConfig(p).breakpoints[d] &&
              gridConfig(p).media[d]`
      padding-right: ${({ theme }) =>
        theme.rem(gridConfig(p).gutterWidth[d] / 2)};
      padding-left: ${({ theme }) =>
        theme.rem(gridConfig(p).gutterWidth[d] / 2)};
    `
          )}
        `
      : null} */

  ${(p) =>
    typeof p.noGutter === 'object'
      ? css`
          padding-left: ${({ theme }) =>
            theme.rem(gridConfig(p).gutterWidth['xs'] / 2)};
          padding-right: ${({ theme }) =>
            theme.rem(gridConfig(p).gutterWidth['xs'] / 2)};
        `
      : !p.noGutter
      ? css`
          padding-left: ${({ theme }) =>
            theme.rem(gridConfig(p).gutterWidth['xs'] / 2)};
          padding-right: ${({ theme }) =>
            theme.rem(gridConfig(p).gutterWidth['xs'] / 2)};
        `
      : null}

  ${(p) => css`
    ${GRID_DIMENSIONS.map(
      (d) =>
        p[d] &&
        gridConfig(p).breakpoints[d] &&
        gridConfig(p).media[d]`
      flex: 1 1 ${(p[d] / gridConfig(p).columns[d]) * 100}%;
      max-width: ${(p[d] / gridConfig(p).columns[d]) * 100}%;
    `
    )}
  `}

  ${(p) =>
    p.$offset &&
    css`
      ${GRID_DIMENSIONS.map(
        (d) =>
          gridConfig(p).breakpoints[d] &&
          gridConfig(p).media[d]`
      ${
        typeof p.$offset === 'object'
          ? p.$offset[d] !== undefined &&
            `margin-left: ${
              p.$offset[d] > 0
                ? (p.$offset[d] / gridConfig(p).columns[d]) * 100
                : 0
            }%`
          : `margin-left: ${(p.$offset / gridConfig(p).columns['xs']) * 100}%`
      };
    `
      )}
    `}

  ${(p) =>
    p.order !== undefined &&
    css`
      ${GRID_DIMENSIONS.map(
        (d) =>
          gridConfig(p).breakpoints[d] &&
          gridConfig(p).media[d]`
      ${
        typeof p.order === 'object'
          ? p.order[d] !== undefined && `order: ${p.order[d]}`
          : `order: ${p.order}`
      };
    `
      )}
    `}

  ${(p) =>
    p.reverse &&
    css`
      ${Array.isArray(p.reverse)
        ? GRID_DIMENSIONS.map(
            (d) =>
              gridConfig(p).breakpoints[d] &&
              gridConfig(p).media[d]`
          flex-direction: ${
            p.reverse.indexOf(d) !== -1 ? 'column-reverse' : 'column'
          };
        `
          )
        : 'flex-direction: column-reverse;'}
    `}

  ${(p) =>
    p.align &&
    css`
      ${typeof p.align === 'object'
        ? GRID_DIMENSIONS.map(
            (d) =>
              gridConfig(p).breakpoints[d] &&
              gridConfig(p).media[d]`${
                p.align[d] && `align-items: ${p.align[d]}`
              }`
          )
        : `align-items: ${p.align};`}
    `}
  
  ${(p) =>
    p.justify &&
    css`
      ${typeof p.justify === 'object'
        ? GRID_DIMENSIONS.map(
            (d) =>
              gridConfig(p).breakpoints[d] &&
              gridConfig(p).media[d]`${
                p.justify[d] && `justify-content: ${p.justify[d]}`
              }`
          )
        : `justify-content: ${p.justify};`}
    `}
  
  ${({ debug }) =>
    debug &&
    css`
      background-color: #cce2fc;
      outline: #fff solid 1px !important;
    `}
`

Col.displayName = 'Col'

const numberOrString = PropTypes.oneOfType([PropTypes.string, PropTypes.number])
const stringOrObject = PropTypes.oneOfType([PropTypes.string, PropTypes.object])
const numberOrObject = PropTypes.oneOfType([PropTypes.number, PropTypes.object])
const boolOrArray = PropTypes.oneOfType([PropTypes.bool, PropTypes.array])
const boolOrObject = PropTypes.oneOfType([PropTypes.bool, PropTypes.object])

Col.propTypes = {
  xs: numberOrString,
  sm: numberOrString,
  md: numberOrString,
  lg: numberOrString,
  xl: numberOrString,
  xxl: numberOrString,
  align: stringOrObject,
  justify: stringOrObject,
  order: numberOrObject,
  offSet: numberOrObject,
  reverse: boolOrArray,
  noGutter: boolOrObject,
  children: PropTypes.node,
  debug: PropTypes.bool,
}

export default Col
