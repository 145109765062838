import React, { useContext } from 'react'

import Image from 'next/image'

import { Button } from '@/atoms'
import { Col, Container, Row } from '@/atoms/Grid'
import Card from '@/molecules/Card'
import Video from '@/molecules/Video'
import { ItemsGrouped, RichText } from '@/organisms'

import SectionThemeContext from '@contexts/SectionTheme'

import * as S from './styles'

const Banner = ({
  tagline,
  title,
  paragraph,
  image,
  bulletPoints = [],
  ctaPrimary,
  ctaSecondary,
  variant,
  video = null,
}) => {
  const colorScheme = useContext(SectionThemeContext)

  return (
    <S.Banner>
      <Container>
        <Row reverse={variant == 'right' ? ['lg', 'xl', 'xxl'] : false}>
          <Col
            xs={12}
            lg={6}
            $offset={variant == 'right' ? { lg: 1 } : null}
            align={'center'}
          >
            {image ? (
              <S.BannerImage hasBulletPoints={bulletPoints.length > 0}>
                <Image
                  src={image.url}
                  alt={image.alt}
                  fill
                  sizes='(max-width: 768px) 50vw, (max-width: 1200px) 50vw, 50vw'
                />
              </S.BannerImage>
            ) : null}
            {!!video ? (
              <S.BannerVideo hasBulletPoints={bulletPoints.length > 0}>
                <Video {...video} />
              </S.BannerVideo>
            ) : null}
          </Col>
          <Col
            xs={12}
            lg={5}
            $offset={variant == 'right' ? null : { lg: 1 }}
            $direction={'column'}
            justify={'center'}
          >
            <RichText
              tagline={tagline}
              title={title}
              paragraph={paragraph}
              embedded
            />
            {bulletPoints.length > 0 ? (
              <S.BannerBulletPoints colorScheme={colorScheme}>
                <ItemsGrouped
                  direction={{ xs: 'column' }}
                  space={{ xs: 24, md: 40 }}
                >
                  {bulletPoints.map((item) => {
                    return (
                      <Card
                        key={item.id}
                        data={item}
                        type={'bulletPoint'}
                      />
                    )
                  })}
                </ItemsGrouped>
              </S.BannerBulletPoints>
            ) : null}
            {ctaPrimary || ctaSecondary ? (
              <S.BannerButtons>
                <ItemsGrouped
                  direction={{ xs: 'column', md: 'row' }}
                  space={{ xs: 16, md: 16 }}
                >
                  {ctaPrimary ? (
                    <Button
                      {...ctaPrimary}
                      primary
                      small
                      typo={'button2'}
                    />
                  ) : null}
                  {ctaSecondary ? (
                    <Button
                      {...ctaSecondary}
                      secondary
                      small
                      typo={'button2'}
                    />
                  ) : null}
                </ItemsGrouped>
              </S.BannerButtons>
            ) : null}
          </Col>
        </Row>
      </Container>
    </S.Banner>
  )
}

export default Banner
