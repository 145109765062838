import styled, { css } from 'styled-components'
import { StyledButton } from '../Button/styles'

export const Wrapper = styled.div`
  display: flex;
  padding: ${({ theme }) => theme.rem(10)};
  background-color: ${({ theme }) => theme.colors.grayscale.white};

  ${StyledButton} {
    &:first-child {
      margin-right: ${({ theme }) => theme.rem(10)};
    }
  }
`
