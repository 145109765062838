const BulletPoints = ({ bulletPoints, theme }) => {
  const normalizedData = {
    bulletPoints,
    theme,
  }

  return normalizedData
}

export default BulletPoints
