const Spacer = ({ theme, size, separator }) => {
  const normalizedData = {
    theme,
    size,
    separator,
  }

  return normalizedData
}

export default Spacer
