import styled, { css } from 'styled-components'
import { RichText } from '../RichText/styles'
import { StyledItemsGrouped } from '../ItemsGrouped/styles'

export const Banner = styled.div`
  padding: ${({ theme }) => theme.rem(32, 0)};

  /* ${({ theme }) =>
    theme.mediaQuery.lg(`
    padding: ${theme.rem(80, 0)};
  `)}; */

  ${RichText} {
    padding: 0;
  }
`

export const BannerImage = styled.div`
  width: 100%;
  position: relative;
  margin: ${({ theme }) => theme.rem(0, 0, 30, 0)};
  border-radius: ${({ theme }) => theme.rem(20)};
  overflow: hidden;
  aspect-ratio: 4 / 3;

  ${({ theme }) =>
    theme.mediaQuery.lg(`
    aspect-ratio: 16 / 15;
    margin: ${theme.rem(0, 0, 0, 0)};
  `)};

  ${({ theme, hasBulletPoints }) =>
    hasBulletPoints &&
    css`
      ${theme.mediaQuery.lg(`
      aspect-ratio: 16 / 19;
    `)};
    `}

  img {
    object-fit: cover;
  }
`

export const BannerVideo = styled.div`
  width: 100%;
  position: relative;
  margin: ${({ theme }) => theme.rem(0, 0, 30, 0)};
  border-radius: ${({ theme }) => theme.rem(20)};
  aspect-ratio: 16 / 9;
  overflow: hidden;

  ${({ theme }) =>
    theme.mediaQuery.lg(`
    margin: 0;
    align-self: flex-start;
    position: sticky;
    top: 164px;
  `)};
`

export const BannerButtons = styled.div`
  width: 100%;
  display: flex;
  margin-top: ${({ theme }) => theme.rem(40)};

  ${StyledItemsGrouped} {
    width: 100%;
  }

  a,
  button {
    width: 100%;

    ${({ theme }) =>
      theme.mediaQuery.lg(`
      width: auto;
    `)};
  }
`

export const BannerBulletPoints = styled.div`
  margin-top: ${({ theme }) => theme.rem(40)};

  ${({ theme, colorScheme }) =>
    colorScheme &&
    css`
      color: ${theme.colors.surface[colorScheme].text};
    `}
`
