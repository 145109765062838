import React from 'react'

import Image from 'next/image'

import * as S from './styles'

const OneImage = ({ image, fullWidth }) => {
  return image ? (
    <S.OneImage fullWidth={fullWidth}>
      <S.OneImageWrapper>
        <Image
          src={image.url}
          alt={image.alt}
          fill
          priority={true}
          sizes='(max-width: 400px) 45vw, (max-width: 577px) 50vw, (max-width: 768px) 70vw, (max-width: 1200px) 50vw, 80vw'
        />
      </S.OneImageWrapper>
    </S.OneImage>
  ) : null
}

export default OneImage
