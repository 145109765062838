import React, { useEffect, useState } from 'react'
import { Field } from 'formik'
import FieldBase from '../FieldBase'
import { Icon } from '@/atoms'
import * as S from './styles'

export const InputGroup = ({ options, label, hint, meta }) => {
  return (
    <FieldBase
      label={label}
      meta={meta}
      hint={hint}
    >
      {options.length > 0
        ? options.map(({ label, ...opt }, i) => {
            return (
              <S.InputGroupItem key={`form-field-${opt.type}-${opt.id}-${i}`}>
                <input
                  {...opt}
                  tabIndex='0'
                />
                {label ? (
                  <label htmlFor={opt.id}>
                    <span>
                      {opt.type == 'checkbox' ? <Icon name={'check'} /> : null}
                    </span>
                    {label}
                  </label>
                ) : null}
              </S.InputGroupItem>
            )
          })
        : null}
    </FieldBase>
  )
}

export const InputGroupCompatible = ({
  name,
  options,
  optionsType,
  label,
  hint,
  meta,
  form,
}) => {
  const onChange = (ev) => {
    const value = ev.target.checked
      ? optionsType == 'checkbox'
        ? [...form.values[name], ev.target.value]
        : [ev.target.value]
      : form.values[name].filter((val) => ev.target.value != val)

    form.setFieldValue(name, value)
  }

  return (
    <FieldBase
      label={label}
      meta={meta}
      hint={hint}
    >
      {options.length > 0
        ? options.map(({ label, ...opt }, i) => {
            return (
              <S.InputGroupItem key={`form-field-${opt.type}-${opt.id}-${i}`}>
                <input
                  {...opt}
                  onChange={onChange}
                />
                {label ? (
                  <label htmlFor={opt.id}>
                    <span>
                      {opt.type == 'checkbox' ? <Icon name={'check'} /> : null}
                    </span>
                    <S.InputGroupItemLabel
                      dangerouslySetInnerHTML={{
                        __html: label,
                      }}
                    />
                  </label>
                ) : null}
              </S.InputGroupItem>
            )
          })
        : null}
    </FieldBase>
  )
}
