import React, { useEffect, useRef, useState } from 'react'

import useDebounce from '@hooks/useDebounce'

import { Button } from '@/atoms'
import { Container } from '@/atoms/Grid'
import { Paragraph } from '@/atoms/Typography'
import { InputForwardingRef } from '@/atoms/Form/FormFields/Input'
import { getGlobalSearchData } from '@api/index'

import * as S from '../styles'

const ModalSearch = ({ handleCloseModal, open }) => {
  const inputRef = useRef(null)
  const [value, setValue] = useState('')
  const [results, setResults] = useState(null)
  const debouncedValue = useDebounce(value, 500)

  const handleSearchChange = (ev) => {
    setValue(ev.target.value)
  }

  useEffect(() => {
    if (value.length >= 3) {
      getGlobalSearchData(value).then((response) => {
        setResults(response)
      })
    }
  }, [debouncedValue])

  useEffect(() => {
    if (open && inputRef.current) {
      setTimeout(() => inputRef.current.focus(), 1000)
    }
  }, [open])

  return (
    <S.ModalSearch>
      <S.ModalHeader>
        <Container>
          <S.ModalHeaderContent>
            <InputForwardingRef
              type='text'
              id='globalSearch'
              name='globalSearch'
              placeholder='Cosa stai cercando?'
              onChange={handleSearchChange}
              ref={inputRef}
            />
            <Button
              ariaLabel='Chiudi ricerca'
              iconEnd='close'
              inheritedColorScheme='white'
              tertiary
              handleClick={handleCloseModal}
            />
          </S.ModalHeaderContent>
        </Container>
      </S.ModalHeader>
      <S.ModalBody>
        {results ? (
          <S.ModalSearchResults>
            <Container>
              {results.pages.length <= 0 && results.blog.length <= 0 && results.productInfoDocument <= 0 ? (
                <Paragraph
                  typo='paragraphLG'
                  content='Nessun risultato'
                  bold
                />
              ) : (
                <>
                  {results.pages.length > 0 ? (
                    <S.ModalSearchResultsList>
                      <Paragraph
                        typo='label2'
                        content='Pagine'
                        bold
                        uppercase
                      />
                      {results.pages.map((item, i) => (
                        <S.ModalSearchResultsListItem
                          key={`search-result-page-${i}-${item.id}`}
                        >
                          <Button
                            label={item.title}
                            ariaLabel={`Vai alla pagina ${item.title}`}
                            href={`/${item.slug}`}
                            quaternary
                            quaternaryAnimIn
                            inheritedColorScheme='white'
                          />
                        </S.ModalSearchResultsListItem>
                      ))}
                    </S.ModalSearchResultsList>
                  ) : null}
                  {results.blog.length > 0 ? (
                    <S.ModalSearchResultsList>
                      <Paragraph
                        typo='label2'
                        content='News'
                        bold
                        uppercase
                      />
                      {results.blog.map((item, i) => (
                        <S.ModalSearchResultsListItem
                          key={`search-result-blog-${i}-${item.id}`}
                        >
                          <Button
                            label={item.title}
                            ariaLabel={`Vai alla news ${item.title}`}
                            href={`/blog/${item.slug}`}
                            quaternary
                            quaternaryAnimIn
                            inheritedColorScheme='white'
                          />
                        </S.ModalSearchResultsListItem>
                      ))}
                    </S.ModalSearchResultsList>
                  ) : null}
                  {results.productInfoDocument.length > 0 ? (
                    <S.ModalSearchResultsList>
                      <Paragraph
                        typo='label2'
                        content='Set informativi'
                        bold
                        uppercase
                      />
                      {results.productInfoDocument.map((item, i) => (
                        <S.ModalSearchResultsListItem
                          key={`search-result-set-informativi-${i}-${item.id}`}
                        >
                          <S.ModalSearchResultsListItemTitle as={Paragraph} typo='button1' content={item.title} />
                          {item.documents && item.documents.length > 0 ? (
                            <S.ModalSearchResultsListItemDocs>
                              {item.documents.map((doc, j) => {
                                return (
                                  <Button
                                    key={`search-result-set-informativi-doc-${j}-${doc.document.id}`}
                                    label={doc.label}
                                    ariaLabel={`Apri il ${doc.label} di ${item.title}`}
                                    href={doc.document.url}
                                    target='_blank'
                                    tertiary
                                    inheritedColorScheme='dark'
                                  />
                                )
                              })}
                            </S.ModalSearchResultsListItemDocs>
                          ) : null}
                        </S.ModalSearchResultsListItem>
                      ))}
                    </S.ModalSearchResultsList>
                  ) : null}
                </>
              )}
            </Container>
          </S.ModalSearchResults>
        ) : null}
      </S.ModalBody>
    </S.ModalSearch>
  )
}

export default ModalSearch
