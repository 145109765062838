import React, { forwardRef, useEffect, useRef } from 'react'

import googleMapsLoader from '@services/googleMapsLoader'
import getGeolocation from '@services/getGeolocation'

import FieldBase from '../FieldBase'

import { Button } from '@/atoms'
import { SpacerBase } from '@/atoms/Grid'

import * as S from './styles'

const Input = ({
  id,
  name,
  value,
  label,
  placeholder,
  autocomplete,
  disabled,
  hint,
  meta,
  form,
  button,
  geolocate,
  googleAutocomplete,
  dateMask,
  innerRef,
  onChange,
  ...props
}) => {
  const inputRef = useRef(null)

  const updateValue = (value) => {
    if (form) {
      // Formik support
      form.setFieldValue(name, value, true)
    } else {
      // Default
      inputRef.current.value = value
    }
  }

  const handleFileChange = (ev) => {
    let file = ev.target.files[0]
    updateValue(file)
  }

  const handleGeolocate = async () => {
    const res = await getGeolocation()

    if (res.results.length > 0) {
      updateValue(res.results[0].formatted_address)
    }
  }

  const handleOnKeyDown = (ev) => {
    if (ev.key === 'Enter') {
      ev.preventDefault()
    }

    // Add automatically slashes for Date
    if (dateMask) {
      ev.target.value = ev.target.value
        .replace(/^(\d\d)(\d)$/g, '$1/$2')
        .replace(/^(\d\d\/\d\d)(\d+)$/g, '$1/$2')
        .replace(/[^\d\/]/g, '')

      // ev.target.value = !isNaN((new Date(d)).getTime()) ? ev.target.value : null
    }
  }

  useEffect(() => {
    if (googleAutocomplete) {
      googleMapsLoader
        .load()
        .then((google) => {
          const autocomplete = new google.maps.places.Autocomplete(
            inputRef.current,
            {
              componentRestrictions: { country: 'IT' },
            }
          )

          autocomplete.addListener('place_changed', () => {
            let place = autocomplete.getPlace()

            // User entered the name of a Place that was not suggested and pressed the Enter key, or the Place Details request failed
            if (!place.geometry || !place.geometry.location) {
              return
              // If the place has a geometry, then show it on a map
            } else {
              updateValue(place.formatted_address)
            }
          })
        })
        .catch((e) => {
          console.error(e)
        })
    }
  }, [])

  return (
    <FieldBase
      id={id || name}
      label={label}
      disabled={disabled}
      meta={meta}
      hint={hint}
    >
      <S.InputWrapper>
        <input
          name={name}
          id={id || name}
          placeholder={placeholder || label}
          autoComplete={autocomplete}
          aria-label={label ? null : placeholder}
          disabled={disabled}
          {...props}
          value={props.type != 'file' ? value : undefined}
          onChange={props.type != 'file' ? onChange : handleFileChange}
          onKeyDown={handleOnKeyDown}
          ref={innerRef ? innerRef : inputRef}
        />
        {button ? (
          <Button
            ariaLabel='Cerca'
            {...button}
            tertiary
            inheritedColorScheme='white'
          />
        ) : null}
      </S.InputWrapper>
      {geolocate ? (
        <>
          <SpacerBase xs={1} />
          <Button
            type='button'
            label={'Utilizza la mia posizione'}
            iconEnd={'arrow-right-big'}
            tertiary
            inheritedColorScheme='white'
            handleClick={handleGeolocate}
          />
        </>
      ) : null}
    </FieldBase>
  )
}

export const InputForwardingRef = forwardRef((props, ref) => (
  <Input
    {...props}
    innerRef={ref}
  />
))

export default Input
