import * as yup from 'yup'

const createValidationSchema = (schema, config) => {
  const { name, validationType, validations = [], type: fieldType } = config

  if (!yup[validationType]) {
    return schema
  }

  let validator = yup[validationType]()
  if (validations && !!validations.length) {
    validations.forEach(({ params = [], type }) => {
      if (!validator[type]) {
        return
      }
      if (fieldType !== 'file') {
        validator = validator[type](...params)
      } else {
        const fileValidation = {
          fileSize: (value) => (value ? value.size <= Number(params[2]) : true),
          fileFormat: (value) =>
            value ? params[2].includes(value.type) : true,
        }
        validator = validator[type](
          params[0],
          params[1],
          fileValidation[params[0]]
        )
      }
    })
  }

  schema[name] = validator
  return schema
}

export default createValidationSchema
