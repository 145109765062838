import normalizeCta from '@models/CTA'
import normalizeImage from '@models/Image'

const Marquee = ({
  title,
  ctaPrimary,
  ctaSecondary,
  firstRow = [],
  secondRow = [],
}) => {
  let normalizedImagesFirstRow = []
  let normalizedImagesSecondRow = []

  for (const firstRowItem of firstRow ? firstRow : []) {
    normalizedImagesFirstRow.push(normalizeImage(firstRowItem))
  }

  for (const secondRowItem of secondRow ? secondRow : []) {
    normalizedImagesSecondRow.push(normalizeImage(secondRowItem))
  }

  const normalizedData = {
    title,
    ctaPrimary: normalizeCta(ctaPrimary),
    ctaSecondary: normalizeCta(ctaSecondary),
    rows: [normalizedImagesFirstRow, normalizedImagesSecondRow],
    theme: 'secondaryLight',
  }

  return normalizedData
}

export default Marquee
