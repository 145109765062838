import { Heading, Paragraph } from '@/atoms/Typography'
import React from 'react'
import { SectionBuilder, Spacer } from '@/organisms'

import * as S from './styles'

const PostContent = ({ intro, content }) => {
  return (
    <S.PostContent>
      <S.PostContentIntro>
        <S.PostContentIntroHeader>
          {intro.category ? (
            <S.PostContentIntroHeaderCategory>
              {intro.category}
            </S.PostContentIntroHeaderCategory>
          ) : null}
          {intro.date ? (
            <S.PostContentIntroHeaderDate>
              {intro.date}
            </S.PostContentIntroHeaderDate>
          ) : null}
          {intro.readTime ? (
            <S.PostContentIntroHeaderRead>
              {intro.readTime} min read
            </S.PostContentIntroHeaderRead>
          ) : null}
        </S.PostContentIntroHeader>
        {intro.introduction ? (
          <Heading
            typo='displayXS'
            content={intro.introduction}
          />
        ) : null}
        <Spacer
          size='sm'
          separator
        />
      </S.PostContentIntro>
      <SectionBuilder data={content} />
    </S.PostContent>
  )
}

export default PostContent
