import styled, { css } from 'styled-components'

export const StyledMenuMobile = styled.div`
  width: ${({ theme }) => theme.rem(315)};
  height: 100vh;
  background-color: ${({ theme }) => theme.colors.primary.light5};
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  transition: transform ${({ theme }) => theme.timing.fast};
  transform: translateX(100%);
  border-radius: ${({ theme }) => theme.rem(40)} 0 0
    ${({ theme }) => theme.rem(40)};
  overflow: hidden;

  ${({ isOpen }) =>
    isOpen &&
    css`
      transform: translateX(0);
    `}
`

export const StyledMenuMobileContent = styled.div`
  height: calc(100svh - ${({ theme }) => theme.rem(56)});
  margin-top: ${({ theme }) => theme.rem(56)};
  padding-bottom: ${({ theme }) => theme.rem(40)};
  overflow-y: scroll;
`

export const StyledFirstLevel = styled.div``

export const StyledFirstLevelItem = styled.div`
  padding: ${({ theme }) => theme.rem(60, 16, 44)};
  border-bottom: 1px solid ${({ theme }) => theme.colors.primary.light4};
  display: flex;
  justify-content: space-between;

  ${({ small, theme }) =>
    small &&
    css`
      padding: ${theme.rem(16)};
    `}

  i {
    font-size: ${({ theme }) => theme.rem(24)};
  }
`

export const StyledFirstLevelButtonWrapper = styled.div`
  max-width: ${({ theme }) => theme.rem(282)};
  margin: 0 auto;
`

export const StyledMenuMobileBack = styled.div`
  position: absolute;
  left: ${({ theme }) => theme.rem(16)};
  top: ${({ theme }) => theme.rem(26)};
  display: flex;

  i {
    font-size: ${({ theme }) => theme.rem(24)};
    margin-right: ${({ theme }) => theme.rem(8)};
  }
`

export const StyledSecondLevelItem = styled.div`
  padding: ${({ theme }) => theme.rem(16)};
  border-bottom: 1px solid ${({ theme }) => theme.colors.primary.light4};

  i {
    font-size: ${({ theme }) => theme.rem(48)};
  }
`

export const StyledSecondLevelItemContent = styled.div`
  padding-right: ${({ theme }) => theme.rem(24)};
  position: relative;
`

export const StyledSecondLevelItemIconWrapper = styled.div`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);

  i {
    font-size: ${({ theme }) => theme.rem(24)};
  }
`

export const StyledSecondLevelItemLink = styled.div`
  &:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.rem(16)};
  }
`
