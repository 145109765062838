import styled, { css } from 'styled-components'

export const Marquee = styled.div`
  position: relative;
  padding: ${({ theme }) => theme.rem(32, 0)};
  text-align: center;

  ${({ isFocus }) =>
    isFocus &&
    css`
      border: 2px solid ${({ theme }) => theme.colors.secondary.default};
    `}
`

export const MarqueeTitle = styled.div`
  padding-top: ${({ theme }) => theme.rem(32)};
  margin-bottom: ${({ theme }) => theme.rem(25)};

  &::before {
    content: '';
    width: calc(100% - 32px);
    height: 1px;
    display: block;
    position: absolute;
    top: 32px;
    left: 50%;
    transform: translateX(-50%);
    background-color: ${({ theme }) => theme.colors.secondary.default};

    ${({ theme }) =>
      theme.mediaQuery.md(`
      width: 200px;
    `)};
  }
`

export const MarqueeRow = styled.div`
  width: auto;
  position: relative;
  display: flex;
  flex-direction: row;
  overflow-x: hidden;

  &:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.rem(25)};
  }

  &:hover div {
    animation-play-state: var(--pause-on-hover);
  }

  &:active div {
    animation-play-state: var(--pause-on-click);
  }
`

export const MarqueeInner = styled.div`
  /* min-width: 100%; */
  flex: 0 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  animation: scroll var(--duration) linear var(--delay) var(--iteration-count);
  animation-play-state: var(--play);
  animation-delay: var(--delay);
  animation-direction: var(--direction);
  z-index: 1;

  @keyframes scroll {
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
`

export const MarqueeItem = styled.div`
  width: 140px;
  height: 80px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: ${({ theme }) => theme.rem(20)};
  margin-right: ${({ theme }) => theme.rem(25)};
  border-radius: 20px;
  background-color: ${({ theme }) => theme.colors.grayscale.white};

  ${({ theme }) =>
    theme.mediaQuery.md(`
    width: 175px;
    height: 100px;
  `)};

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`

export const MarqueeCTA = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${({ theme }) => theme.rem(40)};
`
