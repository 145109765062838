import React from 'react'
import Link from 'next/link'

import { Icon } from '@/atoms'
import { Paragraph } from '@/atoms/Typography'
import { SpacerBase } from '@/atoms/Grid'

import {
  StyledFirstLevel,
  StyledSecondLevelItem,
  StyledSecondLevelItemContent,
  StyledSecondLevelItemIconWrapper,
  StyledSecondLevelItemLink,
} from './styles'

const SecondLevel = ({ data }) => {
  return (
    <StyledFirstLevel>
      {data.map((item) => {
        return (
          <StyledSecondLevelItem
            key={item.id}
            small
          >
            {item.title || item.description ? (
              <StyledSecondLevelItemContent>
                <Link href={item.href ? item.href : ''}>
                  {item.icon ? <Icon name={item.icon} /> : null}
                  {item.title || item.label ? (
                    <Paragraph
                      typo={'paragraphLG'}
                      bold
                    >
                      {item.title || item.label}
                    </Paragraph>
                  ) : null}
                  {item.description ? (
                    <Paragraph typo={'paragraph2'}>
                      {item.description}
                    </Paragraph>
                  ) : null}
                  <StyledSecondLevelItemIconWrapper>
                    <Icon name={'arrow-right-big'} />
                  </StyledSecondLevelItemIconWrapper>
                </Link>
              </StyledSecondLevelItemContent>
            ) : null}
            {item.links && item.links.length > 0 ? (
              <>
                <SpacerBase xs={4} />
                {item.links?.map((link) => {
                  return (
                    <StyledSecondLevelItemLink key={link.id}>
                      <Paragraph typo={'paragraph2'}>
                        {`<a href="${link.href}" target="${link.target}">${link.label}</a>`}
                      </Paragraph>
                    </StyledSecondLevelItemLink>
                  )
                })}
              </>
            ) : null}
          </StyledSecondLevelItem>
        )
      })}
    </StyledFirstLevel>
  )
}

export default SecondLevel
