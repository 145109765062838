import gridConfig, { GRID_DIMENSIONS } from '@theme/gridConfig'

const getViewPort = () => {
  if (typeof window !== 'undefined' && window.innerWidth) {
    return window.innerWidth
  }
  return null
}

const getScreen = (props = {}) => {
  let screenClass
  const screenClasses = GRID_DIMENSIONS
  const breakpoints = Object.values(gridConfig(props).breakpoints)
  const viewport = getViewPort()

  if (viewport) {
    screenClass = screenClasses[0]

    if (breakpoints[1] && viewport >= breakpoints[1])
      screenClass = screenClasses[1]
    if (breakpoints[2] && viewport >= breakpoints[2])
      screenClass = screenClasses[2]
    if (breakpoints[3] && viewport >= breakpoints[3])
      screenClass = screenClasses[3]
    if (breakpoints[4] && viewport >= breakpoints[4])
      screenClass = screenClasses[4]
    if (breakpoints[5] && viewport >= breakpoints[5])
      screenClass = screenClasses[5]
  }

  console.info(screenClass)

  return screenClass
}

export { getScreen, getViewPort }
