import styled, { css } from 'styled-components'
import styledMap from 'styled-map'

export const StyledIcon = styled.i`
  display: inline-flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  font-size: ${({ theme }) => styledMap`
    large: ${theme.rem(48)};
    huge: ${theme.rem(32)};
    big: ${theme.rem(24)};
    default: ${theme.rem(16)};
  `};
  /* cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')}; */

  svg {
    width: 1em;
    height: 1em;

    ${({ unlockedProportions }) =>
      unlockedProportions &&
      css`
        height: auto;
        width: auto;
      `}
  }

  path {
    ${({ useIconColor, color = 'currentColor' }) =>
      !useIconColor &&
      css`
        fill: ${color};
      `}
  }

  ${({ round, theme }) =>
    round &&
    css`
      padding: ${theme.rem(8)};
      background-color: ${theme.colors.white};
      box-shadow: ${theme.commons.boxShadow};
      border-radius: 50%;
    `}
`
