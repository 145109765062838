import { css } from 'styled-components'

export const getSpacerSize = (size, prop = 'height') => {
  const sizes = {
    sm: {
      desktop: 40,
      mobile: 20,
    },
    md: {
      desktop: 80,
      mobile: 40,
    },
    lg: {
      desktop: 120,
      mobile: 60,
    },
  }

  const styles =
    prop == 'height'
      ? css`
          height: ${({ theme }) => theme.rem(sizes[size].mobile)};

          ${({ theme }) =>
            theme.mediaQuery.md(`
        height: ${theme.rem(sizes[size].desktop)};
      `)}
        `
      : css`
          padding: ${({ theme }) => theme.rem(sizes[size].mobile, 0)};

          ${({ theme }) =>
            theme.mediaQuery.md(`
        padding: ${theme.rem(sizes[size].desktop, 0)};
      `)}
        `

  return styles
}
