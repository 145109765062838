/**
 * Accessibility specifications
 *
 * @url Accessibility https://www.w3.org/WAI/ARIA/apg/patterns/breadcrumb/
 */

import PropTypes from 'prop-types'
import { useState, useRef } from 'react'

import { Button } from '@/atoms'
import { ButtonLabel } from '@/atoms/Typography'
import { Container } from '@/atoms/Grid'

import { BreadcrumbList, BreadcrumbListItem } from './styles'

const Breadcrumb = ({ items, expanded = true }) => {
  const [expand, setExpanded] = useState(expanded)
  const listRef = useRef(null)

  const handleExpand = (ev) => {
    // Expand items
    setExpanded(true)

    // Set focus in the component after expanding
    const focusable = listRef.current.querySelector('a[href],button,[tabindex]')
    if (focusable) {
      focusable.focus()
    }
  }

  const dispatchItems = (items) => {
    let renderedItemsArr = []

    const renderedItems = items.reduce((acc, current, index) => {
      // Link component
      let link =
        index + 1 == items.length ? (
          <ButtonLabel
            as='span'
            aria-current='page'
            tertiary
          >
            {current.label}
          </ButtonLabel>
        ) : (
          <Button
            href={current.href}
            label={current.label}
            ariaLabel={`Vai a ${current.label}`}
            tertiary
            inheritedColorScheme='white'
          />
        )

      // Separator component
      let separator = (
        <ButtonLabel
          as='span'
          separator
          aria-hidden
        >
          /
        </ButtonLabel>
      )

      // Aggregator component
      let aggregator = (
        <Button
          label={'...'}
          tabIndex='0'
          ariaLabel={'Aggregator'}
          tertiary
          inheritedColorScheme='white'
          handleClick={handleExpand}
        />
      )

      // Add aggregator
      if (current.role == 'aggregator') {
        return renderedItemsArr.push(aggregator, separator)
      }

      if (index < items.length - 1) {
        // Add link + separator
        renderedItemsArr = acc.concat(link, separator)
      } else {
        // Add link
        renderedItemsArr.push(link)
      }

      return renderedItemsArr
    }, [])

    return renderedItems
  }

  // Render collapsed breadcrumb items
  const dispatchItemsBeforeAndAfter = (items) => {
    return [
      ...items.slice(0, 1),
      { role: 'aggregator' },
      ...items.slice(items.length - 1, items.length),
    ]
  }

  // Get breadcrumb items
  const breadcrumbItems = dispatchItems(
    expand ? items : dispatchItemsBeforeAndAfter(items)
  )

  return (
    <nav aria-label='Breadcrumb'>
      <Container>
        <BreadcrumbList ref={listRef}>
          {breadcrumbItems.map((el, i) => {
            return (
              <BreadcrumbListItem
                separator={el.props?.separator}
                key={`breadcrumb-item-${i}`}
              >
                {el}
              </BreadcrumbListItem>
            )
          })}
        </BreadcrumbList>
      </Container>
    </nav>
  )
}

Breadcrumb.propTypes = {
  items: PropTypes.array.isRequired,
  expanded: PropTypes.bool,
}

export default Breadcrumb
