import styled, { css } from 'styled-components'

export const RelatedPosts = styled.div`
  padding: ${({ theme }) => theme.rem(32, 0)};

  /* ${({ theme }) =>
    theme.mediaQuery.lg(`
    padding: ${theme.rem(80, 0)};
  `)}; */
`

export const RelatedPostsButton = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${({ theme }) => theme.rem(32)};
`
