import React, { useContext, useMemo } from 'react'

import { ModalContext } from '../../../contexts/Modal.jsx'

import ModalBase from './ModalBase'
import ModalSearch from './ModalSearch'

import * as S from './styles'

const normalizedComponents = {
  base: ModalBase,
  search: ModalSearch,
}

const Modal = ({ children, id, variant = 'base', onClose }) => {
  const { modalId, modalOpen, handleModalChange } = useContext(ModalContext)

  const Component = useMemo(
    () => normalizedComponents[variant],
    [modalId, modalOpen]
  )

  const handleCloseModal = (e) => {
    e.stopPropagation()

    handleModalChange(false, null)

    if (onClose) {
      onClose()
    }
  }

  return (
    <S.Modal
      key={`modal-${id}`}
      id={`modal-${id}`}
      open={modalOpen && modalId === id}
    >
      <S.ModalContent open={modalOpen}>
        <Component
          handleCloseModal={handleCloseModal}
          open={modalOpen && modalId === id}
        >
          {children}
        </Component>
      </S.ModalContent>
    </S.Modal>
  )
}

export default Modal
