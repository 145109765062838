import { useEffect, useRef } from 'react'

import googleMapsLoader from '@services/googleMapsLoader'

import { getCurrentLocation } from '@api/index'

import { default as normalizeMarkers } from '@models/Markers'

import * as S from './styles'

const Map = ({
  locations: baseLocations,
  search,
  handleOpenInfoWindow = () => {},
}) => {
  const googleMap = useRef(null)

  useEffect(() => {
    let map
    let markers = []
    const locations =
      baseLocations.length > 0 ? normalizeMarkers(baseLocations) : []

    googleMapsLoader.load().then((google) => {
      const initialView = {
        center: {
          lat: 41.89525045076914,
          lng: 12.494112524255282,
        },
        zoom: 5.6,
      }
      const buttonsDisabled = {
        fullscreenControl: false,
        mapTypeControl: false,
        streetViewControl: false,
        zoomControl: false,
      }

      map = new google.maps.Map(googleMap.current, {
        ...initialView,
        ...buttonsDisabled,
      })

      if (locations.length > 0 && search) {
        const location = getCurrentLocation({ address: search })
        location.then((res) => {
          if (res.results.length > 0) {
            locations.push({
              position: res.results[0].geometry.location,
              zIndex: 2,
            })
          }

          locations.map((markerData) => {
            const marker = new google.maps.Marker({
              zIndex: 1,
              ...markerData,
              map,
            })

            marker.position.filialID = markerData.id
            markers.push(marker)

            if (marker.position.filialID) {
              google.maps.event.addListener(marker, 'click', (ev) => {
                // Set current marker animation
                for (var j = 0; j < markers.length; j++) {
                  markers[j].setAnimation(null)
                }
                marker.setAnimation(google.maps.Animation.BOUNCE)

                if (handleOpenInfoWindow) handleOpenInfoWindow(ev)
              })
            }
          })

          let bounds = new google.maps.LatLngBounds()
          for (let i = 0; i < markers.length; i++) {
            bounds.extend(markers[i].position)
          }
          map.fitBounds(bounds)
        })
      }
    })
  }, [baseLocations])

  return <S.Map ref={googleMap} />
}

export default Map
