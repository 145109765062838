import React from 'react'

import { Button, InnerContent } from '@/atoms'
import { Heading, Paragraph } from '@/atoms/Typography'
import { SpacerBase } from '@/atoms/Grid'

import * as S from './styles'

const Filial = ({
  id,
  distance,
  bank,
  name,
  locations,
  address,
  tel,
  website,
}) => {
  return (
    <S.FilialCard key={id}>
      {distance ? (
        <>
          <InnerContent
            typo='label2'
            isTagline={true}
            uppercase
            bold
            content={`Distanza: ${distance} KM`}
          />
          <SpacerBase xs={1} />
        </>
      ) : null}
      {bank ? (
        <Heading
          typo='displayXS'
          content={bank}
          bold
        />
      ) : null}
      {name ? (
        <Paragraph
          typo='paragraph1'
          content={`Filiale di ${name} (${locations})`}
          bold
        />
      ) : null}
      {address ? (
        <>
          <SpacerBase xs={1} />
          <Paragraph
            typo='paragraph2'
            content={address}
          />
          {tel ? (
            <Paragraph
              typo='paragraph2'
              content={`Telefono: ${tel}`}
            />
          ) : null}
        </>
      ) : null}
      {website ? (
        <Button
          href={website}
          target='_blank'
          label='Visita il sito web'
          ariaLabel={`Visita il sito web di ${bank} ${name}`}
          tertiary
          uppercase
          inheritedColorScheme='white'
        />
      ) : null}
    </S.FilialCard>
  )
}

export default Filial
