import normalizeImage from '@models/Image'

const Tab = ({ tabs }) => {
  const normalizedData = {
    theme: 'white',
    tabs: tabs.map((tab) => {
      let type = tab.type ? tab.type : 'card'
      if (tab.image) type = 'image'
      if (tab.video) type = 'video'

      return {
        ...tab,
        image: tab?.image ? normalizeImage(tab.image) : null,
        type,
      }
    }),
  }

  return normalizedData
}

export default Tab
