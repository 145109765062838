import { css } from 'styled-components'

export const getBrandColor = (prop) => {
  const colors = {
    purpleLight: css`
      ${({ theme }) => theme.colors.brand.purple.light1}
    `,
    purple: css`
      ${({ theme }) => theme.colors.brand.purple.default}
    `,
    yellowLight: css`
      ${({ theme }) => theme.colors.brand.yellow.light1}
    `,
    yellow: css`
      ${({ theme }) => theme.colors.brand.yellow.default}
    `,
    orangeLight: css`
      ${({ theme }) => theme.colors.brand.orange.light1}
    `,
    orange: css`
      ${({ theme }) => theme.colors.brand.orange.default}
    `,
    greenLight: css`
      ${({ theme }) => theme.colors.brand.green.light1}
    `,
    green: css`
      ${({ theme }) => theme.colors.brand.green.default}
    `,
    tealLight: css`
      ${({ theme }) => theme.colors.brand.teal.light1}
    `,
    teal: css`
      ${({ theme }) => theme.colors.brand.teal.default}
    `,
    greyLight: css`
      ${({ theme }) => theme.colors.brand.grey.light1}
    `,
    grey: css`
      ${({ theme }) => theme.colors.brand.grey.default}
    `,
  }

  return colors[prop]
}
