import normalizeImage from './Image'

const Newsletter = ({ image, title, paragraph, privacyText }) => {
  const normalizedData = {
    image: normalizeImage(image),
    title,
    paragraph,
    privacyText,
    form: {
      method: 'POST',
      endpoint: 'api/newsletter/signup',
      baseUrlEndpoint: 'cms',
      fieldGroups: [
        {
          title: null,
          fields: [
            {
              type: 'email',
              id: 'email',
              name: 'email',
              placeholder: 'Email',
              required: true,
              'aria-required': 'true',
              col: {
                xs: 12,
                md: 12,
              },
              validationType: 'string',
              validations: [
                {
                  type: 'required',
                  params: ['Inserisci un indirizzo email'],
                },
              ],
            },
          ],
        },
      ],
      initialValues: {
        email: '',
      },
      submitButton: {
        type: 'submit',
        label: 'Iscriviti',
        ariaLabel: 'Iscriviti alla Newsletter',
        primary: true,
      },
      alignButtons: 'center',
      errorMessage: {
        label:
          "L'indirizzo email fornito è già registrato alla nostra newsletter. Prova con un altro indirizzo email.",
      },
      successMessage: {
        label: 'Iscrizione avvenuta con successo',
      },
    },
  }

  return normalizedData
}

export default Newsletter
