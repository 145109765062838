const Accordion = ({ elements }) => {
  const normalizedData = {
    theme: 'white',
    list: elements,
  }

  return normalizedData
}

export default Accordion
