import { API_URL, ENDPOINTS } from './config'

export const getApiUrl = (type = 'cms') => {
  return API_URL[type]
}

export const fetchData = async (url, opt = {}) => {
  let response

  try {
    const req = await fetch(url, opt)
    response = await req.json()
  } catch (err) {
    console.error(err)
  }

  return response
}

export const getPageData = async ({ entity, slug = '' }) => {
  const baseUrl = getApiUrl()
  let url = `${baseUrl}${ENDPOINTS[entity]}&filters[slug]=${slug}`

  const data = await fetchData(url)

  return data
}

export const getPagePreviewData = async (id) => {
  const baseUrl = getApiUrl()
  const endpoint = ENDPOINTS.pages
  const apiUrl = `${baseUrl}${endpoint}/${id}?populate=deep&publicationState=preview`

  const data = await fetchData(apiUrl)

  return data
}

export const getHeaderData = async () => {
  const baseUrl = getApiUrl()
  const endpoint = ENDPOINTS.header
  const apiUrl = `${baseUrl}${endpoint}`

  const data = await fetchData(apiUrl)

  return data
}

export const getFooterData = async () => {
  const baseUrl = getApiUrl()
  const endpoint = ENDPOINTS.footer
  const apiUrl = `${baseUrl}${endpoint}`

  const data = await fetchData(apiUrl)

  return data
}

export const getEntity = async ({ entity, baseUrlEndpoint = 'cms' }) => {
  const baseUrl = getApiUrl(baseUrlEndpoint)
  const endpoint = ENDPOINTS[entity]
  const apiUrl = `${baseUrl}${endpoint}`

  const data = await fetchData(apiUrl)

  data.entity = entity

  return data
}

export const submitForm = async (
  endpoint,
  baseUrlEndpoint = 'cms',
  options
) => {
  const baseUrl = getApiUrl(baseUrlEndpoint)
  const apiUrl = `${baseUrl}${endpoint}`

  const data = await fetchData(apiUrl, options)

  return data
}

export const getCurrentLocation = async (props) => {
  const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json`
  const params = {
    ...props,
    key: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY,
  }
  const options = {
    method: 'GET',
  }

  const url = `${apiUrl}?${new URLSearchParams(params)}`

  const data = await fetchData(url, options)

  return data
}

export const getStoreLocatorPageData = async () => {
  const baseUrl = getApiUrl()
  const endpoint = ENDPOINTS.storeLocatorPage
  const apiUrl = `${baseUrl}${endpoint}`

  const data = await fetchData(apiUrl)

  return data.data
}

export const getStoreLocatorData = async () => {
  const baseUrl = getApiUrl('proxy')
  const endpoint = ENDPOINTS.storeLocatorData
  const apiUrl = `${baseUrl}${endpoint}`

  const data = await fetchData(apiUrl)

  return data
}

export const getFaqCategoriesData = async () => {
  const baseUrl = getApiUrl()
  const endpoint = ENDPOINTS.faq
  const apiUrl = `${baseUrl}${endpoint}`

  const data = await fetchData(apiUrl)

  return data.data
}

export const getDocumentsProductCategoriesData = async () => {
  const baseUrl = getApiUrl()
  const endpoint = ENDPOINTS.documents
  const apiUrl = `${baseUrl}${endpoint}`

  const data = await fetchData(apiUrl)

  return data.data
}

export const getStatementsProductCategoriesData = async () => {
  const baseUrl = getApiUrl()
  const endpoint = ENDPOINTS.statements
  const apiUrl = `${baseUrl}${endpoint}`

  const data = await fetchData(apiUrl)

  return data.data
}

export const getPostData = async (id) => {
  const baseUrl = getApiUrl()
  const apiUrl = `${baseUrl}api/blog-posts/${id}?populate=deep`

  const data = await fetchData(apiUrl)

  return data.data
}

export const getContactPageData = async () => {
  const baseUrl = getApiUrl()
  const endpoint = ENDPOINTS.contactPage
  const apiUrl = `${baseUrl}${endpoint}`

  const data = await fetchData(apiUrl)

  return data.data
}

export const getFundData = async (id) => {
  const baseUrl = getApiUrl('proxy')
  const apiUrl = `${baseUrl}api/arca/fondi/${id}`

  const data = await fetchData(apiUrl)

  return data.body
}

export const getFundQuotationsData = async (id) => {
  const baseUrl = getApiUrl('proxy')
  const apiUrl = `${baseUrl}api/arca/fondi/${id}/quotazioni`

  const data = await fetchData(apiUrl)

  return data.body
}

export const getQuotationPageData = async () => {
  const baseUrl = getApiUrl()
  const endpoint = ENDPOINTS.quotationPage
  const apiUrl = `${baseUrl}${endpoint}`

  const data = await fetchData(apiUrl)

  return data.data
}

export const getGlobalSearchData = async (value) => {
  const baseUrl = getApiUrl()
  const endpoint = ENDPOINTS.globalSearch
  const apiUrl = `${baseUrl}${endpoint}?q=${value}`

  const data = await fetchData(apiUrl)

  return data.data
}

export const getPreviewByID = async (id, postType) => {
  const baseUrl = getApiUrl()
  const endpoint = ENDPOINTS[postType]
  const apiUrl = `${baseUrl}${endpoint}/${id}?populate=deep&publicationState=preview`

  const data = await fetchData(apiUrl)

  return data.data
}

// http://localhost:3000/api/preview?type=page&id=19&secret=YCbRb6W3uxrjgIk93H36LGE5US4XLYHgYoIh9Px1fSNsvs4bCxTcotpF0SPRzq3R
