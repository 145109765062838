import React from 'react'

import { Button, Icon } from '@/atoms'
import { Paragraph } from '@/atoms/Typography'
import { SpacerBase } from '@/atoms/Grid'

import {
  StyledFirstLevel,
  StyledFirstLevelItem,
  StyledFirstLevelButtonWrapper,
} from './styles'

const FirstLevel = ({ data, handleMenuNavigation }) => {
  return (
    <StyledFirstLevel>
      {data?.menuData?.map((item, index) => {
        return (
          <StyledFirstLevelItem
            key={item.id}
            onClick={() => handleMenuNavigation('second-level', index)}
          >
            <Paragraph
              typo={'paragraphLG'}
              bold
            >
              {item.title}
            </Paragraph>
            <Icon name={'arrow-right-big'} />
          </StyledFirstLevelItem>
        )
      })}
      {data?.preHeaderData?.map(({ id, href, target, label }) => {
        return (
          <StyledFirstLevelItem
            key={id}
            small
          >
            <Paragraph>
              {`<a href="${href}" target="${target}">${label}</a>`}
            </Paragraph>
          </StyledFirstLevelItem>
        )
      })}
      <SpacerBase xs={5} />
      <StyledFirstLevelButtonWrapper>
        <Button
          label={'Filiale più vicina'}
          iconEnd={'map-2'}
          href='/store-locator'
          secondary
          fullWidth
          small
          inheritedColorScheme='white'
        />
        <SpacerBase xs={2} />
        <Button
          label={'Entra in my arca'}
          href='https://my.arcassicura.it/myarca/login'
          target='_blank'
          primary
          fullWidth
          small
          inheritedColorScheme='white'
        />
      </StyledFirstLevelButtonWrapper>
    </StyledFirstLevel>
  )
}

export default FirstLevel
