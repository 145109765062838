import styled, { css } from 'styled-components'

export const FormMessage = styled.div`
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  padding: ${({ theme }) => theme.rem(16, 0)};
  background-color: ${({ theme, status }) =>
    theme.colors.status[status].default};
  color: ${({ theme }) => theme.colors.grayscale.white};
  z-index: ${({ theme }) => theme.layers.formMessage};
  transform: translateY(100%);
  opacity: 0;
  transition: all 0.5s ease-in-out;

  ${({ theme }) =>
    theme.mediaQuery.lg(`
    padding: ${theme.rem(46, 0)};
  `)};

  ${({ show }) =>
    show &&
    css`
      opacity: 1;
      transform: translateY(0);
    `}
`

export const FormMessageWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    color: ${({ theme }) => theme.colors.grayscale.white};

    &:hover {
      i {
        color: ${({ theme }) => theme.colors.grayscale.white};
      }
    }
  }
`
