import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import Link from 'next/link'

import useDebounce from '@hooks/useDebounce'

import normalizeProductsFinancialList from '@models/ProductsFinancialList'

import { Col, Container, Row } from '@/atoms/Grid'
import { Paragraph } from '@/atoms/Typography'
import { Input, Select } from '@/atoms/Form/FormFields'
import Accordion from '@/molecules/Accordion'

import * as S from './styles'

const ProductsFinancialList = ({
  productsFinancial: initialProductsFinancial,
  fundSearch,
  productsFinancialFilter,
  productsFinancialOrder,
}) => {
  const [productsFinancial, setProductsFinancial] = useState(
    initialProductsFinancial
  )
  const [accordion, setAccordion] = useState({ list: [] })

  const fundSearchRef = useRef(null)
  const [fundSearchVisible, setFundSearchVisible] = useState(false)
  const [funds, setFunds] = useState(fundSearch)

  const [searchValue, setSearchValue] = useState('')
  const debouncedSearchValue = useDebounce(searchValue, 500)

  // Alphabetical Order Filters func
  const orderFilters = {
    increasing: (items) =>
      items.sort((prev, next) => prev.name.localeCompare(next.name)),
    decreasing: (items) =>
      items.sort((prev, next) => next.name.localeCompare(prev.name)),
  }

  // Fund search debounce
  useMemo(() => {
    if (searchValue.length >= 3) {
      const res = fundSearch.filter(
        (item) =>
          item.label.toLowerCase().search(searchValue.toLowerCase()) != -1
      )
      setFunds(res)
      setFundSearchVisible(true)
    }
  }, [debouncedSearchValue])

  // Normalize accordions
  useMemo(() => {
    if (productsFinancial) {
      const newAccordion = normalizeProductsFinancialList(productsFinancial)
      setAccordion(newAccordion)
    }
  }, [productsFinancial])

  // Toggle Fund search results list
  useEffect(() => {
    const handleClickOutside = (ev) => {
      if (fundSearchRef.current && !fundSearchRef.current.contains(ev.target)) {
        setFundSearchVisible(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [fundSearchRef])

  // Set Fund search value
  const handleFundSearchChange = useCallback(
    (ev) => {
      setSearchValue(ev.target.value)
    },
    [searchValue]
  )

  // Products filter
  const handleProductsFinancialFilterChange = (ev) => {
    const values = Array.isArray(ev) ? ev : [ev]
    const filteredProductsFinancial =
      values.length <= 0
        ? initialProductsFinancial
        : values.map((val) => {
            const search = initialProductsFinancial.find(
              (item) => item.id == val.value
            )
            return search
          })
    setProductsFinancial([...filteredProductsFinancial])
  }

  // Products Order
  const handleProductsFinancialOrderChange = (ev) => {
    const filteredProductsFinancial = orderFilters[ev.value](
      initialProductsFinancial
    )
    setProductsFinancial([...filteredProductsFinancial])
  }

  return accordion.list.length > 0 ? (
    <S.Wrapper>
      <Container>
        <S.Filters>
          <Row>
            <Col
              xs={12}
              md={6}
            >
              <S.FiltersFundsSearch ref={fundSearchRef}>
                <Paragraph typo='paragraph2'>Filtra per:</Paragraph>
                <S.FiltersFundsSearchWrapper>
                  <Input
                    id='fundSearch'
                    name='fundSearch'
                    placeholder='Cerca il tuo fondo'
                    onChange={handleFundSearchChange}
                  />
                  {fundSearchVisible ? (
                    <S.FundSearch>
                      {funds.length > 0 ? (
                        funds.map((fund) => (
                          <Link
                            href={`/quotazioni/fondo/${fund.value}`}
                            target='_blank'
                            key={`${fund.value}`}
                          >
                            {fund.label}
                          </Link>
                        ))
                      ) : (
                        <S.FundSearchNoRes>
                          Nesun risultato trovato
                        </S.FundSearchNoRes>
                      )}
                    </S.FundSearch>
                  ) : null}
                </S.FiltersFundsSearchWrapper>
              </S.FiltersFundsSearch>
            </Col>
            <Col
              xs={12}
              md={3}
              align={{ xs: 'center' }}
            >
              <Select
                id='productsFinancialFilter'
                name='productsFinancialFilter'
                placeholder='Seleziona Prodotto'
                options={productsFinancialFilter}
                onChange={(ev) => handleProductsFinancialFilterChange(ev)}
                isMulti
                isSearchable
                hasControls={false}
                closeMenuOnSelect={false}
              />
            </Col>
            <Col
              xs={12}
              md={3}
              align={{ xs: 'center' }}
            >
              <Select
                id='productsFinancialOrder'
                name='productsFinancialOrder'
                placeholder='Ordina dalla A alla Z'
                options={productsFinancialOrder}
                onChange={(ev) => handleProductsFinancialOrderChange(ev)}
                isSearchable={false}
                hasControls={false}
                closeMenuOnSelect={true}
              />
            </Col>
          </Row>
        </S.Filters>
        <S.Content>
          <Accordion {...accordion} />
          {/* {accordion.list.map((item) => {
            return (
              <Paragraph key={item.id} content={item.title} />
            )
          })} */}
        </S.Content>
      </Container>
    </S.Wrapper>
  ) : null
}

export default ProductsFinancialList
