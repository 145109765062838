import React, { memo } from 'react'

import { Icon, Button } from '@/atoms'
import { Heading, Paragraph } from '@/atoms/Typography'
import { Col, Row, SpacerBase, Visible } from '@/atoms/Grid'

import {
  ProductFinancialAccordion,
  BaseAccordionHeader,
  BaseAccordionContent,
  BaseAccordionIconLeft,
  BaseAccordionToggle,
  ProductFinancialAccordionHeaderContent,
  ProductFinancialAccordionHeaderContentTitle,
  ProductFinancialAccordionFundsHeader,
  ProductFinancialAccordionFund,
  ProductFinancialTag,
  ProductFinancialAccordionTrend,
} from './styles'

const ProductFinancial = ({
  refs,
  accordion,
  iconLeft,
  tabIndex,
  disabled,
  title,
  description,
  items,
  handleClick,
  id,
}) => {
  return (
    <ProductFinancialAccordion
      tabIndex={tabIndex}
      ref={refs.wrapper}
      disabled={disabled}
      key={'productFinancialAccordion' + id}
      aria-expanded={accordion.open}
    >
      <BaseAccordionHeader
        ref={refs.header}
        open={accordion.open}
        onClick={() => handleClick(accordion)}
      >
        {iconLeft ? (
          <BaseAccordionIconLeft>
            <Icon name={iconLeft} />
          </BaseAccordionIconLeft>
        ) : null}
        <ProductFinancialAccordionHeaderContent>
          <ProductFinancialAccordionHeaderContentTitle>
            {title ? (
              <Heading
                typo={'displayXS'}
                content={title}
                bold
              />
            ) : null}
            {description ? <Paragraph content={description} /> : null}
          </ProductFinancialAccordionHeaderContentTitle>
        </ProductFinancialAccordionHeaderContent>
        <BaseAccordionToggle open={accordion.open}>
          <Button
            ariaLabel={
              accordion.open
                ? `Riduci${title ? ` ${title}` : ''}`
                : `Espandi${title ? ` ${title}` : ''}`
            }
            iconStart={'chevron-down'}
            tertiary
            inheritedColorScheme='white'
            handleClick={() => handleClick(accordion)}
          />
        </BaseAccordionToggle>
      </BaseAccordionHeader>
      <BaseAccordionContent
        ref={refs.content}
        open={accordion.open}
        role={'region'}
      >
        {items.length > 0 ? (
          accordion.open ? (
            <>
              <Visible
                lg
                xl
                xxl
              >
                <ProductFinancialAccordionFundsHeader>
                  <Row>
                    <Col
                      xs={12}
                      md={3}
                    >
                      <Paragraph
                        typo='paragraph2'
                        content='Fondo'
                        bold
                      />
                    </Col>
                    <Col
                      xs={12}
                      md={2}
                    >
                      <Paragraph
                        typo='paragraph2'
                        content='ISIN'
                        bold
                      />
                    </Col>
                    <Col
                      xs={12}
                      md={2}
                    >
                      <Paragraph
                        typo='paragraph2'
                        content='Valore della quotazione (euro)'
                        bold
                      />
                    </Col>
                    <Col
                      xs={12}
                      md={3}
                    >
                      <Paragraph
                        typo='paragraph2'
                        content='Storico'
                        bold
                      />
                    </Col>
                    <Col
                      xs={12}
                      md={2}
                    >
                      <Paragraph
                        typo='paragraph2'
                        content='Andamento Fondo'
                        bold
                      />
                    </Col>
                  </Row>
                </ProductFinancialAccordionFundsHeader>
              </Visible>
              {items.map((fund, i) => {
                return (
                  <ProductFinancialAccordionFund
                    key={`fund-${i}-${fund.idAlbedo}`}
                  >
                    <Row>
                      <Col
                        xs={12}
                        lg={3}
                        $direction={{ xs: 'column' }}
                        justify={{ xs: 'center' }}
                        align={{ xs: 'center', lg: 'flex-start' }}
                        noGutter={{ xs: true, lg: false }}
                      >
                        <Visible
                          xs
                          sm
                          md
                        >
                          <Paragraph
                            typo='paragraph2'
                            content='Fondo'
                            bold
                          />
                          <SpacerBase xs={1} />
                        </Visible>
                        {fund.sustainabilityTypology != '0' ? (
                          <>
                            <ProductFinancialTag>
                              <Icon name='descriptive-icons' />
                              <span>FONDO SOSTENIBILE</span>
                            </ProductFinancialTag>
                            <SpacerBase xs={0.5} />
                          </>
                        ) : null}
                        <Paragraph
                          typo='paragraphLG'
                          content={fund.name}
                          bold
                        />
                        <SpacerBase xs={0.5} />
                        <Paragraph
                          typo='link1'
                          content={`Prezzo al ${fund.lastPriceDate}`}
                        />
                      </Col>
                      <Col
                        xs={6}
                        lg={2}
                        $direction={{ xs: 'column', lg: 'row' }}
                        align={{ xs: 'center' }}
                        justify={{ xs: 'space-between', lg: 'flex-start' }}
                        noGutter={{ xs: true, lg: false }}
                      >
                        <Visible
                          xs
                          sm
                          md
                        >
                          <Paragraph
                            typo='paragraph2'
                            content='ISIN'
                            bold
                          />
                          <SpacerBase xs={1} />
                        </Visible>
                        <Paragraph
                          typo='paragraph2'
                          content={fund.idISIN}
                        />
                      </Col>
                      <Col
                        xs={6}
                        lg={2}
                        $direction={{ xs: 'column', lg: 'row' }}
                        align={{ xs: 'center' }}
                        justify={{ xs: 'space-between', lg: 'flex-start' }}
                        noGutter={{ xs: true, lg: false }}
                      >
                        <Visible
                          xs
                          sm
                          md
                        >
                          <Paragraph
                            typo='paragraph2'
                            content='Valore della quotazione (euro)'
                            bold
                          />
                          <SpacerBase xs={1} />
                        </Visible>
                        <ProductFinancialAccordionTrend>
                          {fund.trend ? (
                            <Icon
                              name={fund.trend}
                              huge
                            />
                          ) : null}
                          <Paragraph
                            typo='paragraphLG'
                            content={fund.lastPrice}
                            bold
                          />
                        </ProductFinancialAccordionTrend>
                      </Col>
                      <Col
                        xs={6}
                        lg={3}
                        $direction={{ xs: 'column', lg: 'row' }}
                        align={{ xs: 'center' }}
                        justify={{ xs: 'center', lg: 'flex-start' }}
                        noGutter={{ xs: true, lg: false }}
                      >
                        <Button
                          label='Visualizza storico'
                          href={`/quotazioni/fondo/${fund.idAlbedo}#storico-fondo`}
                          typo='link1'
                          uppercase
                          tertiary
                          inheritedColorScheme='white'
                        />
                      </Col>
                      <Col
                        xs={6}
                        lg={2}
                        $direction={{ xs: 'column', lg: 'row' }}
                        align={{ xs: 'center' }}
                        justify={{ xs: 'center', lg: 'flex-start' }}
                        noGutter={{ xs: true, lg: false }}
                      >
                        <Button
                          label='Vedi dettagli'
                          href={`/quotazioni/fondo/${fund.idAlbedo}`}
                          typo='link1'
                          uppercase
                          tertiary
                          inheritedColorScheme='white'
                        />
                      </Col>
                    </Row>
                  </ProductFinancialAccordionFund>
                )
              })}
            </>
          ) : null
        ) : (
          <Paragraph
            typo='paragraph1'
            content='Nessun fondo correlato al prodotto'
          />
        )}
      </BaseAccordionContent>
    </ProductFinancialAccordion>
  )
}

export default memo(ProductFinancial)
