import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
  max-width: 1440px;
  margin: 0 auto;
`

export const LinksPart = styled.div`
  padding: ${({ theme }) => theme.rem(30, 16)};
`

export const BlogPart = styled.div`
  width: 100%;
  height: calc(100% - 1px);
  padding: ${({ theme }) => theme.rem(120, 30)};
  background-color: ${({ theme }) => theme.colors.secondary.light5};
`
