import { formattedDate } from '@utils/formattedDate'

const FundHistory = ({ quotations, lastPriceDate, name }) => {
  const tabs = []

  // Get Years from Quotations
  const years = quotations.reduce((acc, curr) => {
    let year = new Date(curr.dataQuotazione).getFullYear()
    if (!acc.includes(year)) {
      return [...acc, year]
    }
    return acc
  }, [])

  // Get Object with Quotations divided by Years=
  let quotationsByYear = quotations.reduce((acc, curr) => {
    let year = new Date(curr.dataQuotazione).getFullYear()
    if (acc[year]) {
      return { ...acc, [year]: [...acc[year], curr] }
    }
    return { ...acc, [year]: [curr] }
  }, {})

  if (years.length > 0) {
    // Sort by older years
    // years.reverse()

    // Add Tab for each Year
    years.map((year, i) => {
      // Normalize Table Cell content by current Year
      const cells = quotationsByYear[year].reduce((acc, curr) => {
        return [
          ...acc,
          [
            {
              value: formattedDate(curr.dataQuotazione, 'short'),
            },
            {
              value: curr.valore,
            },
          ],
        ]
      }, [])

      tabs.push({
        type: 'table',
        id: i + 1,
        label: year,
        table: {
          head: [
            {
              label: 'Data',
            },
            {
              label: 'Euro',
            },
          ],
          body: [...cells],
        },
      })
    })
  }

  const normalizedData = {
    name,
    lastPriceDate,
    tabs,
  }

  return normalizedData
}

export default FundHistory
