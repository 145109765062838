const Markers = (locations = []) => {
  const markersNormalized = !locations
    ? []
    : locations.reduce((acc, curr) => {
        acc.push({
          id: curr.id,
          title: curr.name,
          position: {
            lat: Number(curr.lat),
            lng: Number(curr.lng),
          },
          icon: 'https://static.develop.arca-assicura-website-cms.n3.caffeina.host/map_pin_6036568c0e.svg?updated_at=2023-03-03T09:43:14.691Z',
        })
        return acc
      }, [])

  return markersNormalized
}

export default Markers
