import { useContext } from 'react'

import SectionThemeContext from '@contexts/SectionTheme'

import * as S from './styles'

const Section = ({ children, ...props }) => {
  const colorScheme = useContext(SectionThemeContext)

  return (
    <S.Section
      colorScheme={colorScheme}
      {...props}
    >
      {children}
    </S.Section>
  )
}

export default Section
