import styled, { css } from 'styled-components'
import styledMap from 'styled-map'
import { ButtonLabel } from '../Typography/styles'
import { getBrandColor } from '@utils/getBrandColor'

export const resetButton = css`
  width: auto;
  position: relative;
  margin: 0;
  padding: 0;
  cursor: pointer;
  text-align: inherit;
  color: inherit;
  border: none;
  background: transparent;
  line-height: normal;
  overflow: visible;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  appearance: none;
  -webkit-appearance: none;
`

export const StyledButton = styled.button`
  ${resetButton}
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  box-shadow: 0 0 0 0;
  transition:
    background 0.3s ease-in-out,
    color 0.3s ease-in-out,
    background-size 0.3s ease-in-out,
    box-shadow 0.3s ease-in-out;

  background-color: ${({ theme, colorScheme, variant }) =>
    theme.colors.button[colorScheme][variant].base.background};
  color: ${({ theme, colorScheme, variant }) =>
    theme.colors.button[colorScheme][variant].base.color};
  border: 1px solid
    ${({ theme, colorScheme, variant }) =>
      theme.colors.button[colorScheme][variant].base.borderColor};

  padding: ${({ theme, $label, iconStart, iconEnd }) => styledMap`
    default: ${
      (iconStart || iconEnd) && !$label ? theme.rem(8, 11) : theme.rem(12, 24)
    };
    secondary: ${
      (iconStart || iconEnd) && !$label ? theme.rem(8, 11) : theme.rem(12, 24)
    };
    tertiary: 0 0;
    quaternary: 0 0;
    fifth: 0 0;
    small: ${
      (iconStart || iconEnd) && !$label ? theme.rem(8, 11) : theme.rem(10, 20)
    };
  `};

  &:not([disabled]),
  &:not([aria-disabled='true']) {
    // HOVER
    @media screen and (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
      &:hover {
        background-color: ${({ theme, colorScheme, variant }) =>
          theme.colors.button[colorScheme][variant].hover.background};
        color: ${({ theme, colorScheme, variant }) =>
          theme.colors.button[colorScheme][variant].hover.color};
      }
    }

    // FOCUS
    &:focus {
      background-color: ${({ theme, colorScheme, variant }) =>
        theme.colors.button[colorScheme][variant].focus.background};
    }

    // ACTIVE
    &:active,
    &[aria-current='true'] {
      background-color: ${({ theme, colorScheme, variant }) =>
        theme.colors.button[colorScheme][variant].active.background};
      color: ${({ theme, colorScheme, variant }) =>
        theme.colors.button[colorScheme][variant].active.color};
    }

    // FOCUS and ACTIVE
    &:focus,
    &:active:focus {
      ${({ theme, quaternary, fifth }) =>
        !quaternary &&
        !fifth &&
        css`
          box-shadow: 0 0 0 ${theme.rem(4)} ${theme.colors.secondary.light4};
        `}
    }

    // VARIANT TERTIARY and QUATERNARY underline
    ${({ tertiary, quaternary, $label, iconStart, iconEnd }) =>
      (tertiary || quaternary) &&
      $label &&
      css`
        background-image: linear-gradient(0deg, currentColor, currentColor);
        background-position: ${iconStart ? 'right' : iconEnd ? 'left' : '100%'}
          100%;
        background-repeat: no-repeat;
        background-size: ${iconStart || iconEnd ? 'calc(100% - 28px)' : '100%'}
          1px;
        border-radius: 0;
        transform: translateZ(0);
        -webkit-font-smoothing: subpixel-antialiased;

        &:hover,
        &:focus {
          background-size: 0% 1px;
          outline-width: 0;
        }
      `}

    // VARIANT QUATERNARY underline
    ${({ quaternary, quaternaryAnimIn }) =>
      quaternary &&
      css`
        display: inline;
        background-size: ${quaternaryAnimIn ? '0%' : '100%'} 0.5px;

        &:hover,
        &:focus {
          background-size: ${quaternaryAnimIn ? '100%' : '0'} 0.5px;
        }
      `}

    // VARIANT FIFTH
    ${({ fifth }) =>
      fifth &&
      css`
        &[aria-current='true'] {
          ${ButtonLabel} {
            font-weight: 700;
          }
        }

        > i:last-of-type {
          svg {
            opacity: 0;
            transform: translateX(0);
            backface-visibility: hidden;
            transition: all 0.3s ease-in;
          }
        }

        &:hover,
        &:focus {
          > i:last-of-type {
            svg {
              opacity: 1;
              transform: translateX(3px);
            }
          }
        }
      `}

    ${({ theme, fifth, fifthWithBG, brandColor }) =>
      fifth &&
      fifthWithBG &&
      css`
        &::before {
          content: '';
          width: 100%;
          height: 100%;
          position: absolute;
          background-color: ${brandColor
            ? getBrandColor(brandColor)
            : theme.colors.grayscale.white};
          border-radius: 8px;
          z-index: 0;
          opacity: 0;
          transform: scale(1);
          transition: all 0.3s ease-in-out;
        }

        &:hover,
        &:focus {
          &::before {
            opacity: 1;
            transform: scale(1.1);
          }
        }
      `}
  }

  // ICONS
  && i {
    margin-right: ${({ theme, $label, icon }) =>
      $label && icon ? theme.rem(8) : 0};
    pointer-events: none;
  }

  // DISABLED
  &[disabled],
  &[aria-disabled='true'] {
    pointer-events: none;
    background-color: ${({ theme }) => styledMap`
      default: ${theme.colors.primary.light4};
      secondary: ${theme.colors.primary.light4};
      tertiary: transparent;
      quaternary: transparent;
      fifth: transparent;
    `};
    border-color: ${({ theme }) => theme.colors.primary.light4};
    color: ${({ theme }) => theme.colors.primary.light3};
  }

  // VARIANT FULL WIDTH
  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
    `}
`
