import React, { useEffect, useRef, useState } from 'react'

import { Button, Form } from '@/atoms'
import { Heading, Paragraph } from '@/atoms/Typography'
import { Row, Col, SpacerBase } from '@/atoms/Grid'
import Card from '@/molecules/Card'
import Map from '@/molecules/Map'

import { default as normalizeLocations } from '@models/Locations'

import * as S from './styles.js'

const StoreLocator = ({
  form,
  search: baseSearch,
  stores: baseStores = [],
  banks,
}) => {
  const srTextRef = useRef(null)

  const [state, setState] = useState({
    search: baseSearch,
    locations: baseStores,
  })
  const [infoWindow, setInfoWindow] = useState({
    open: false,
    data: null,
  })

  if (baseSearch) {
    form.initialValues.storeLocatorSearch = baseSearch
  }

  const handleSubmit = async (values, formResponse) => {
    const latLng = values.sort_by.split(',')
    const locations = normalizeLocations({
      locationSearched: {
        lat: latLng[0].split(':').pop(),
        lng: latLng[1].split(':').pop(),
      },
      locations: formResponse?.body ? formResponse.body : [],
      banks,
    })
    const nextState = {
      search: values.storeLocatorSearch,
      locations,
    }
    setState(nextState)
  }

  const handleOpenInfoWindow = (ev) => {
    const filialID = ev.latLng.filialID
    const filialSelected = state.locations.find(({ id }) => id === filialID)
    setInfoWindow({
      open: true,
      data: filialSelected,
    })
  }

  useEffect(() => {
    if (srTextRef.current) {
      srTextRef.current.focus()
    }
  }, [state])

  return (
    <>
      <S.StoreLocator>
        <S.StoreLocatorForm>
          {form ? (
            <Form
              {...form}
              onSubmit={handleSubmit}
            />
          ) : null}
        </S.StoreLocatorForm>
        <Row>
          <Col
            xs={12}
            lg={6}
            $direction='column'
            style={{ maxHeight: '500px', overflow: 'auto' }}
          >
            <p
              ref={srTextRef}
              className='sr-only'
              tabIndex='0'
              aria-label={`${state.locations.length} risultati trovati per la ricerca: ${state.search}. Esplora i risultati.`}
            >
              {`${state.locations.length} risultati trovati per la ricerca: ${state.search}`}
            </p>
            {state.locations.length > 0 ? (
              state.locations.map((item) => (
                <Card
                  key={item.id}
                  data={item}
                  type='filial'
                />
              ))
            ) : (
              <>
                <Heading
                  typo='displayMD'
                  content='Non abbiamo trovato nessun risultato'
                  bold
                />
                <SpacerBase xs={2} />
                <Paragraph
                  typo='paragraph1'
                  content='Prova a modificare la tua ricerca inserendo un indirizzo alternativo o cambiando i filtri scelti. Rintracceremo la sede più comoda da raggiungere.'
                />
              </>
            )}
          </Col>
          <Col
            xs={12}
            lg={6}
          >
            <S.StoreLocatorMap>
              <Map
                locations={state.locations}
                search={state.search}
                handleOpenInfoWindow={handleOpenInfoWindow}
              />
            </S.StoreLocatorMap>
          </Col>
        </Row>
      </S.StoreLocator>
      <S.InfoWindow open={infoWindow.open}>
        <S.InfoWindowContent open={infoWindow.open}>
          {infoWindow.data ? (
            <>
              <S.InfoWindowClose>
                <Button
                  ariaLabel='Chiudi infowindow banca'
                  iconEnd='close'
                  inheritedColorScheme='white'
                  iconEndWeight={{ big: true }}
                  tertiary
                  uppercase
                  handleClick={() => setInfoWindow({ open: false })}
                />
              </S.InfoWindowClose>
              <Card
                data={infoWindow.data}
                type='filial'
              />
            </>
          ) : null}
        </S.InfoWindowContent>
      </S.InfoWindow>
    </>
  )
}

export default StoreLocator
