import { useEffect, useRef, useState } from 'react'

import { Button } from '@/atoms'
import { Heading, Paragraph } from '@/atoms/Typography'

import * as S from './styles'

const GroupsSubmenu = ({ data }) => {
  const [submenu, setSubmenu] = useState(false)
  const [buttonHeight, setButtonHeight] = useState(0)
  const isSingleChildItem = data.links.length === 1 ?? false
  const buttonRef = useRef()

  let ref = useRef()

  const handleMouseEnter = (ev) => {
    if (ref.current && ref.current.contains(ev.target)) {
      setSubmenu(true)
      ref.current.querySelector('button').focus()
    }
  }

  const handleMouseLeave = (ev) => {
    if (ref.current && ref.current.contains(ev.target)) {
      setSubmenu(false)
      ref.current.querySelector('button').blur()
    }
  }

  useEffect(() => {
    if (buttonRef.current) {
      setButtonHeight(buttonRef.current.offsetHeight)
    }
  }, [buttonRef, ref])

  useEffect(() => {
    const closeHandler = (event) => {
      if (submenu && ref.current && !ref.current.contains(event.target)) {
        setSubmenu(false)
      }
    }

    document.addEventListener('mousedown', closeHandler)
    document.addEventListener('keydown', closeHandler)
    document.addEventListener('touchstart', closeHandler)

    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', closeHandler)
      document.removeEventListener('keydown', closeHandler)
      document.removeEventListener('touchstart', closeHandler)
    }
  }, [submenu])

  return (
    <S.GroupSubmenu
      ref={ref}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      $buttonHeight={buttonHeight}
    >
      <S.GroupSubmenuButtonWrapper ref={buttonRef}>
        <Button
          fifth
          fifthWithBG
          ariaLabel={`${submenu ? 'Chiudi' : 'Espandi'} ${data?.title}${
            data?.description ? ' - ' + data?.description : ''
          }`}
          iconEnd={'arrow-right-big'}
          iconEndWeight={{ big: true }}
          inheritedColorScheme='white'
          aria-haspopup='menu'
          aria-expanded={submenu ? 'true' : 'false'}
          handleClick={() => setSubmenu((prev) => !prev)}
        >
          {data.title && (
            <Heading
              typo={'displayXS'}
              content={data.title}
              bold
            />
          )}
          {data.description && (
            <Paragraph
              typo={'paragraph2'}
              content={data.description}
            />
          )}
        </Button>
      </S.GroupSubmenuButtonWrapper>
      <S.GroupSubmenuItem
        $visibility={submenu ? 1 : 0}
        $isSingleChildItem={isSingleChildItem}
      >
        {data.links.map(({ id, label, ...link }, i) => (
          <Button
            key={id}
            {...link}
            ariaLabel={`Vai a ${label}`}
            iconEnd={'arrow-right-big'}
            iconEndWeight={{ big: true }}
            fifth
            fifthWithBG
            inheritedColorScheme='white'
          >
            <Heading
              typo={'displayXS'}
              content={label}
              bold
            />
          </Button>
        ))}
      </S.GroupSubmenuItem>
    </S.GroupSubmenu>
  )
}

export default GroupsSubmenu
