import { StyledButton } from '@/atoms/Button/styles'
import styled, { css } from 'styled-components'

export const StyledHeader = styled.header`
  width: 100%;
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.secondary.light4};
  z-index: ${({ theme }) => theme.layers.header};
`

export const PreHeader = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 ${({ theme }) => theme.rem(16)};
  background-color: ${({ theme }) => theme.colors.secondary.light4};
  opacity: 1;
  transition:
    height 0.3s ease-in-out,
    opacity 0.3s ease-in-out;

  ${({ sticky }) =>
    sticky &&
    css`
      height: 0;
      opacity: 0;
    `}

  ${StyledButton} {
    color: ${({ theme }) => theme.colors.primary.default};

    &:focus {
      box-shadow: 0 0 0 ${({ theme }) => theme.rem(4)}
        ${({ theme }) => theme.colors.primary.light3};
    }
  }
`

export const MainHeader = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 ${({ theme }) => theme.rem(16)};
  background-color: ${({ theme }) => theme.colors.secondary.light6};
  border-bottom: 0.5px solid ${({ theme }) => theme.colors.primary.light3};

  ${({ theme }) =>
    theme.mediaQuery.lg(`
    height: 104px;
  `)};
`

export const MainHeaderWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${({ theme }) =>
    theme.mediaQuery.xl(`
    justify-content: center;
  `)};
`

export const MainHeaderLogo = styled.div`
  margin-right: auto;

  ${({ theme }) =>
    theme.mediaQuery.lg(`
    margin-right: initial;
  `)};

  svg {
    height: 30px !important;

    ${({ theme }) =>
      theme.mediaQuery.lg(`
      height: ${theme.rem(37)} !important;
    `)};
  }
`

export const MainHeaderSearch = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: ${({ theme }) => theme.rem(8)};
  margin-left: ${({ theme }) => theme.rem(8)};

  ${({ theme }) =>
    theme.mediaQuery.xxl(`
    padding-left: ${theme.rem(48)};
    border-left: 0.5px solid ${theme.colors.primary.light3};
  `)};

  svg {
    color: ${({ theme }) => theme.colors.primary.default};
  }
`

export const MenuDesktop = styled.div`
  margin: 0 ${({ theme }) => theme.rem(70)};
`

export const MenuDesktopItem = styled.div``
