import { useMemo } from 'react'
import dynamic from 'next/dynamic'

import { StyledIcon } from './styles'

const getIcon = (name) => {
  const Icon = dynamic(() => import(`@/atoms/Icon/glyphs/${name}.svg`), {
    ssr: false,
  })

  return Icon
}

const Icon = ({ name, ariaLabel, ...props }) => {
  const SvgIcon = useMemo(() => getIcon(name), [name])

  const label = ariaLabel ? ariaLabel : name.replaceAll('-', ' ')

  return SvgIcon ? (
    <StyledIcon
      {...props}
      aria-label={label}
      role='img'
    >
      <SvgIcon />
    </StyledIcon>
  ) : null
}

export default Icon
