import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import gridConfig, { GRID_DIMENSIONS } from '@theme/gridConfig'

const Row = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-wrap: wrap;
  box-sizing: border-box;

  /* ${(p) => css`
    ${GRID_DIMENSIONS.map(
      (d) =>
        gridConfig(p).container[d] &&
        gridConfig(p).media[d]`
      margin-left: -${({ theme }) =>
        theme.rem(gridConfig(p).gutterWidth[d] / 2)};
      margin-right: -${({ theme }) =>
        theme.rem(gridConfig(p).gutterWidth[d] / 2)};
    `
    )}
  `} */

  ${(p) => css`
    margin-left: -${({ theme }) => theme.rem(gridConfig(p).gutterWidth['xs'] / 2)};
    margin-right: -${({ theme }) => theme.rem(gridConfig(p).gutterWidth['xs'] / 2)};
  `}

  ${(p) =>
    p.reverse &&
    css`
      ${Array.isArray(p.reverse)
        ? GRID_DIMENSIONS.map(
            (d) =>
              gridConfig(p).breakpoints[d] &&
              gridConfig(p).media[d]`
        ${
          p.reverse.indexOf(d) !== -1
            ? `
            flex-direction: row-reverse;
            flex-wrap: wrap-reverse;
          `
            : `
            flex-direction: row;
            flex-wrap: wrap;
          `
        };
      `
          )
        : `
        flex-direction: row-reverse;
        flex-wrap: wrap-reverse;
      `}
    `}

  ${(p) =>
    p.align &&
    css`
      ${typeof p.align === 'object'
        ? GRID_DIMENSIONS.map(
            (d) =>
              gridConfig(p).breakpoints[d] &&
              gridConfig(p).media[d]`
          ${
            p.align[d] &&
            `align-items: ${p.align[d]}
        `
          }
      `
          )
        : `align-items: ${p.align};`}
    `}
  
  ${(p) =>
    p.justify &&
    css`
      ${typeof p.justify === 'object'
        ? GRID_DIMENSIONS.map(
            (d) =>
              gridConfig(p).breakpoints[d] &&
              gridConfig(p).media[d]`
          ${
            p.justify[d] &&
            `justify-content: ${p.justify[d]}
        `
          }
      `
          )
        : `justify-content: ${p.justify};`}
    `}

  ${({ debug }) =>
    debug &&
    css`
      background-color: #cce2fc;
      outline: #fff solid 1px !important;
    `}
`

Row.displayName = 'Row'

const boolOrArray = PropTypes.oneOfType([PropTypes.bool, PropTypes.array])
const stringOrObject = PropTypes.oneOfType([PropTypes.string, PropTypes.object])

Row.propTypes = {
  reverse: boolOrArray,
  align: stringOrObject,
  justify: stringOrObject,
  children: PropTypes.node,
  debug: PropTypes.bool,
}

export default Row
