import React from 'react'
import dynamic from 'next/dynamic'

import { Container, Row, Visible } from '@/atoms/Grid'
import Card from '@/molecules/Card'

import * as S from './styles'

const Carousel = dynamic(() => import('@/molecules/Carousel'))

const BulletPoints = ({ bulletPoints }) => {
  const carouselItems =
    bulletPoints.length > 0
      ? bulletPoints.map((item) => {
          item.reverse = true
          return {
            type: 'bulletPoint',
            item,
          }
        })
      : null

  return (
    <S.BulletPoints>
      <Container>
        <Visible
          lg
          xl
          xxl
        >
          <Row justify={'center'}>
            {bulletPoints.length > 0
              ? bulletPoints.map((item) => {
                  item.reverse = true
                  return (
                    <S.BulletPointsCard
                      xs={12}
                      md={bulletPoints.length == 4 ? 3 : 4}
                      length={bulletPoints.length}
                      key={item.id}
                    >
                      <Card
                        data={item}
                        type={'bulletPoint'}
                      />
                    </S.BulletPointsCard>
                  )
                })
              : null}
          </Row>
        </Visible>
        <Visible
          xs
          sm
          md
        >
          {carouselItems ? <Carousel items={carouselItems} /> : null}
        </Visible>
      </Container>
    </S.BulletPoints>
  )
}

export default BulletPoints
