import styled, { css } from 'styled-components'
import { StyledButton } from '@/atoms/Button/styles'

export const StyledMenuBurger = styled.div`
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  button {
    width: ${({ theme }) => theme.rem(40)};
    height: ${({ theme }) => theme.rem(40)};
    background-color: ${({ theme }) => theme.colors.grayscale.white};
    border-color: ${({ theme }) => theme.colors.grayscale.white};
    padding: 0;

    &:not([disabled]),
    &:not([aria-disabled='true']) {
      &:active,
      &:focus {
        background-color: ${({ theme }) => theme.colors.grayscale.white};
      }
    }
  }

  span {
    width: 50%;
    height: 25%;
    position: relative;
  }
`

export const StyledMenuBurgerLine = styled.div`
  width: 100%;
  height: ${({ theme }) => theme.rem(2)};
  border-radius: ${({ theme }) => theme.rem(2)};
  background-color: ${({ theme }) => theme.colors.primary.default};
  position: absolute;
  left: 0;
  transition: all ${({ theme }) => theme.timing.fast} 0s;

  &:first-child {
    top: 0;

    ${({ isOpen }) =>
      isOpen &&
      css`
        top: 50%;
        transform: translateY(-50%) rotate(45deg);
      `}
  }

  &:last-child {
    top: 100%;

    ${({ isOpen }) =>
      isOpen &&
      css`
        top: 50%;
        transform: translateY(-50%) rotate(-45deg);
      `}
  }
`
