import React from 'react'

import { Button } from '@/atoms'
import { Row, Col, SpacerBase } from '@/atoms/Grid'
import Card from '@/molecules/Card'
import { ItemsGrouped, RichText } from '@/organisms'

import {
  StyledTabsContentInner,
  StyledContentCardItem,
  StyledTabsCardWrapper,
  StyledTabsRichTextWrapper,
} from './styles'

const ContentCard = ({
  tagline,
  title,
  description,
  cards,
  ctaPrimary,
  ctaSecondary,
}) => {
  return (
    <StyledTabsContentInner>
      <Row>
        <Col
          xs={12}
          lg={6}
        >
          <StyledTabsRichTextWrapper>
            <RichText
              tagline={tagline}
              title={title}
              paragraph={description}
              embedded
            />
            {ctaPrimary || ctaSecondary ? (
              <>
                <SpacerBase xs={4} />
                <ItemsGrouped
                  direction={{ xs: 'column', md: 'row' }}
                  space={{ xs: 16, md: 16 }}
                >
                  {ctaPrimary ? (
                    <Button
                      {...ctaPrimary}
                      primary
                      small
                      typo='button2'
                    />
                  ) : null}
                  {ctaSecondary ? (
                    <Button
                      {...ctaSecondary}
                      secondary
                      small
                      typo='button2'
                    />
                  ) : null}
                </ItemsGrouped>
              </>
            ) : null}
          </StyledTabsRichTextWrapper>
        </Col>
        <Col
          xs={12}
          lg={6}
        >
          <StyledTabsCardWrapper>
            {cards
              ? cards.map((item) => {
                  return (
                    <StyledContentCardItem key={item.id}>
                      <Card
                        data={item}
                        type={'tab'}
                      />
                    </StyledContentCardItem>
                  )
                })
              : null}
          </StyledTabsCardWrapper>
        </Col>
      </Row>
    </StyledTabsContentInner>
  )
}

export default ContentCard
