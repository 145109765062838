import { Container, SpacerBase } from '@/atoms/Grid'
import { Heading } from '@/atoms/Typography'
import SectionsWrapper from './SectionsWrapper'

import * as S from './styles'

const DynamicForm = ({ formData, ...rest }) => {
  return (
    <S.Wrapper>
      <Container>
        <S.Content>
          <Heading
            typo='displayLG'
            bold
          >
            La tua richiesta
          </Heading>
          <SpacerBase xs={4} />
          <Heading
            typo='displayXS'
            bold
          >
            Informazioni di contatto
          </Heading>
          <SpacerBase xs={2} />
          <SectionsWrapper
            section={formData}
            {...rest}
          />
        </S.Content>
      </Container>
    </S.Wrapper>
  )
}

export default DynamicForm
