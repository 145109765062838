import styled, { css } from 'styled-components'
import styledMap from 'styled-map'

const base = css`
  font-weight: ${styledMap`
    light: 300;
    medium: 500;
    bold: 700;
    extraBold: 800;
    default: 400;
  `};
  ${({ uppercase }) => uppercase && 'text-transform: uppercase;'};
`

export const InnerContent = styled.div`
  ${base}
  ${({ theme, as }) => theme.typo(as || 'div')};
  ${({ theme, typo }) => theme.typo(typo || 'displaySM')};

  ${({ theme, colorScheme, isTagline }) =>
    isTagline &&
    css`
      color: ${theme.colors.surface[colorScheme].tagline};
    `}

  a {
    font-weight: 500;
    text-decoration: underline;
    transition: color 0.3s ease-in-out;

    ${({ theme, colorScheme }) =>
      colorScheme &&
      css`
        &:hover,
        &:focus {
          color: ${theme.colors.surface[colorScheme].textHover};
          box-shadow: 0 0 0 ${theme.rem(2)} ${theme.colors.secondary.light4};
        }
      `}
  }

  strong,
  b {
    font-weight: 700;
  }

  em {
    font-style: italic;
  }

  ol,
  ul {
    margin-block-start: ${({ theme }) => theme.rem(5)};
    margin-block-end: ${({ theme }) => theme.rem(5)};
    padding-inline-start: ${({ theme }) => theme.rem(16)};

    li {
      margin: ${({ theme }) => theme.rem(5, 0)};
    }
  }

  ol {
    list-style: decimal;
  }

  ul {
    list-style: disc;
  }
`
