import React from 'react'

import { components } from 'react-select'

import FieldBase from '../FieldBase'

import { Icon, Button } from '@/atoms'

import * as S from './styles'

const ClearIndicator = (props) => {
  const {
    innerProps: { ref, ...restInnerProps },
  } = props

  return (
    <S.CustomSelectClear>
      <Button
        iconEnd='close'
        secondary
        small
        ariaLabel='Resetta la select'
        {...restInnerProps}
        inheritedColorScheme='white'
        onKeyDown={restInnerProps.onMouseDown}
        ref={ref}
      />
    </S.CustomSelectClear>
  )
}

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <Icon
        name={'chevron-down'}
        big
      />
    </components.DropdownIndicator>
  )
}

const MenuList = ({ children, ...props }) => {
  return (
    <components.MenuList {...props}>
      {children}
      <S.CustomSelectButtons>
        {/* <Button label='Cancella' tertiary small typo={"button2"} /> */}
        <Button
          type='submit'
          label='Applica filtri'
          primary
          small
          typo={'button2'}
          inheritedColorScheme='white'
        />
      </S.CustomSelectButtons>
    </components.MenuList>
  )
}

const Select = ({
  type,
  id,
  name,
  value: baseValue,
  label,
  options,
  disabled,
  required,
  onChange,
  onBlur,
  col,
  hint,
  meta,
  form,
  closeMenuOnSelect = false,
  hasControls = true,
  ...props
}) => {
  // Flatten the options so that it will be easier to find the value
  const flattenedOptions = props.options?.flatMap((opt) => {
    const isNotGrouped = 'value' in opt
    if (isNotGrouped) {
      return opt
    } else {
      return opt.options
    }
  })

  // Get the value using flattenedOptions and value
  const value = flattenedOptions?.filter((o) => {
    const isArrayValue = Array.isArray(baseValue)

    if (isArrayValue) {
      const values = baseValue
      return values.includes(o.value)
    } else {
      return baseValue === o.value
    }
  })

  const handleOnChange = (newValue, actionMeta) => {
    const _val = newValue
    const isArray = Array.isArray(_val)
    if (isArray) {
      const values = _val.map((o) => o.value)
      form.setFieldValue(actionMeta.name, values)
    } else {
      form.setFieldValue(actionMeta.name, _val === null ? '' : _val)
    }

    if (!props.isMulti || actionMeta.action === 'clear') {
      form.submitForm()
    }
  }

  let components = { DropdownIndicator, ClearIndicator }

  if (hasControls) {
    components = { ...components, ...{ MenuList } }
  }

  return (
    <FieldBase
      id={id || name}
      label={label}
      disabled={disabled}
      meta={meta}
      hint={hint}
    >
      <S.CustomSelect
        id={id}
        name={name}
        placeholder={label}
        options={options}
        isDisabled={disabled}
        required={required}
        onChange={form ? handleOnChange : onChange}
        onBlur={onBlur && onBlur(name)}
        closeMenuOnSelect={closeMenuOnSelect}
        classNamePrefix='caffeina'
        components={components}
        {...props}
        value={value}
      />
    </FieldBase>
  )
}

export default Select
