import React, { useContext } from 'react'
import SectionThemeContext from '@contexts/SectionTheme.jsx'

import * as S from './styles.js'

const InnerContent = ({
  children,
  inheritedColorScheme,
  content,
  as,
  typo,
  uppercase,
  bold,
  isTagline,
}) => {
  const injectHtml = (content) => {
    return { __html: content }
  }

  const colorScheme =
    useContext(SectionThemeContext) || inheritedColorScheme || 'white'

  return (
    <S.InnerContent
      as={as}
      typo={typo}
      colorScheme={colorScheme}
      uppercase={uppercase}
      bold={bold}
      isTagline={isTagline}
      dangerouslySetInnerHTML={injectHtml(content || children)}
    />
  )
}

export default InnerContent
