import { Container } from '@/atoms/Grid'
import styled, { css } from 'styled-components'

export const StoreLocator = styled(Container)`
  height: calc(100vh - 85px);
  overflow-y: auto;
`

export const StoreLocatorForm = styled.div`
  position: relative;
  padding: ${({ theme }) => theme.rem(32, 0)};
  z-index: 100;

  ${({ theme }) =>
    theme.mediaQuery.md(
      `
    padding: ${theme.rem(20, 0, 0, 0)};
  `,
      true
    )}
`

export const StoreLocatorMap = styled.div`
  width: 100%;

  ${({ theme }) =>
    theme.mediaQuery.md(
      `
    padding: ${theme.rem(20, 0)};
  `,
      true
    )}
`

export const InfoWindow = styled.div`
  width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 38, 74, 20%);
  opacity: 0;
  visibility: hidden;
  transition:
    opacity 0.2s ease-in-out,
    visibility 0.2s ease-in-out;
  pointer-events: none;
  z-index: 100;

  ${({ theme }) =>
    theme.mediaQuery.md(
      `
    overflow-x: hidden;
    overflow-y: auto;
  `,
      true
    )}

  ${({ open }) =>
    open &&
    css`
      pointer-events: all;
      opacity: 1;
      visibility: visible;
    `}
`

export const InfoWindowContent = styled.div`
  max-width: 600px;
  position: relative;
  transform: translateY(20px);
  transition: transform 0.2s ease-in-out;

  ${({ open }) =>
    open &&
    css`
      transform: translateY(0);
    `}
`

export const InfoWindowClose = styled.div`
  position: absolute;
  right: 0;
  top: 0;

  button {
    padding: ${({ theme }) => theme.rem(12, 24)};
    color: ${({ theme }) => theme.colors.primary.default};
  }
`
