import React, { useState } from 'react'

import { motion, AnimatePresence } from 'framer-motion'

import { Swiper, SwiperSlide } from 'swiper/react'
import { FreeMode, Mousewheel } from 'swiper'

import { Paragraph } from '@/atoms/Typography'
import { Container } from '@/atoms/Grid'

import Card from './Card'
import Image from './Image'
import Text from './Documents'
import Table from './Table'
import Video from './Video'

import {
  StyledTab,
  StyledTabInner,
  StyledTabHeader,
  StyledTabHeaderItemList,
  StyledTabHeaderItem,
  StyledTabContent,
} from './styles'

const swiperConfig = {
  slidesPerView: 'auto',
  freeMode: {
    enabled: true,
    sticky: false,
    momentumBounce: false,
    momentumVelocityRatio: 0.5,
  },
  mousewheel: {
    enabled: true,
    forceToAxis: true,
  },
}

const Tabs = ({ tabs, minimal }) => {
  const [selectedTab, setSelectedTab] = useState(0)

  const handleTabChange = (index) => {
    setSelectedTab(index)
  }

  const renderTabContent = (type = 'card') => {
    const normalizeContent = {
      card: Card,
      image: Image,
      documents: Text,
      table: Table,
      video: Video,
    }

    const Component = normalizeContent[type]

    return <Component {...tabs[selectedTab]} />
  }

  return (
    <StyledTab
      minimal={minimal || tabs[selectedTab].type == 'documents' ? true : null}
    >
      <Container>
        <StyledTabInner>
          <StyledTabHeader>
            <StyledTabHeaderItemList>
              <Swiper
                modules={[FreeMode, Mousewheel]}
                {...swiperConfig}
              >
                {tabs.map(({ id, label }, index) => {
                  return (
                    <SwiperSlide key={id}>
                      <StyledTabHeaderItem
                        tabIndex='0'
                        isSelected={selectedTab === index}
                        onClick={() => handleTabChange(index)}
                        onKeyDown={(ev) =>
                          (ev.key === 'Enter' || ev.key === ' ') &&
                          handleTabChange(index)
                        }
                      >
                        <Paragraph
                          typo={'paragraph2'}
                          bold
                        >
                          {label}
                        </Paragraph>
                      </StyledTabHeaderItem>
                    </SwiperSlide>
                  )
                })}
              </Swiper>
            </StyledTabHeaderItemList>
          </StyledTabHeader>
          <StyledTabContent>
            <AnimatePresence mode={'wait'}>
              <motion.div
                initial={{ y: 10, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: -10, opacity: 0 }}
                transition={{ duration: 0.2 }}
                key={selectedTab}
              >
                {renderTabContent(tabs[selectedTab].type)}
              </motion.div>
            </AnimatePresence>
          </StyledTabContent>
        </StyledTabInner>
      </Container>
    </StyledTab>
  )
}

export default Tabs
