export const formattedDate = (date, format = 'full') => {
  let finalDate = ''
  const formattedDate = new Date(date.split('-'))

  if (format == 'full') {
    finalDate = `${formattedDate.getDate()} ${formattedDate.toLocaleString(
      'it-IT',
      { month: 'long' }
    )} ${formattedDate.getFullYear()}`
  } else if (format == 'short') {
    finalDate = formattedDate.toLocaleString('it-IT', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    })
  }

  return finalDate
}
