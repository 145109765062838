import { StyledButton } from '@/atoms/Button/styles'
import { Field } from '@/atoms/Form/FormFields/FieldBase/styles'
import styled, { css } from 'styled-components'

export const Modal = styled.div`
  width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
  transition:
    opacity 0.2s ease-in-out,
    visibility 0.2s ease-in-out;
  pointer-events: none;
  z-index: ${({ theme }) => theme.layers.modal};

  ${({ theme, open }) =>
    open &&
    css`
      pointer-events: all;
      opacity: 1;
      visibility: visible;
    `}
`

export const ModalContent = styled.div`
  width: 100vw;
  min-height: 100vh;
  transform: translateY(20px);
  transition: transform 0.2s ease-in-out;

  ${({ open }) =>
    open &&
    css`
      transform: translateY(0);
    `}
`

export const ModalHeader = styled.div`
  width: 100%;
  padding: ${({ theme }) => theme.rem(24, 0)};
  position: sticky;
  top: 0;
  z-index: 2;

  ${({ theme }) =>
    theme.mediaQuery.md(
      `
    position: sticky;
    top: 0;
  `,
      true
    )}
`

export const ModalHeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  > i {
    svg {
      height: ${({ theme }) => theme.rem(36)};
    }
  }

  button {
    color: ${({ theme }) => theme.colors.primary.default};
  }
`

export const ModalBody = styled.div`
  min-height: calc(100vh - 85px);
  position: relative;
  padding-bottom: ${({ theme }) => theme.rem(20)};
  z-index: 1;
`

/**
 * Modal Base
 */
export const ModalBase = styled.div`
  ${ModalHeader} {
    background-color: ${({ theme }) => theme.colors.primary.light6};
    border-bottom: 1px solid ${({ theme }) => theme.colors.primary.light4};
  }

  ${ModalBody} {
    background-color: ${({ theme }) => theme.colors.primary.light6};
  }
`

/**
 * Modal Search
 */
export const ModalSearch = styled.div`
  ${ModalHeader} {
    background-color: ${({ theme }) => theme.colors.primary.default};

    ${({ theme }) =>
      theme.mediaQuery.md(`
      padding: ${theme.rem(36, 0)};
    `)}
  }

  ${ModalHeaderContent} {
    ${Field} {
      margin-bottom: 0;
      margin-right: ${({ theme }) => theme.rem(20)};

      input[type='text'],
      input[type='text']::placeholder {
        color: ${({ theme }) => theme.colors.grayscale.white};
        font-size: ${({ theme }) => theme.rem(24)};
        font-weight: 700;
      }

      input[type='text'] {
        padding-left: 0;
        background-color: ${({ theme }) => theme.colors.primary.default};
        border: 0;

        &:not([disabled]),
        &:not([aria-disabled='true']) {
          &:focus {
            box-shadow: none;
          }
        }
      }
    }

    button {
      svg {
        color: ${({ theme }) => theme.colors.grayscale.white};
      }
    }
  }

  ${ModalBody} {
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(30px);
  }
`

export const ModalSearchResults = styled.div`
  height: calc(100vh - 95.5px);
  padding: ${({ theme }) => theme.rem(40, 0)};
  overflow-y: auto;
  color: ${({ theme }) => theme.colors.grayscale.white};
  background-color: ${({ theme }) => theme.colors.primary.light1};

  ${({ theme }) =>
    theme.mediaQuery.md(`
    padding: ${theme.rem(60, 0)};
    height: calc(100vh - 119.5px);
  `)}
`

export const ModalSearchResultsList = styled.div`
  &:not(:first-child) {
    margin-top: ${({ theme }) => theme.rem(40)};
  }
`

export const ModalSearchResultsListItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: ${({ theme }) => theme.rem(24)};
  padding-bottom: ${({ theme }) => theme.rem(16)};
  border-bottom: 1px solid ${({ theme }) => theme.colors.grayscale.white};

  ${({ theme }) => theme.mediaQuery.md(`
    flex-direction: column;
    align-items: flex-start;
  `, true)}

  ${StyledButton} {
    font-size: ${({ theme }) => theme.rem(16)};
    color: ${({ theme }) => theme.colors.grayscale.white};
  }
`

export const ModalSearchResultsListItemTitle = styled.div`
  flex: 1;
`

export const ModalSearchResultsListItemDocs = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  flex: 1;
  gap: ${({ theme }) => theme.rem(16)};

  ${({ theme }) => theme.mediaQuery.md(`
    justify-content: flex-start;
    gap: ${theme.rem(8)};
  `, true)}

  ${StyledButton} {
    text-transform: capitalize;
  }
`
