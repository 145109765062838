import styled, { css } from 'styled-components'

export const CardsGrid = styled.div`
  padding: ${({ theme }) => theme.rem(32, 0)};

  /* ${({ theme }) =>
    theme.mediaQuery.lg(`
    padding: ${theme.rem(60, 0)};
  `)}; */
`
